import { useContext } from 'react';
import { AppContext } from '../../../utils/store/app';

export const defaultFormValues = {};

export const formConfig = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'rank',
    label: 'Rank',
    type: 'number',
  },
  {
    name: 'commission',
    label: 'Commission',
    type: 'number',
  },
  {
    name: 'match',
    label: 'Match',
    type: 'number',
  },
  // {
  //  name: 'minTv',
  //  label: 'Minimal Tv',
  //  type: 'number',
  // },
  {
    name: 'minPv',
    label: 'Minimal Personal Volume / Month',
    type: 'number',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },

];

export const getFormConfig = () => formConfig;

export const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  rank: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  commission: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  match: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export default {};
