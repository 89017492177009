import React, { useState } from 'react';
import { Provider } from './context';
import Banner from '../../../components/Banner';
import Teams from './components/Teams';
import UsersTree from './components/Tree';
import UserModal from './components/UserModal';
import ActiveTeamModal from './components/ChangeTeamModal';
import { PROMO_MESSAGES } from '../../../constants';
import useScreenSize from '../../../utils/hooks/useScreenSize';
import CustomAccordion from '../../../components/Accordion';

export function GenealogyTree() {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useScreenSize();

  return (
    <div>
      {/* <Banner data={PROMO_MESSAGES.mvpKickoff} /> */}
      {isMobile ? (
        <div className="teams-accordion-container">
          <CustomAccordion
            expanded={isOpen}
            setExpanded={() => setIsOpen((current) => !current)}
            summary={<p>My Team</p>}
            details={<Teams />}
          />
        </div>
      ) : (
        <Teams />
      )}
      <div className="flex justify-center">
        <UsersTree />
      </div>
      <UserModal />
      <ActiveTeamModal />
    </div>
  );
}

export default () => (
  <Provider>
    <GenealogyTree />
  </Provider>
);
