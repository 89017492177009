import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Toolbar, Typography } from '@mui/material';
import CustomDrawer from './components/Drawer';
import AppBar from './components/AppBar';
import { APP_BAR_HEIGHT } from '../../constants';
import { AuthenticationContext } from '../../utils/store/authentication';
// TODO remove, this demo shouldn't need to reset the theme.

export default function AppLayout() {
  const { user } = useContext(AuthenticationContext);
  const [open, setOpen] = useState(localStorage.getItem('drawer') === 'open');

  const toggleDrawer = () => {
    if (open) {
      localStorage.setItem('drawer', 'closed');
    } else {
      localStorage.setItem('drawer', 'open');
    }

    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
     
      <CssBaseline />
      {user && (
        <>
          <AppBar toggleDrawer={toggleDrawer} open={open} />
          <CustomDrawer toggleDrawer={toggleDrawer} open={open} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : '#121316'),
              flexGrow: 1,
              height: '100dvh',
              overflow: 'auto',
              px: '3em',
              // [defaultTheme.muiTheme.breakpoints.between('md', 'xs')]: { ml: '72px' },
              pb: 7,
              // md: { ml: '72px' },
              // sm: { ml: '72px' },
              pl: {
                sm: '12px', md: '72px', xs: '12px', xl: '20px', lg: '20px',
              },
              pr: {
                sm: '12px', xs: '12px', md: '32px', xl: '32px', lg: '32px',
              },
            }}
          >
            <Toolbar
              sx={{
                height: `${APP_BAR_HEIGHT}px`,
              }}
            />

            <Outlet />
          </Box>
        </>
      )}

    </Box>
  );
}
