import dayjs from 'dayjs';
import { IconButton, Tooltip } from '@mui/material';
import { renderNumber } from '../../../utils/string';
import Icon from '../../../components/Icon';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultWithdrawColumnsConfig = (t, setWithdrawDestinationDataModal) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value.Username),
  },
  {
    field: 'amount',
    headerName: t('Withdraw Amount'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
  {
    field: 'createdAt',
    headerName: t('Withdraw Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
  },
  {
    field: 'destination',
    headerName: t('Perform Payment'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title="Perform Payment">
        <IconButton color="inherit" onClick={() => setWithdrawDestinationDataModal({ withdrawId: row.id, destination: row.destination })}>
          <Icon name="MoneyHand" width={24} height={24} />
        </IconButton>
      </Tooltip>
    ),
  },
];
