import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import CoinComponent from './CoinComponent';
import { renderNumber } from '../../utils/string';
import RedeemButton from '../RedeemButton';

const GameMatch = ({ game, userTokens }) => {
  const launchDate = dayjs(process.env.REACT_APP_REDEMPTION_START_DATE);
  const gameEndDate = dayjs(game.endDate);

  const hideRedeemButton = launchDate >= gameEndDate;

  const coin1Winner = game.coin1 === game.winningCoin;
  const coin2Winner = game.coin2 === game.winningCoin;

  const coin1Picked = game.coin1 === game.myPick?.memeCoinsId;
  const coin2Picked = game.coin2 === game.myPick?.memeCoinsId;

  const coin1CanRedeem = coin1Winner && coin1Picked;
  const coin2CanRedeem = coin2Winner && coin2Picked;

  const leaderboardRank = game?.statistics?.rankingPercentage || 0;
  const tokenReceived = game?.token || game?.price;

  return (
    <Grid
      maxWidth="100%"
      size="xsm"
      item
      sx={{
        mb: { lg: '8px' }, padding: { xs: 2, lg: 4 },
      }}
      className="game-container"
      display="flex"
      flexDirection="column"
    >
      <Grid
        maxWidth="100%"
        size="xsm"
        className="game-container-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0"
      >
        <Typography sx={{
          fontSize: { xs: '16px' },
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          {renderNumber(game?.price)}
          {' Coin Game'}
        </Typography>

        <div className="info">
          <Typography
            sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
            className="text-number"
          >
            <span>Leaderboard Rank:</span>
            <span className="text-gold">
              {`TOP ${parseInt(leaderboardRank, 10)}%`}
            </span>
          </Typography>

          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-number"
          >
            <span>Coins Received:</span>
            <span className="text-gold">
              {renderNumber(tokenReceived)}
            </span>
          </Typography>
          {(coin1CanRedeem || coin2CanRedeem) && (
            <RedeemButton
              totalTokens={userTokens}
              gameId={game.id}
              coin={coin1CanRedeem ? game.coinOne : game.coinTwo}
              buttonText={userTokens > 2000 ? 'Redeem 2,000' : null}
              bordered
            />
          )}
        </div>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="divider" />
      </Grid>
      <div className="game-container-body">
        <div className={`games${hideRedeemButton ? ' hide-redeem' : ''}`}>
          <CoinComponent
            coin={game.coinOne}
            coinData={game.coin1Data}
            isWinner={coin1Winner}
            myPick={coin1Picked}
            tokens={userTokens}
            gameId={game.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
          />
          <CoinComponent
            coin={game.coinTwo}
            coinData={game.coin2Data}
            isWinner={coin2Winner}
            myPick={coin2Picked}
            tokens={userTokens}
            gameId={game.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
          />
        </div>
      </div>
    </Grid>
  );
};

export default GameMatch;
