import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthentication } from '../utils/store';

const AdminRoute = () => {
  const { user } = useAuthentication();
  const location = useLocation();
  if (!user.Permissions?.IsAdmin && !user.Permissions?.IsSuperAdmin) {
    // user is not authorized
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
