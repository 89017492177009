import { Grid } from '@mui/material';
import Welcome from './components/Welcome';
import Layout from '../../components/CardLayout';
import AccountInfo from './components/AccountInfo';
import ChangePassword from './components/ChangePassword';

export default function Profile() {
  return (
    <Grid container className="profile">
      <div className="col left-col">
        <Layout size="sm" maxWidth="xl" className="centered-card">
          <Welcome />
        </Layout>

        <div>
          <Layout size="sm" maxWidth="xl">
            <ChangePassword />
          </Layout>
        </div>
      </div>

      <div className="col left-col">
        <Layout size="sm" maxWidth="xl">
          <AccountInfo />
        </Layout>
      </div>
    </Grid>
  );
}
