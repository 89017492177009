const config = {
  viewBox: '0 0 12 7',
  children: (
    <>
      <path d="M0.248047 1.46289H11.5625" stroke="currentColor" />
      <path d="M0.248047 5.87402H11.5625" stroke="currentColor" />
    </>
  ),
};

export default config;
