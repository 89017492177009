import { Typography } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Context } from '../../context';
import TeamsCards from '../../../../../components/GenealogyTeamCards';
import useApi from '../../../../../utils/api';

export default function Teams() {
  const {
    user, onChangeTeamClick, volume, nrOfRanks, personalVolume,
  } = useContext(Context);

  const { authGet } = useApi();
  const [qualificationPercent, setQualificationPercent] = useState();
  const [loadingVolumes, setLoadingVolumes] = useState(false);

  const fetchQualificationPercent = async () => {
    setLoadingVolumes(true);
    try {
      const resp = await authGet('/payments/qualification');
      setQualificationPercent(resp);
    } catch (err) {
      console.log(err);
    }
    setLoadingVolumes(false);
  };

  useEffect(() => {
    fetchQualificationPercent();
  }, []);

  return (
    <TeamsCards
      volume={volume}
      personalVolume={personalVolume}
      qualificationPercent={qualificationPercent}
      loadingVolumes={loadingVolumes}
    >
      <div className="flex flex-column justify-center h-full">
        <Typography sx={{
          color: 'var(--primary-color)', textTransform: 'uppercase', fontSize: '13px', fontWeight: 'bold',
        }}
        >
          {user.qualified ? user.rank.name : ''}
          <span style={{ color: 'gray' }}>
            {user.qualified ? '' : 'Unqualified'}
          </span>
        </Typography>
        <Typography sx={{ textWrap: 'nowrap' }}>
          {' '}
          Selected Team:
          <span style={{ color: 'var(--primary-color)' }}>
            {` Team ${user.activeTeam || '1'}`}

          </span>
          {' '}
          {
            nrOfRanks > 1 ? <EditIcon onClick={() => onChangeTeamClick()} sx={{ fontSize: '18px', ml: '3px', cursor: 'pointer' }} color="primary" />
              : ''
          }

        </Typography>
      </div>
    </TeamsCards>
  );
}
