import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import RedeemButton from '../../../components/RedeemButton';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TextInput from '../../../components/Inputs/TextInput';
import { Context } from '../context';

export default function SolanaWallet({ solanaBalance }) {
  const {
    onSaveSolanaWallet, solanaWallet, tokens, onDeleteSolanaWallet, setSolanaWallet,
  } = useContext(Context);
  const { t } = useTranslation();
  const [solanaKey, setSolanaKey] = useState('');
  const [openSolanaModal, setOpenSolanaModal] = useState(false);
  const [openSolanaDeleteModal, setOpenSolanaDeleteModal] = useState(false);

  const handleOnDeleteWallet = async (walletId) => {
    try {
      const deleted = await onDeleteSolanaWallet(walletId);
      if (deleted?.deletedCount > 0) {
        toast.success('Solana Wallet deleted.');
        setSolanaWallet(null);
      } else {
        toast.error('Can\'t delete your wallet at this moment. Please, try again ma');
      }
    } catch (err) {
      console.log(err);
      toast.error('Can\'t delete your wallet at this moment. Please, try again ma');
    }

    setOpenSolanaDeleteModal(false);
  };

  return (
    <>
      <Grid container sx={{ pt: 3 }} className="mt-2">
        <Grid
          item
          xs={12}
        >
          <Typography sx={{
            fontWeight: 600, textAlign: 'center', color: '#D8A878', fontSize: '16px',
          }}
          >
            {t('Solana Wallet')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingY: solanaWallet ? 1 : 3,
          }}
        >
          {!solanaWallet ? (
            <Button
              onClick={() => {
                setOpenSolanaModal(true);
              }}
              className="my-auto"
            >
              <span className="no-wrap" style={{ padding: '0 32px' }}>{t('Connect')}</span>
            </Button>
          ) : null }
        </Grid>

        {!!solanaWallet && (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: 1 }}>
              <TextInput
                className="wallet-input"
                value={solanaBalance.toString()}
                readOnly
                startAdornment={(
                  <span className="flex items-center">
                    <Typography sx={{
                      pl: 2, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                    }}
                    >
                      {solanaWallet.currency}
                    </Typography>
                  </span>
                )}
                endAdornment={(
                  <div className="flex" style={{ alignItems: 'center', paddingRight: '16px' }}>
                    <RedeemButton totalTokens={tokens} />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" paddingY="12px">
              <Button
                onClick={() => {
                  setOpenSolanaDeleteModal(true);
                }}
                bordered
                size="sm"
              >
                <span className="no-wrap">{t('Delete Wallet')}</span>
              </Button>
            </Grid>
            <Typography sx={{
              padding: 1, fontSize: '14px', opacity: 0.5, textAlign: { xs: 'center' },
            }}
            >
              {t('Redemptions for Meme Coins are in 2,000 token increments. You may chose to redeem in coin packs, which are available immediately. You may redeem for any Meme Coin that you’ve won with, which will be sent to your connected Solana wallet within 3 full business days.')}
            </Typography>
          </>
        )}
      </Grid>
      <Modal width="" open={!!openSolanaDeleteModal} handleClose={() => { setOpenSolanaDeleteModal(false); }} title="Delete Wallet">
        <Grid container columnSpacing={1}>
          <Grid item xl={12} lg={12} sm={12} md={12} position="relative" display="flex" flexDirection="column">
            <Typography style={{ textAlign: 'center' }}>
              {' '}
              Are you sure you want to delete
              <span style={{
                color: 'var(--primary-color)', textTransform: 'uppercase', marginLeft: '3px', marginRight: '3px',
              }}
              >
                your Solana Wallet
              </span>
              ?
            </Typography>
            <Typography style={{
              textAlign: 'center',
            }}
            >
              Wallet Address:
              {' '}
              <span style={{
                color: 'var(--primary-color)', textTransform: 'uppercase', marginLeft: '3px', marginRight: '3px', wordBreak: 'break-all',
              }}
              >
                {solanaWallet?.publicKey}
              </span>
            </Typography>
          </Grid>

          <Grid item xl={6} lg={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button onClick={() => handleOnDeleteWallet(solanaWallet.id)} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Delete</Button>
          </Grid>

          <Grid item xl={6} lg={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: { xs: 2, lg: 3 } }}>
            <Button style={{ display: 'flex', width: '100%', justifyContent: 'center' }} bordered onClick={() => { setOpenSolanaDeleteModal(false); }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openSolanaModal}
        width="700px"
        title="Add Solana Wallet"
        handleClose={() => {
          setOpenSolanaModal(false);
        }}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: 2 }}>
          <TextInput
            value={solanaKey}
            onChange={(e) => {
              setSolanaKey(e);
            }}
            placeholder="Your Solana Wallet public Key"
            className="mb-5"
          />
          <Button
            onClick={async () => {
              if (solanaKey?.length > 1) {
                onSaveSolanaWallet(solanaKey);
                setOpenSolanaModal(false);
              }
            }}
            disabled={false}
          >
            <span className="no-wrap">{t('Connect Wallet')}</span>
          </Button>
        </Grid>
      </Modal>
    </>
  );
}
