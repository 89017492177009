/* eslint-disable max-len */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-plusplus */

import _ from 'lodash';

export default {};

export function validateAll(data, formValidationConfig) {
  const tmp = {};
  for (let i = 0; i < Object.values(formValidationConfig).length; i++) {
    // console.log(Object.keys(formValidationConfig)[i]);
    // console.log(data);
    // console.log(data[Object.keys(formValidationConfig)[i]]);

    if (_.get(data, Object.keys(formValidationConfig)[i]) || _.get(data, Object.keys(formValidationConfig)[i]) === 0) {
      // do nothing
    } else {
      tmp[Object.keys(formValidationConfig)[i]] = Object.values(formValidationConfig)[i].message;
    }
  }

  if (Object.values(tmp).length) {
    return tmp;
  }

  return false;
}
