/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { Context } from '../../context';
import { EJTree, NonEjTree } from './components/ej';

export default function UsersTree() {
  const {
    user, passTypes, users, fetchSelectedUser,
  } = useContext(Context);

  if (user.Username === 'ej') {
    return <EJTree user={user} passTypes={passTypes} currentUser={user} users={users} fetchSelectedUser={fetchSelectedUser} />;
  }

  return <NonEjTree user={user} passTypes={passTypes} users={users} currentUser={user} fetchSelectedUser={fetchSelectedUser} />;
}
