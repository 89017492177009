/* eslint-disable max-len */
import { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../../components/Modal';
import useApi from '../../../../../utils/api';
import ImagePreview from '../../../../../components/ImagePreview';
import Button from '../../../../../components/Button';
import AppLoader from '../../../../../components/AppLoader';

export default function UploadProfileImageModal({
  image,
  user,
  handleModalClose,
  updateUserImage,
  setCurrentImage,
}) {
  const [loading, setLoading] = useState();
  const { authPost } = useApi();

  const uploadImage = async () => {
    setLoading(true);

    try {
      const compressedFile = new File([image], image.name, { type: image.type });

      const formData = new FormData();
      formData.append('file', compressedFile);
      const res = await authPost('/upload/profile-image', {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      if (!res.url) sessionStorage.setItem('_upic', res.url);
      updateUserImage(res.url);
      setCurrentImage(res.url);
      toast.success('You have succefully changed your profile picture!');
    } catch (err) {
      console.log(err);
      toast.error('We cannot change you profile picture, please try again later!');
    }

    setLoading(false);
    handleModalClose();
  };

  return (
    <Modal
      title="Change your profile image"
      width="500px"
      open={!!image}
      handleClose={handleModalClose}
    >
      <div>
        {loading && <AppLoader overpage />}
        <ImagePreview loading={loading} image={image} user={user} />
        <Button
          onClick={uploadImage}
          loading={loading}
          disabled={loading}
          fullWidth
          type="submit"
          style={{ marginTop: '40px', width: '100%' }}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
}
