import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAuthenticationApi from '../../../Authentication/api';
import useApi from '../../../../utils/api';
import { _actionsArr, _typesArr, columnsConfig } from '../config';

const PAGE_SIZE = 25;

const useHistoryDescriptions = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const { authGet, authPut, authPost } = useApi();
  const { t } = useTranslation();
  const [editEl, setEditEl] = useState(null);

  const getData = async (_page = 1) => {
    try {
      setLoadingData(true);
      const res = await authGet('log-description');
  
      setData(res.data);
      setPage(_page);
      setTotal(res.total);
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong. Please try again later!');
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onEditClick = (_el) => {
    setEditEl(_el.row);
  };
  const onEditModalClose = () => {
    setEditEl(null);
  };

  const changeDescription = (e) => {
    setEditEl({ ...editEl, description: e });
  };

  const onSubmit = async (_data) => {
    try {
      const tmp = { ..._data };
      delete tmp.id;
      if (_data.id) {
        await authPut(`/log-description/${editEl.id}`, { data: _data });
        toast.success('Description Edited Successfully!');
      } else {
        await authPost('/log-description', { data: _data });
        toast.success('Description Added Successfully!');
      }
   
      setEditEl();
      getData();
    } catch (err) {
      console.log(err);
      toast.error(err || 'Something went wrong!');
    }
  };

  const onAddClick = () => {
    setEditEl({});
  };

  const columns = useMemo(() => columnsConfig(t, onEditClick), [t, onEditClick]);
  const totalPages = useMemo(() => Math.ceil(total / PAGE_SIZE) || 0, [total]);

  return {
    // 
    data,
    loadingData,
    page,
    total,
    columns,
    totalPages,
    t,
    editEl,
    onEditModalClose,
    changeDescription,
    onSubmit,
    getData,
    onAddClick,
  };
};

export default useHistoryDescriptions;
