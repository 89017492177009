const config = {
  viewBox: '0 0 18 14',
  children: (
    <>
      <path d="M3.37891 8.30567L7.10924 4.14656L10.6457 7.88916L16.6091 1.61671" stroke="black" strokeLinecap="round" />
      <path d="M3.37891 8.30567L7.10924 4.14656L10.6457 7.88916L16.6091 1.61671" stroke="#CE965F" strokeLinecap="round" />
      <path d="M3.37891 8.30567L7.10924 4.14656L10.6457 7.88916L16.6091 1.61671" stroke="url(#paint0_linear_1453_849)" strokeOpacity="0.3" strokeLinecap="round" style={{ mixBlendMode: 'overlay' }} />
      <path d="M0.948242 0.562012V12.654" stroke="black" strokeLinecap="round" />
      <path d="M0.948242 0.562012V12.654" stroke="#CE965F" strokeLinecap="round" />
      <path d="M0.948242 0.562012V12.654" stroke="url(#paint1_linear_1453_849)" strokeOpacity="0.3" strokeLinecap="round" style={{ mixBlendMode: 'overlay' }} />
      <path d="M12.582 12.6538L0.948548 12.6538" stroke="black" strokeLinecap="round" />
      <path d="M12.582 12.6538L0.948548 12.6538" stroke="#CE965F" strokeLinecap="round" />
      <path d="M12.582 12.6538L0.948548 12.6538" stroke="url(#paint2_linear_1453_849)" strokeOpacity="0.3" strokeLinecap="round" style={{ mixBlendMode: 'overlay' }} />
      <defs>
        <linearGradient id="paint0_linear_1453_849" x1="3.27205" y1="10.9227" x2="14.5232" y2="-2.44802" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint1_linear_1453_849" x1="0.940306" y1="16.2716" x2="2.94799" y2="16.1434" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint2_linear_1453_849" x1="-2.53192" y1="12.6459" x2="-2.39872" y2="14.6529" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default config;
