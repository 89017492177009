import { Grid } from '@mui/material';
import PackageCard from './components/card';
import usePackages from './hook';
import PackageModal from './components/modal';
import Button from '../../../components/Button';

export default function Packages() {
  const {
    submit,
    errors,
    packages,
    onPackageClick,
    selectedPackage,
    handleModalClose,
    message,
  } = usePackages();

  return (
    <>
      <Grid container columnSpacing={3} sx={{ width: '100%' }}>
        <Grid
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}
          item
        >
          <Button
            size="sm"
            onClick={() => {
              onPackageClick();
            }}
          >
            Add +
          </Button>
        </Grid>
        {packages?.map((item) => (
          <Grid key={`package${item._id}`} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <PackageCard onPackageClick={onPackageClick} data={item} />
          </Grid>
        ))}
      </Grid>
      <PackageModal
        errors={errors}
        submit={submit}
        data={selectedPackage}
        handleModalClose={handleModalClose}
        message={message}
      />
    </>
  );
}
