/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { Grid, Box } from '@mui/material';
import clsx from 'clsx';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Context } from '../context';
import Header from './memeGame/Header';
import VoteCoin from './memeGame/VoteCoin';
import VoteModal from './memeGame/VoteModal';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import Icon from '../../../components/Icon';
import MultiplierModal from './memeGame/MultiplierModal';
import { tabTypes } from '../context/helper';
import CountdownToMidnight from './memeGame/Countdown';
import TodayOtherCoins from './memeGame/TodayOtherConts';
import YesterdayOtherCoins from './memeGame/YesterdayOtherConts';
import LockButton from './memeGame/LockButton';

export default function MemeCoinsPool() {
  const {
    loadingCoins, yesterdayMemeCoins, updateGeckoData,
    memeCoinsList, openMemeConfirmModal, todayGame,
    userWonYesterday, tokens, leaderboardRank, activeTab,
  } = useContext(Context);
  const [multiplierModalType, setMultiplierModalType] = useState(null);
  const [width, setWidth] = useState(0);
  const isMobile = useMemo(() => width <= 850, [width]);

  const setWidthCallback = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setWidthCallback();
    window.addEventListener('resize', setWidthCallback);
    return () => window.removeEventListener('resize', setWidthCallback);
  }, []);

  const iconColor = userWonYesterday ? '#793300' : '#DAAB74';

  const onCloseMultiplierModal = () => setMultiplierModalType(null);

  useEffect(() => {
    if (todayGame) {
      const interval = setInterval(() => {
        updateGeckoData();
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => { };
  }, []);

  const renderCont = ({
    type, value, label, color,
  }) => (
    <div className="multiplier-cont">
      <div className="flex">
        <h2>{value}</h2>
        {type !== 'lastPoints' ? (
          <div className="ml-1 cursor-pointer" onClick={() => setMultiplierModalType(type)}>
            <Icon name="SquaredQuestionmark" width={16} height={16} color={color || iconColor} />
          </div>
        ) : null}
      </div>
      <h5>{label}</h5>
    </div>
  );

  const renderOtherConts = (game) => {
    if (loadingCoins) {
      return null;
    }

    if (activeTab === tabTypes.TODAY) {
      const coins = memeCoinsList.find((c) => c.gameId === game.id)?.coins || [];
      return <TodayOtherCoins game={game} coins={coins} leaderboardRank={leaderboardRank} />;
    }

    const coins = yesterdayMemeCoins.find((c) => c.gameId === game?.id)?.coins || [];
    return <YesterdayOtherCoins game={game} coins={coins} leaderboardRank={leaderboardRank} />;
  };

  return (
    <div className="meme-coins-page">
      <Header />

      <div className="flex gap-2 flex-column meme-coins">
        {activeTab === tabTypes.TODAY ? (!todayGame.length ? [{}] : todayGame).map((g, j) => (
          <div className="flex-1" key={`${g.gameId}-${j}`}>
            <Layout
              maxWidth="100%"
              cardMainClassName="flex matchup-card"
              size="xsm"
              cardStyle={{
                paddingLeft: 0, paddingRight: 0, overflow: 'visible', marginTop: 0, borderRadius: 20,
              }}
            >
              <div
                className={clsx('flex flex-column gap-1 justify-center flex-1 meme-coins game-container', j === 0 && 'first-child')}
              >
                {loadingCoins ? (
                  <div className="flex justify-center">
                    <div className="flex flex-column">
                      <ComponentLoader />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-column">
                    <div className="flex flex-wrap mb-4 gap-2 vote-coin-header">
                      <div className="price-badge">
                        <span>
                          {g.price?.toLocaleString('en-us') || 0}
                          {' '}
                        </span>
                        <span className="primary">
                          Coins
                        </span>
                      </div>

                      <div className="price-badge-mobile">
                        <div className="potential-points ">
                          <div className="right">
                            <span>
                              {g.price?.toLocaleString('en-us') || 0}
                            </span>
                          </div>
                          <div className="left">
                            {/* // changed to points */}
                            <span>Coins</span>
                          </div>

                        </div>

                      </div>
                      <div className="flex flex-1 justify-end">
                        <div className="flex flex-column mr-1">
                          <Box sx={{
                            display: {
                              xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block',
                            },
                          }}
                          >
                            <Icon name="Trophy" width={27} height={26} color="#FFB66D" />
                          </Box>

                          <Box sx={{
                            display: {
                              xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none',
                            },
                          }}
                          >
                            <AccessTimeIcon sx={{ color: 'var(--primary-color)', mt: 0.8 }} className="gradient-icon" width={27} height={27} color="#FFB66D" />
                          </Box>
                        </div>
                        <CountdownToMidnight game={g} />
                      </div>
                    </div>

                    <Box className="game-container-box">
                      <Grid
                        className="vote-coin-container"
                        container
                        columnSpacing={{
                          xl: 1, lg: 1, md: 1, sm: 1, xs: '10px',
                        }}
                      >
                        {(memeCoinsList.find((c) => c.gameId === g.id)?.coins || [])
                          .map((i, index) => (
                            <VoteCoin
                              key={index}
                              todayGame={g}
                              index={index}
                              coin={i}
                              isMobile={isMobile}
                              userTokens={tokens}
                            />
                          ))}
                      </Grid>
                    </Box>

                    <LockButton
                      game={g}
                      coins={memeCoinsList.find((c) => c.gameId === g.id)?.coins || []}
                    />
                  </div>
                )}

                {loadingCoins ? null : (
                  <img
                    className="vs-image"
                    alt="vs"
                    src="/images/vs-badge.png"
                  />
                )}
              </div>

              {renderOtherConts(g)}
            </Layout>
          </div>
        )) : null}

        {activeTab === tabTypes.YESTERDAY
          ? (!yesterdayMemeCoins.length ? [{}] : yesterdayMemeCoins).map((g, j) => (
            <div className="flex-1" key={`${g.gameId}-${j}`}>
              <Layout
                maxWidth="100%"
                cardMainClassName="flex matchup-card"
                size="xsm"
                cardStyle={{
                  paddingLeft: 0, paddingRight: 0, overflow: 'visible', marginTop: 0, borderRadius: 20,
                }}
              >
                <div
                  className={clsx('flex flex-column gap-1 justify-center flex-1 meme-coins game-container', j === 0 && 'first-child')}
                >
                  {loadingCoins ? (
                    <div className="flex justify-center">
                      <div className="flex flex-column">
                        <ComponentLoader />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-column">
                      <div className="flex flex-wrap mb-4 gap-2 vote-coin-header">
                        <div className="price-badge">
                          <span>
                            {g?.game?.price || 0}
                            {' '}
                          </span>
                          <span className="primary">
                            Coins
                          </span>
                        </div>
                      </div>
                      <Box className="game-container-box">
                        <Grid
                          className="vote-coin-container"
                          container
                          columnSpacing={{
                            xl: 1, lg: 1, md: 1, sm: 1, xs: '10px',
                          }}
                        >
                          {(g?.coins || []).map((i, index) => (
                            <VoteCoin
                              todayGame={g.game}
                              index={index}
                              coin={i}
                              isYesterday
                              key={index}
                              isMobile={isMobile}
                              userTokens={tokens}
                            />
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  )}

                  {loadingCoins ? null : (
                    <img
                      className="vs-image"
                      alt="vs"
                      src="/images/vs-badge.png"
                    />
                  )}
                </div>
                {renderOtherConts(g.game)}
              </Layout>
            </div>
          )) : null}

        {openMemeConfirmModal ? (
          <VoteModal gameId={openMemeConfirmModal.gameId} renderCont={renderCont} />
        ) : null}

        {multiplierModalType ? (
          <MultiplierModal
            onClose={onCloseMultiplierModal}
            multiplierModalType={multiplierModalType}
          />
        ) : null}
      </div>
    </div>
  );
}
