const config = {
  viewBox: '0 0 13 12',
  children: (
    <>
      <path d="M1.2793 0.670898L12.0176 11.4091" stroke="currentColor" />
      <path d="M12.0176 0.670898L1.2793 11.4091" stroke="currentColor" />
    </>
  ),
};

export default config;
