import { createContext } from 'react';
import useHistoryDescriptions from './hook';

export const MyContext = createContext({
  t: () => {},
  data: [],
  loadingData: false,
  page: 1,
  total: 0,
  columns: [],
  totalPages: 0,
  editEl: null,
  onEditModalClose: () => {},
  changeDescription: () => {},
  onSubmit: () => {},
  getData: () => {},
  onAddClick: () => {},
});

export const MyProvider = ({ children }) => {
  const value = useHistoryDescriptions();
  return <MyContext.Provider value={value}>{ children }</MyContext.Provider>;
};
