import { useContext, useEffect, useState } from 'react';
import {
  Container, Grid, Slider, Typography, 
} from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { modalTypes } from '../config';
import Button from '../../../components/Button';
import RadioGroup from '../../../components/Inputs/RadioGroup';
import { AppContext } from '../../../utils/store/app';

const minWithdrawAmount = 100;

export default function WithdrawModal() {
  const {
    modalData,
    onModalClose,
    onWithdrawSubmit,
    defaultWallet,
    solanaWallet,
  } = useContext(Context);

  const { passTypes } = useContext(AppContext);

  const filteredPassTypes = passTypes.filter(
    (p) => p.active && p.price > 0 && p.price % minWithdrawAmount === 0,
  );

  const [withdrawAmount, setWithdrawAmount] = useState(minWithdrawAmount);
  const [withdrawType, setWithdrawType] = useState('solana');

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);

  const handleWithdrawButtonSubmit = () => {
    const passTypeId = selectedPassTypeId || filteredPassTypes[0].id; 
    const selectedPassType = filteredPassTypes.filter((pt) => pt.id === passTypeId)[0];

    onWithdrawSubmit({
      withdrawType,
      withdrawAmount,
      gamePass: selectedPassType,
    });

    onModalClose();
  };
  
  if (modalData?.type === modalTypes.WITHDRAW) {
    return (
      <Modal
        width="600px"
        title={modalData.description}
        open={!!modalData}
        handleClose={onModalClose}
      >
        <Typography
          className="text-center"
          sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
        >
          Redeem can only be performed 
          {' '}
          <br />
          {`in $${minWithdrawAmount} increments`}
        </Typography>

        {!!defaultWallet && defaultWallet.balance >= minWithdrawAmount ? (
          <Container>
            <RadioGroup
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                color: '#FFFFFF',
              }}
              onChange={(e) => {
                setWithdrawType(e);
              }}
              value={withdrawType}
              setErrors={() => {}}
              name="withdrawType"
              options={[
                { label: 'To Solana Wallet', value: 'solana' },
                { label: 'Redeem for Coin Packs', value: 'passes' },
              ]}
            />

            {withdrawType === 'solana'
              && (!solanaWallet ? (
                <Typography className="text-center">
                  You need to connect your Solana Wallet to the system
                </Typography>
              ) : (
                <Typography className="text-center">
                  {`Transferring $${withdrawAmount} to your solana Wallet ${solanaWallet.publicKey}`}
                </Typography>
              ))}

            {withdrawType === 'passes' && (
              <RadioGroup
                onChange={(e) => {
                  setSelectedPassTypeId(e);
                }}
                value={selectedPassTypeId}
                defaultValue={selectedPassTypeId || filteredPassTypes[0].id}
                setErrors={() => {}}
                name="selectedPassTypeId"
                options={filteredPassTypes.map((p) => ({
                  label: `${p.name} ($${p.price}) ${
                    p?.price
                      ? `(${Math.floor(withdrawAmount / p.price)} packs)`
                      : ''
                  }`,
                  value: p.id,
                }))}
              />
            )}

            <Grid sx={{ marginTop: 2 }}>
              <Slider
                min={minWithdrawAmount}
                max={
                  Math.floor(defaultWallet.balance / minWithdrawAmount)
                  * minWithdrawAmount
                }
                value={withdrawAmount}
                step={minWithdrawAmount}
                getAriaValueText={() => withdrawAmount}
                valueLabelDisplay="auto"
                onChange={(e, v) => {
                  setWithdrawAmount(v);
                }}
              />
              <Button
                onClick={() => {
                  handleWithdrawButtonSubmit();
                }}
                className="mt-3"
              >
                <span className="no-wrap">
                  Redeem (
                  {withdrawAmount}
                  )
                </span>
              </Button>
            </Grid>
          </Container>
        ) : (
          <Typography
            className="text-center"
            sx={{ marginTop: 2, marginBottom: 3 }}
          >
            {`Your current balance is $${defaultWallet?.balance || '0'}`}
          </Typography>
        )}
      </Modal>
    );
  }
}
