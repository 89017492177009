import { useEffect, useRef } from 'react';

export default function MiniChart() {
  const _ref = useRef();

  useEffect(() => {
    let script;

    if (!_ref.current.querySelector('#bitcoin-chart')) {
      script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.async = true;
      script.type = 'text/javascript';
      script.id = 'bitcoin-chart';
      script.innerHTML = JSON.stringify({
        symbol: 'COINBASE:BTCUSD',
        width: '100%',
        height: 300,
        locale: 'en',
        dateRange: '12M',
        colorTheme: 'dark',
        trendLineColor: '#CB8F59',
        underLineColor: 'transparent',
        isTransparent: true,
        autosize: false,
        largeChartUrl: '',
      });

      _ref.current.appendChild(script);
    }

    // return () => {
    //  if (script && _ref.current) {
    //    _ref.current?.removeChild(script);
    //  }
    // };
  }, []);

  return (
    <div ref={_ref} id="mini-chart">
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget" />
        <div className="tradingview-widget-copyright" />
      </div>
    </div>
  );
}
