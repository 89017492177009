import { renderNumber } from '../../utils/string';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column';
    }
    return '';
  },
};

export const defaultUsageReportColumnsConfig = (t) => [
  {
    field: 'username',
    headerName: t('Username'),
    flex: 1,
    minWidth: 120,
    ...defaultColConfig,
  },
  {
    field: 'lassPassDays',
    headerName: t('Days Since Last Purchase'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'playedTotal',
    headerName: t('Total Played'),
    flex: 1,
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'playedLast30Days',
    headerName: t('Played Coins Last 30 days'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'coinsTotal',
    headerName: t('Purchased Coins'),
    flex: 1,
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'coinsLast30Days',
    headerName: t('Purchased Coins Last 30 days'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'coinsBefore30Days',
    headerName: t('Purchased Coins Before 30 days'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'tokenTotal',
    headerName: t('Total Tokens'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
];

export default {};
