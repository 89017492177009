/* eslint-disable max-len */
import { Typography, Container } from '@mui/material';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';

export default function ConfirmAffiliateModal({
  openAffiliateConfirmModal, handleConfirmModalClose, t, onConfirmationModalSubmit,
}) {
  return (
    <Modal
      title={t('Action Confirm')}
      width="550px"
      open={!!openAffiliateConfirmModal}
      handleClose={handleConfirmModalClose}

    >

      <Typography sx={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }}>
        {t('Are you sure you want to be an Ambassador for MemeGames.ai and earn commissions by signing on other users?')}
      </Typography>
      <Typography
        color="primary"
        sx={{
          display: 'flex', justifyContent: 'center', fontSize: '14px', mt: 2,
        }}
      >
        {t('This action cannot be undone!')}
      </Typography>

      <Container sx={{
        display: 'flex', justifyContent: 'center', gap: 3, mt: 2,
      }}
      >

        <Button onClick={() => onConfirmationModalSubmit('yes')} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          {t('Continue')}
        </Button>

      </Container>
    </Modal>
  );
}
