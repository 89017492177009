const config = {
  viewBox: '0 0 14 12',
  children: (
    <g opacity="1">
      <mask id="path-1-inside-1_198_3941" fill="currentColor">
        <path d="M5.65625 11.3438H2.34375V6H0.34375L7 0L13.6562 6H11.6562V11.3438H8.34375V7.34375H5.65625V11.3438Z" />
      </mask>
      <path
        opacity={0.7}
        d="M5.65625 11.3438V12.3438H6.65625V11.3438H5.65625ZM2.34375 11.3438H1.34375V12.3438H2.34375V11.3438ZM2.34375 6H3.34375V5H2.34375V6ZM0.34375 6L-0.325792 5.25723L-2.25918 7H0.34375V6ZM7 0L7.66954 -0.742774L7 -1.34631L6.33046 -0.742774L7 0ZM13.6562 6V7H16.2592L14.3258 5.25723L13.6562 6ZM11.6562 6V5H10.6562V6H11.6562ZM11.6562 11.3438V12.3438H12.6562V11.3438H11.6562ZM8.34375 11.3438H7.34375V12.3438H8.34375V11.3438ZM8.34375 7.34375H9.34375V6.34375H8.34375V7.34375ZM5.65625 7.34375V6.34375H4.65625V7.34375H5.65625ZM5.65625 10.3438H2.34375V12.3438H5.65625V10.3438ZM3.34375 11.3438V6H1.34375V11.3438H3.34375ZM2.34375 5H0.34375V7H2.34375V5ZM1.01329 6.74277L7.66954 0.742774L6.33046 -0.742774L-0.325792 5.25723L1.01329 6.74277ZM6.33046 0.742774L12.9867 6.74277L14.3258 5.25723L7.66954 -0.742774L6.33046 0.742774ZM13.6562 5H11.6562V7H13.6562V5ZM10.6562 6V11.3438H12.6562V6H10.6562ZM11.6562 10.3438H8.34375V12.3438H11.6562V10.3438ZM9.34375 11.3438V7.34375H7.34375V11.3438H9.34375ZM8.34375 6.34375H5.65625V8.34375H8.34375V6.34375ZM4.65625 7.34375V11.3438H6.65625V7.34375H4.65625Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_198_3941)"
      />
    </g>
  ),
};

export default config;
