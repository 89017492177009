export const memeModalConfig = [
  {
    name: 'coin1',
    label: 'Coin 1',
    type: 'ajaxSelect',
    endPoint: '/memecoins', // TODO: add endpoint
    mapper: (val) => ({ label: val.name, value: val.id }), // TODO: add mapper
  },
  {
    name: 'coin2',
    label: 'Coin 2',
    type: 'ajaxSelect',
    endPoint: '/memecoins', // TODO: add endpoint
    mapper: (val) => ({ label: val.name, value: val.id }), // TODO: add mapper
  },
  {
    name: 'startDate',
    label: 'Active Date',
    type: 'date',
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
  },
];

export default null;
