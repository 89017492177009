import { useState } from 'react';

const FlagIcon = ({ countryCode = null }) => {
  const [url, setUrl] = useState(`/icons/flags/${countryCode?.toLowerCase()}.svg`);

  return (
    <div className="flex" style={{ width: 24 }}>
      <img
        src={url}
        title={countryCode ?? 'flag'}
        alt={countryCode ?? 'flag'}
        width={20}
        height={20}
        onError={() => setUrl('/assets/logo_gold.svg')}
      />
    </div>
  );
};

export default FlagIcon;
