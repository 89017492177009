/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import moment from 'moment';
import {
  Container, Popover, Tooltip, Typography, 
} from '@mui/material';
import {
  useMemo, useState, useCallback, useEffect,
  useRef, 
} from 'react';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useMemeGameApi from './api';

function getCalendarRange(date) {
  // Get the current month and year
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  // Get the first and last day of the current month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  // Get the day of the week (0-6) for the first and last day of the month
  const firstDayOfWeek = firstDayOfMonth.getDay();
  const lastDayOfWeek = lastDayOfMonth.getDay();

  // Calculate the start date (previous Sunday before the first day of the month)
  const startDate = new Date(firstDayOfMonth);
  startDate.setDate(firstDayOfMonth.getDate() - firstDayOfWeek);

  // Calculate the end date (next Saturday after the last day of the month)
  const endDate = new Date(lastDayOfMonth);
  endDate.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfWeek));

  return { startDate, endDate };
}

/* eslint-disable react/jsx-filename-extension */
export default function useMemeGames() {
  const {
    getMemeGames, updateMemeGame, createMemeGame, deleteMemeGame, 
  } = useMemeGameApi();
  const [modalEvent, setModalEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopover, setOpenPopover] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarRef = useRef(null);
  const [currentRange, setCurrentRange] = useState(null);
  
  const [loadingForm, setLoadingForm] = useState(false);
  
  const [deleteId, setDeleteId] = useState();
  
  const onDelete = (data) => {
    setDeleteId(data);
  };
  
  const _fetch = async (start, end) => {
    setLoading(true);

    try {
      const data = await getMemeGames(start, end);
      setEvents(data);
    } catch (err) {
      setEvents([{ startDate: moment().add(2, 'days').toDate(), endDate: moment().add(2, 'days').toDate(), id: 1 }]);
    }

    setLoading(false);
  };

  useEffect(() => {
    const { startDate, endDate } = getCalendarRange(new Date());
    setCurrentRange({ start: startDate, end: endDate });
    _fetch(startDate, endDate);
  }, []);

  const onClosePopover = useCallback(() => {
    setAnchorEl(null);
    setOpenPopover(null);
  }, []);

  const calendarEvents = useMemo(() => events.map((ev) => ({
    start: ev.startDate,
    end: ev.startDate,
    title: (
      <div
        onClick={(e) => {
          setOpenPopover(ev.id);
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <Typography sx={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
          {/* // TODO: add title from coins */}
          {ev?.coinOne?.name}
          {' '}
          
        </Typography>
        <Typography sx={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
            
          {' '}
          vs
          
        </Typography>
        <Typography sx={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
        
          {' '}
          {ev?.coinTwo?.name}
        </Typography>
          
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="Delete">
            <DeleteIcon onClick={() => {
              onDelete(ev);
            }}
            />
          </Tooltip>
         
          <EditIcon onClick={() => {
            setModalEvent(ev);
            onClosePopover();
          }}
          />

        </Container>
          
      </div>
    ),
  })), [events, openPopover, anchorEl]);

  const onClose = () => setModalEvent(null);

  const onSubmitForm = async (data) => {
    setLoadingForm(true);

    try {
      if (data.id) {
        await updateMemeGame(data);
        toast.success('Game Updated!');
        _fetch(currentRange.start, currentRange.end);
      } else {
        await createMemeGame(data);
        toast.success('Game Created!');
        _fetch(currentRange.start, currentRange.end);
      }
      setLoadingForm(false);
      onClose();
    } catch (err) {
      toast.error(err);
      console.log(err);
      setLoadingForm(false);
    }
  };
    
  const onDeleteSubmit = async () => {
    try {
      await deleteMemeGame(deleteId);
      toast.success('Game deleted successfully!');
      setDeleteId();
      _fetch(currentRange.start, currentRange.end);
    } catch (err) {
      console.log(err);
    }
  };
  
  const cancelDelete = () => {
    setDeleteId();
  };

  const onRangeChange = async (date) => {
    console.log(date);
    const end = moment(date?.end);
    const start = moment(date?.start);
    const endMonth = end.month();
    const startMonth = start.month();
    const endYear = end.year();
    const startYear = start.year();
    setCurrentRange({ start: date.start, end: date.end });

    await _fetch(start, end);
  };

  return {
    loading,
    onClose,
    modalEvent,
    loadingForm,
    onSubmitForm,
    setModalEvent,
    calendarEvents,
    
    cancelDelete,
    deleteId,
    onDeleteSubmit,
    onRangeChange,
    calendarRef,
  };
}
