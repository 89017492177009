import clsx from 'clsx';
import PropTypes from 'prop-types';
import PhoneInputCont from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function PhoneInput({
  type,
  name = '',
  className,
  value = '',
  focused = false,
  placeholder = '',
  size = 'md',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
}) {
  return (
    <div className="form-input-group">
      <div
        className={clsx(
          'form-input phone-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
        )}
      >
        <PhoneInputCont
          id={name}
          country="us"
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          className={clsx(
            focused && 'focused',
            size,
            type,
            disabled && 'disabled',
            error && 'error',
          )}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

PhoneInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
