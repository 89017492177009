import React, { createContext } from 'react';
import useGeneralStore from './hook';
import { defaultTheme } from '../../../constants/theme';

export const GeneralContext = createContext({
  config: {},
  configLoading: true,
  theme: defaultTheme,
});

/**
 * GeneralContext Provider
 * @returns {ReactNode}  wrapper for GeneralContext
 */
export const GeneralProvider = ({ children = null }) => {
  const value = useGeneralStore();

  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  );
};
