import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import Modal from '../../../../../components/Modal';
import { Context } from '../../context';
import Select from '../../../../../components/Inputs/Select';
import Button from '../../../../../components/Button';

export default function ActiveTeamModal() {
  const { t } = useTranslation();
  const {
    selectedUser,
    handleCloseTeamModal,
    user,
    changeTeamModal,
    onActiveTeamSubmit,
    nrOfRanks,
  } = useContext(Context);

  const [defaultActiveTeam, setDefaultActiveTeam] = useState(user.activeTeam || 1);

  return (
    <Modal
      title="Change Active Team"
      width="550px"
      open={!!changeTeamModal}
      handleClose={handleCloseTeamModal}
    >
      <Container className="team-modal">
        <Container>
          <Select
            onChange={(e) => {
              setDefaultActiveTeam(e);
            }}
            value={`${defaultActiveTeam}`}
            options={Array.from({ length: nrOfRanks }, (_, index) => ({
              value: `${index + 1}`,
              label: `Team ${index + 1}`,
            }))}
          />
        </Container>
        <Container sx={{ mt: 3 }}>
          <Button onClick={() => onActiveTeamSubmit(defaultActiveTeam)}>
            Submit
          </Button>
        </Container>
        <Typography
          onClick={handleCloseTeamModal}
          sx={{
            textDecoration: 'underline',
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            cursor: 'pointer',
          }}
        >
          {' '}
          {t('Close')}
        </Typography>
      </Container>
    </Modal>
  );
}
