import { createContext } from 'react';
import useAdminReportHook from './hook';

export const AdminReportContext = createContext({
  t: () => { },
  user: {},

  usageData: [],
  loadedUsageData: false,
  usageDataPagination: {},
  fetchUsageData: () => { },

  usageDataColumnsConfiguration: [],

  downloadingCsv: false,
  downloadUsageReportCsv: () => { },
});

export const AdminReportProvider = ({ children }) => {
  const value = useAdminReportHook();
  return <AdminReportContext.Provider value={value}>{children}</AdminReportContext.Provider>;
};
