/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import useApi from '../../../utils/api';

export default function CustomAutocomplete({
  name = '',
  value = '',
  focused = false,
  placeholder = '',
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  size = 'md',
  endPoint = '/',
  mapper = (val) => val,
  disabled = false,
  error,
}) {
  const { authGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      // if (!searchInput) {
      //  setOptions([]);
      //  return;
      // }

      setLoading(true);
      try {
        const res = await authGet(`${endPoint}${searchInput ? `?search=${encodeURIComponent(searchInput)}` : ''}`);
        if (Array.isArray(res)) {
          setOptions(res.map(mapper));
        } else if (Array.isArray(res?.data)) {
          setOptions(res.data.map(mapper));
        } else if (Array.isArray(Object.keys(res.data))) {
          setOptions(Object.keys(res.data).map(mapper));
        }
      } catch (err) {
        console.error('Error fetching options:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [searchInput]);

  return (
    <div className="form-input select-input flex-col">
      <Autocomplete
        id={name}
        value={value || null}
        onChange={(event, newValue) => {
          onChange(newValue || '');
        }}
        inputValue={searchInput}
        onInputChange={(event, newInputValue) => {
          setSearchInput(newInputValue);
        }}
        sx={{
          '.MuiOutlinedInput-root': {
            padding: '5px',
            borderRadius: '8px',
          },
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        options={options}
        loading={loading}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            className={clsx('select', focused && 'focused', size, error ? 'error' : 'clean', 'besaaa')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),

            }}
            error={Boolean(error)}
            helperText={error}
            disabled={loading || disabled}
            // sx={{ mt: -1 }}
            sx={{
              '&.MuiFormLabel-root': {
                mt: '-5px',
              },
            }}
          />
        )}
      />
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

CustomAutocomplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  mapper: PropTypes.func,
  onChange: PropTypes.func,
};
