/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {
  Typography, Grid,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import { modalTypes } from '../config';
import BtcWalletNumber from './btcWallet';
import Modal from '../../../components/Modal';
import PurchasePasses from '../../Memberships/components/PurchasePasses';
import useApi from '../../../utils/api';
import SolanaWallet from './solanaWallet';

export const getDisclaimerContent = () => (
  <Typography sx={{ fontSize: '11px', color: 'GrayText', textTransform: 'uppercase' }}>
    Be careful!
    {' '}
    <a href="https://memegames.ai/" target="_blank" rel="noreferrer">
      MemeGames.ai
    </a>
    {' '}
    is not responsible for any lost funds sent to an incorrect address.
  </Typography>
);

export default function WalletManagement() {
  const {
    t,
    defaultWallet,
    setDefaultWallet,
    setModalData,
    solanaWallet,
  } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [solanaBalance, setSolanaBalance] = useState(0);
  const { authGet } = useApi();

  const fetchWalletBalance = async (publicKey) => {
    try {
      const balance = await authGet(`/wallets/solana?publicKey=${publicKey}`);
      setSolanaBalance(balance);
    } catch (err) {
      // toast.error('Invalid Solana Wallet, you must connect a valid Wallet');
      setSolanaBalance(0);
      console.log(err);
    }
  };

  useEffect(() => {
    if (solanaWallet?.publicKey) {
      fetchWalletBalance(solanaWallet.publicKey);
    }
  }, [solanaWallet]);

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 4, padding: { xs: 0, lg: '0 24px' } }}>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: { xs: '21px', lg: '26px' },
          fontWeight: 600,
          mb: 1,
          textAlign: 'center',
        }}
      >
        {t('Ambassador Wallets')}
      </Typography>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginY: 4 }}>
          <TextInput
            className="wallet-input"
            value={defaultWallet?.balance?.toLocaleString('en-us') || '0'}
            onChange={(e) => {
              setDefaultWallet(e);
            }}
            readOnly
            startAdornment={(
              <span className="flex items-center">
                <Typography sx={{
                  pl: 2, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                }}
                >
                  {defaultWallet.currency}
                </Typography>
              </span>
            )}
            endAdornment={(
              <div className="flex items-center">
                <Button
                  onClick={() => setModalData({
                    type: modalTypes.WITHDRAW, value: 0, setValue: setDefaultWallet, description: t('Redeem'),
                  })}
                  className="py-auto my-auto mr-3"
                  bordered
                  size="sm"
                >
                  <span className="no-wrap">{t('Redeem')}</span>
                </Button>
              </div>
            )}
          />
          <Typography sx={{
            padding: 1, fontSize: '14px', opacity: 0.5, textAlign: { xs: 'center' },
          }}
          >
            {t('Withdrawals are in $100 increments. Please allow 3 full business days for redemption in Solana. You may chose to withdraw in coin packs which are available immediately')}
          </Typography>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <SolanaWallet solanaBalance={solanaBalance} />
      </Grid>
      
      <BtcWalletNumber />

      <Modal
        open={openModal}
        width="500px"
        title="Purchase Coins"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <PurchasePasses />
      </Modal>
    </Layout>
  );
}
