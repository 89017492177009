import i18n from 'i18next';
import HttpBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { BASE_URL } from '../constants';

export const i18nSupportedLanguages = ['en', 'it'];
export const i18nCachePrefix = 'i18next_res_';

export const i18nCacheKeys = i18nSupportedLanguages.map(
  (lng) => `${i18nCachePrefix}${lng}-translation`,
);

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly',
    nsSeparator: '::',
    keySeparator: ':',
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          loadPath: `${BASE_URL}/{{lng}}/get-labels`,
        },
      ],
    },
  });
