/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
// import AppleSignin from 'react-apple-signin-auth';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import useAuthenticationApi from '../api';
import Button from '../../../components/Button';
import Layout from '../../../components/AuthLayout';
import { useAuthentication } from '../../../utils/store';
import { useNonAuthApi } from '../../../utils/api';
import { getFromSession } from '../../../utils/session';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticateUser } = useAuthentication();
  const {
    getGoogleUser,
    logInGoogle,
    getParent,
    // logInApple
  } = useAuthenticationApi();
  const { nonAuthGet } = useNonAuthApi();
  // const { sponsor } = useParams();

  const [signupParent, setSignupParent] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const host = window.location.href;
    const uri = new URL(host);
    const { searchParams } = uri;

    if (searchParams.size === 1) {
      const username = searchParams.get('sponsor');

      if (username) {
        const _fetch = async () => {
          try {
            const res = await getParent(username);
            setSignupParent(res);
            sessionStorage.setItem('signupParent', JSON.stringify(res));
          } catch (err) {
            if (err === 'Not Found') {
              navigate('/dashboard/registration/new');
            }
          }
        };

        _fetch();
      }
    }
    setLoading(false);
  }, []);

  const onSignInGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const user = await getGoogleUser(tokenResponse.access_token);
      const _user = {
        givenName: user.given_name,
        familyName: user.family_name,
        picture: user.picture,
        email: user.email,
        emailVerified: user.email_verified,
        locale: user.locale,
        sub: user.sub,
        name: user.name,
      };

      try {
        const loginRes = await logInGoogle({ user: _user });
        // check if the payment process is done
        if (loginRes.user.signupStep === 'finished') {
          authenticateUser(loginRes.user, loginRes.token);
          const _params = sessionStorage.getItem('circle-redirect');

          if (_params?.length > 0) {
            window.location.href = `${process.env.REACT_APP_CIRCLE_OAUTH}${_params}`;
          } else {
            navigate('/');
          }
        } else if (loginRes === 'Unauthorized') {
          navigate('/dashboard/registration/new');
          sessionStorage.setItem('registration', JSON.stringify({ ..._user, source: 'google' }));
        }
      } catch (err) {
        console.log('--err--', err, err === 'Unauthorized');
        if (err === 'Unauthorized') {
          navigate('/dashboard/registration/new');
          sessionStorage.setItem('registration', JSON.stringify({ ..._user, source: 'google' }));
        }
        if (err?.statusCode === 402) {
          const eUser = err.user;
          const enrolledParentID = await nonAuthGet(`dashboard/enroll/${eUser.EnrolledParentID}`);
          sessionStorage.setItem('SignupSID', eUser.SID);
          sessionStorage.setItem('signupUser', JSON.stringify(eUser));
          sessionStorage.setItem('token', err.token);
          sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
          sessionStorage.setItem(
            'registration',
            JSON.stringify({
              givenName: eUser.Name,
              familyName: eUser.Surname,
              email: eUser.Email,
              source: 'google',
            }),
          );
          navigate('/dashboard/enroll-now/pay');
        }
      }
    },
  });

  const onSignInEmail = () => {
    const sponsor = getFromSession('signupParent');
    if (sponsor) {
      navigate('/signup/form');
    } else {
      navigate('/login');
    }
  };

  const onSignUpClick = () => {
    navigate('/signin');
  };

  // useEffect(() => {
  //   console.log(signupParent);
  // }, [signupParent]);

  return (

    <Layout>
      {loading ? <>Loading...</> : (
        <div className="login-page">
          <div className="flex-row justify-center mb-7" style={{ minHeight: 255 }}>
            <img alt="logo-black" src="/assets/logo_black.svg" height={255} />
          </div>

          <h1 className={!signupParent ? 'mb-13' : ''}>{t(`${signupParent ? 'SIGN UP' : 'LOG IN'}`)}</h1>
          {signupParent && (
            <Box sx={{ mb: 4, mt: 1 }}>
              <p
                style={{
                  color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '300',
                }}
              >
                {'Sponsor: '}

              </p>
              <p
                style={{
                  color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '200',
                }}
              >
                {signupParent?.firstName && signupParent?.lastName ? `${signupParent?.firstName || ''} ${signupParent?.lastName || ''} ` : signupParent.username}

              </p>
              <p
                style={{
                  color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
                }}
              >
                {signupParent.mobile || ''}
              </p>
              <p
                style={{
                  color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
                }}
              >
                {signupParent.email || ''}
              </p>
            </Box>

          )}

          <Button
            onClick={() => {
              // if (signupParent) {
              onSignInGoogle();
              // } else {
              //  navigate('/dashboard/registration/new');
              // }
            }}
            icon={<img src="/assets/google.svg" alt="google" />}
            bordered
            className="mb-3"
          >
            {t(`${signupParent ? 'Sign up with' : 'Log In with'}`)}
            {' '}
            Google
          </Button>

          <Button
            onClick={onSignInEmail}
            icon={<AlternateEmailIcon fontSize="large" />}
            bordered
            className="mb-3 mt-3"
          >
            {t(`${signupParent ? 'Sign up with' : 'Log In with'}`)}
            {' '}
            Email
          </Button>
        </div>
      )}

      {signupParent && (
        <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <Typography sx={{ fontSize: '14px' }} color="white">
            {t('Already have an account?')}
          </Typography>
          <Typography
            onClick={() => onSignUpClick()}
            sx={{
              fontSize: '14px', color: 'var(--primary-color) !important', fontWeight: 600, ml: 1, cursor: 'pointer',
            }}
          >
            {t('Log In')}
          </Typography>
        </Container>
      )}
    </Layout>

  );
}
