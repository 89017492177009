import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const columnsConfig = (t, onEditClick) => [
  {
    field: 'walletType',
    headerName: t('Type'),
    minWidth: 200,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    sortable: true,
  },

  {
    field: 'action',
    headerName: t('Action'),
    minWidth: 200,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    sortable: true,
  },

  {
    field: 'description',
    headerName: t('Description'),
    minWidth: 500,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    sortable: true,
  },

  {
    field: 'icons',
    flex: 1,
    headerName: '',
    align: 'right',
    columnAlign: 'right',
    type: 'actions',
    headerAlign: 'right',
    renderCell: (_data) => (
      <div className="flex justify-between items-center ">
        <div style={{ width: '30px', maxWidth: '30px' }}>
          <Tooltip title={t('Edit')}>
            <div>
              <EditIcon sx={{ cursor: 'pointer' }} onClick={() => onEditClick(_data)} height={20} width={20} color="primary" />
            </div>
          </Tooltip>
        </div>
      </div>
    ),
  },

];

export const defaultFormValues = {};

export const _typesArr = [
  {
    label: 'MG-Coin',
    value: 'mg-coin',
  },
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Investment',
    value: 'investment',
  },
];

export const _actionsArr = [
  {
    label: 'Played',
    value: 'played',
  },
  {
    label: 'Transfer',
    value: 'transfer',
  },
  {
    label: 'Receive',
    value: 'receive',
  },
  {
    label: 'Transaction',
    value: 'transaction',
  },
];

export const formConfig = [
  {
    name: 'description',
    label: 'Description',
    type: 'text',
  },

  {
    name: 'walletType',
    label: 'Type',
    type: 'select',
    options: _typesArr,
  },

  {
    name: 'action',
    label: 'Action',
    type: 'select',
    options: _actionsArr,
  },

];

export const getFormConfig = () => formConfig;

export const formValidationConfig = {
  description: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  walletType: { isEmpty: false, type: 'obj', message: 'Please do not leave this field empty!' },

};

export default {};
