/* eslint-disable object-curly-newline */
/* eslint-disable padded-blocks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';

export default function ImagePreview({ image }) {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  return (
    <div>
      <section className="cont w-full h-full ">
        <div className="preview-profile-pic">
          {preview && <img src={preview} alt="Preview" />}
        </div>
      </section>
    </div>
  );
}
