/* eslint-disable no-restricted-syntax */

// obj1 -> default data
// obj2 -> new changed data
function getChangedData(obj1, obj2, requiredKeys) {
  const changedData = {};
  const requiredData = {};

  for (const key of requiredKeys) {
    if (obj1[key] !== obj2[key]) {
      changedData[key] = obj2[key];
    } else requiredData[key] = obj2[key];
  }

  return { ...changedData, ...requiredData };
}
export default getChangedData;
