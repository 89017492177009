import { Container } from '@mui/material';
import ComponentLoader from '../../../components/ComponentLoader';
import useLeaderboardApi from '../context/api';
import Table from '../../../components/Table';

const TopAllTime = () => {
  const {
    loadingTopRank,
    topRankData,
    leaderboardTopColumns,
  } = useLeaderboardApi();

  return loadingTopRank ? (
    <ComponentLoader />
  ) : (
    <Container maxWidth="100%" sx={{ padding: { xs: 0 }, maxWidth: { xs: '100%' } }}>
      <Table columns={leaderboardTopColumns} rows={topRankData} className="table" />
    </Container>
  );
};

export default TopAllTime;
