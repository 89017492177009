/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { toast } from 'react-toastify';
import {
  Box, Checkbox, Grid, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import AppLoader from '../../../components/AppLoader';
import Layout from '../../../components/CardLayout';
import useApi from '../../../utils/api';
import 'react-multi-email/dist/style.css';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import Select from '../../../components/Inputs/Select';

const sendToOptions = [
  {
    value: 'ambassadorsOnly',
    label: 'Ambassadors Only',
  },
  {
    value: 'founderAndHigher',
    label: 'Founder and Higher Only',
  },
  {
    value: 'newFounders',
    label: 'New Founders',
  },

  {
    value: 'directorsOrHigherOnly',
    label: 'Directors or higher only',
  },

];

const getLabel = (email, index, removeEmail) => (
  <div data-tag key={index}>
    <div data-tag-item>{email}</div>
    <span data-tag-handle onClick={() => removeEmail(index)}>
      ×
    </span>
  </div>
);

export default function SendEmail() {
  const { authPost, authGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [emails, setEmails] = useState([]);
  const [all, setAll] = useState(false);

  const [sendTo, setSendTo] = useState();
  const [template, setTemplate] = useState();
  const [disabledSendTo, setDisabledSendTo] = useState(false);
  const [disabledEmails, setDisabledEmails] = useState(false);
  const [templates, settemplates] = useState();

  const onSubmit = async () => {
    setLoading(true);

    try {
      const res = await authPost('/notifications', {
        data: {
          emails, all, templateId: template.id, sendTo,
        },
      });

      setText('');
      setEmails([]);
      setAll(false);
      toast.success(res);
    } catch (err) {
      toast.error(err || 'An error occured. Please try again later!');
    }
    setLoading(false);
  };

  const getTemplates = async () => {
    try {
      const res = await authGet('/notifications/templates');
      settemplates(res.map((t) => ({ ...t, value: { ...t }, label: t.name })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (all) {
      setDisabledSendTo(true);
      setDisabledEmails(true);
      setSendTo();
    } else {
      setDisabledSendTo(false);
      setDisabledEmails(false);
    }
  }, [all]);

  useEffect(() => {
    if (emails.length > 0) {
      setSendTo();
    }
  }, [emails]);

  useEffect(() => {
    if (sendTo) {
      setEmails([]);
    }
  }, [sendTo]);

  return (
    <div className="send-email-page">
      {loading && <AppLoader overpage />}
      <Layout className="game-payouts" maxWidth="xl" cardMainClassName="pt-10 pb-10" size="xsm">
        <div className="p-10">
          <h1 className="mb-4">Send Email</h1>
          <div className="form">

            <Grid container>

              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>

                <Box sx={{ pointerEvents: disabledEmails ? 'none' : '', opacity: disabledEmails ? 0.65 : 1, cursor: disabledEmails ? 'not-allowed' : 'default' }} className="form-group">
                  <div className="label flex-row justify-between">
                    <label>
                      Emails
                    </label>
                  </div>
                  <ReactMultiEmail
                    placeholder="Input your email"
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    autoFocus
                    getLabel={getLabel}
                    disabled={disabledEmails}
                  />
                </Box>

              </Grid>

              <Grid xl={4} lg={4} md={4} sm={12} xs={12} item sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>

                <Box
                  className="form-group flex"
                >
                  <div className="label flex flex-column">
                    <label>
                      Send to all
                    </label>
                  </div>
                  <Checkbox checked={all} onChange={(v) => setAll(v.target.checked)} />
                </Box>

              </Grid>

            </Grid>
            {/* <div className="form-group">
              <div className="label flex-row justify-between">
                <label>
                  Body
                </label>
              </div>
              <TextInput type="textarea" focused value={text} onChange={setText} />
            </div> */}

            <Box
              sx={{
                pointerEvents: disabledSendTo ? 'none' : '',
                opacity: disabledSendTo ? 0.65 : 1,
                cursor: disabledSendTo ? 'not-allowed' : 'default',
              }}
              className="form-group flex"
            >
              <div className="label flex flex-column">
                <label>
                  Send To
                </label>
              </div>
              <Select sx={{ width: '350px', ml: 2 }} onChange={(e) => setSendTo(e)} options={sendToOptions} value={sendTo} name="templates" size="sm" />
            </Box>

            <div className="form-group flex">
              <div className="label flex flex-column">
                <label>
                  Choose Template

                </label>
              </div>
              <Select sx={{ width: '400px', ml: 2 }} onChange={(e) => setTemplate(e)} options={templates} value={template} name="templates" size="sm" />
            </div>
          </div>

          {
            template ? (
              <Box>
                <Typography> Preview</Typography>

                <img style={{ height: '300px' }} src={`https:${template?.thumbnail}`} alt={template?.label} />
              </Box>
            )
              : ''
          }

          <Button className="mt-4" onClick={onSubmit}>
            Send
          </Button>
        </div>
      </Layout>
    </div>
  );
}
