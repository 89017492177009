/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import {
  Typography, Grid, CircularProgress, Pagination, Box,
  Tabs,
  Tab,
} from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import { Context } from '../context';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';

export default function TransferHistory() {
  const {
    loadingHistory, columns, historyData, totalPages, historyPage, fetchHistory, t, transferTabsValue, handleTabTransfersChange, columnsTransfers,
    totalTransfersPages, getTransfersHistory,
    transfersData,
    transferesPage,
    loadingTransfersTable,
  } = useContext(Context);

  return (
    <Layout maxWidth="xl">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={transferTabsValue} onChange={handleTabTransfersChange} aria-label="basic tabs example">
          <Tab label="Transactions History" {...a11yProps(0)} />
          {/* <Tab label="Transfers History" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={transferTabsValue} index={0}>

        <Grid item container xs>
          {loadingHistory ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <Grid item container xs rowSpacing={2}>
              <Grid
                item
                container
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="stretch"
              >
                <Table
                  loading={loadingHistory}
                  columns={columns}
                  rows={historyData}
                  className="table mb-4"
                />
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                <Pagination
                  count={totalPages}
                  page={historyPage}
                  onChange={(val, page) => {
                    fetchHistory(page);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
      {/* <CustomTabPanel value={transferTabsValue} index={1}>
        <Grid item container xs>
          {loadingTransfersTable ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <Grid item container xs rowSpacing={2}>
              <Grid
                item
                container
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="stretch"
              >
                <Table
                  loading={loadingTransfersTable}
                  columns={columnsTransfers}
                  rows={transfersData}
                  className="table mb-4"
                />
              </Grid>
              <Grid item container xs={12} justifyContent="flex-end">
                <Pagination
                  count={totalTransfersPages}
                  page={transferesPage}
                  onChange={(val, page) => {
                    getTransfersHistory(page);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>      
      </CustomTabPanel> */}

    </Layout>
  );
}
