import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function CustomCheckbox({ onChange, value = false, name }) {
  const handleChange = () => {
    onChange(!value, name);
  };

  return (
    <Checkbox
      color="primary"
      checked={value}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      label="test"
      sx={{
        width: 'fit-content',
        justifyContent: 'start',
        alignContent: 'start',
        display: 'block',
        my: 'auto',
        py: 'auto',
      }}
    />
  );
}
