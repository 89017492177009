import { useState } from 'react';
import dayjs from 'dayjs';
import CustomAccordion from '../../../../components/Accordion';
import UserAccordionSummary from './UserAccordionSummary';
import UserAccordionBody from './UserAccordionBody';

const UserAccordion = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomAccordion
      _id={user.id}
      expanded={isOpen}
      className="user-accordion"
      setExpanded={() => setIsOpen((current) => !current)}
      summary={(
        <UserAccordionSummary
          image={user.image}
          name={user.username}
          expiration={dayjs(user.dateExpiring).format('MMMM DD')}
        />
      )}
      details={(
        <UserAccordionBody
          name={user.displayName}
          mobile={user.mobile}
          activation={dayjs(user.dateLastRenewed).format('DD/MM/YYYY')}
          email={user.email}
          pack={user.package || 'N/A'}
          rank={user.rankName}
        />
      )}
    />
  );
};

export default UserAccordion;
