/* eslint-disable prefer-destructuring */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable brace-style */
/* eslint-disable max-len */
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/AuthLayout';
import Form from '../../../components/Form';
import { validateAll } from '../../../components/Form/config';
import useAuthenticationApi from '../api';
import { getFromSession } from '../../../utils/session';
import { validateEmail } from '../../../utils/string';
import AppLoader from '../../../components/AppLoader';
import ConfirmAffiliateModal from '../components/confirmAffiliateModal';
// import validatePassword from '../../../utils/data/validatePassword';

const formConfig = [
  {
    name: 'username',
    type: 'text',
    label: 'Username',
    placeholder: 'Username',
    subLabel: '',
    format: (item) => item.trim().replace(' ', '').replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase(),
  },
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    format: (item) => item,

  },

  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    format: (item) => item,

  },
  {
    name: 'email',
    type: 'text',
    label: 'Email',
    placeholder: 'Email',
    format: (item) => item.replace(' ', '').trim(),

  },
  {
    name: 'mobile',
    label: 'Phone Number',
    type: 'phone',
    placeholder: 'Phone Number',
  },
  {
    name: 'country',
    label: 'Country',
    type: 'ajaxSelect',
    endPoint: '/enums/countries',
    mapper: (val) => ({ label: val.Name, value: val.ID }),
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    format: (item) => item.replace(' ', '').trim(),
  },
  {
    name: 'retypePassword',
    type: 'password',
    label: 'Retype Password',
    format: (item) => item.replace(' ', '').trim(),
  },
  {
    name: 'isProduct',
    label: 'I would like to be a gamer at memegames.ai.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
    underLabel: 'Become a gamer to play our daily Meme Coin games. You will be asked to use or purchase a game pack, which will give you MG Coins to play with.',
    underLabelStyle: { color: '#e0e0e0' },
    checked: true,
  },
  {
    name: 'isAffiliate',
    label: 'I also wish to be an ambassador.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
    underLabel: 'Join our ambassador program to sign up other gamers and ambassadors, create a team and earn commissions. ',
    underLabelStyle: { color: '#e0e0e0' },

  },
  {
    name: 'receiveNewsletter',
    label: 'I would like to sign up to receive the MemeGames newsletter and company updates.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
  },
];

const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  country: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  mobile: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  lastName: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  firstName: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  email: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  password: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  retypePassword: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

const defaultData = {
  country: 223,
  username: '',
  lastName: '',
  firstName: '',
  email: '',
  // state: '',
  // city: '',
  password: '',
  retypePassword: '',
  mobile: '',
  isProduct: true,
  isAffiliate: false,
  receiveNewsletter: true,
};

export default function SignupForm() {
  const [formConfiguration, setformConfiguration] = useState(formConfig);
  const [currentData, setCurrentData] = useState([]);
  const { t } = useTranslation();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signUpEmail } = useAuthenticationApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);
  const [openAffiliateConfirmModal, setOpenAffiliateConfirmModal] = useState(false);
  const handleConfirmModalClose = () => {
    setOpenAffiliateConfirmModal(false);
  };
  // const getFormConfig = () => formConfiguration;

  useEffect(() => {
    const allLinks = [...formConfiguration].filter((a) => a.name !== 'affiliateLink');
    const affiliateLink = { type: 'label', name: 'affiliateLink', label: `Signup link: https://${currentData?.username || 'Username'}.${process.env.REACT_APP_BASE_DOMAIN}` };
    if (currentData.isAffiliate) {
      // setformConfiguration([{ ...username, subLabel: `( ${currentData?.username || 'Username'}.${process.env.REACT_APP_BASE_DOMAIN} )` }, ...otherInp]);
      setformConfiguration([...allLinks, affiliateLink]);
    }
    else {
      setformConfiguration([...allLinks]);
    }
  }, [currentData.username, currentData.isAffiliate]);

  useEffect(() => {
    if (!signupParent) {
      navigate('/');
    }
  }, []);

  const onSubmit = async (_data) => {
    const _validate = validateAll(_data, formValidationConfig, t);

    if (_validate) {
      setErrors(_validate);
    } else if (_data?.username?.length < 4 || loading) {
      setErrors({ username: 'Username must have 4 characters or more' });
      toast.error(t('Username must have 4 characters or more'));
    } else if (!validateEmail(_data.email)) {
      setErrors({ email: t('Please enter a valid email!') });
      toast.error(t('Please enter a valid email!'));
    }

    else if (!_data.isAffiliate && !_data.isProduct) {
      toast.error('To continue, you must be either an Ambassador, a Gamer, or both!');
    }
    // else if (!validatePassword(_data.password)) {
    //  setErrors({
    //    password: 'Password must have at least 8 characters. Must have at least one Capital Letter, one number, and one special character.',
    //  });
    // } 
    else if (_data.password !== _data.retypePassword) {
      setErrors({
        retypePassword: t('Passwords do not match!'),
      });
    } else {
      try {
        // axios 
        setLoading(true);
        const res = await signUpEmail({ ..._data, affiliateUsername: signupParent.username });

        const _registration = {
          givenName: _data.firstName,
          familyName: _data.lastName,
          email: _data.email,
          emailVerified: false,
          name: `${_data.firstName} ${_data.lastName}`,
          picture: '',
          source: 'email',
          affiliateUsername: signupParent?.username,
        };

        sessionStorage.setItem('SignupSID', res.user.SID);
        sessionStorage.setItem('signupUser', JSON.stringify(res?.user));
        sessionStorage.setItem('token', res?.token);
        sessionStorage.setItem('registration', JSON.stringify(_registration));

        if (process.env.REACT_APP_VERIFY_EMAIL === 'true' || process.env.REACT_APP_VERIFY_EMAIL === true) {
          navigate('/verify-email');
        }
        else {
          navigate('/dashboard/enroll-now/pay');
        }
      } catch (err) {
        console.log('signuperr--', err);

        if (Array.isArray(err) && err.length >= 1) {
          let errObj = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const e of err) {
            for (const key in e) {
              let msg = '';
              if (Array.isArray(e[key]) && e[key].length >= 1) {
                msg = e[key][0];
              }
              errObj = { ...errObj, [key]: msg };
            }
          }

          setErrors(errObj);
        } else {
          setErrors(err);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setErrors();
  }, [currentData]);

  useEffect(() => {
    if ((!currentData.isAffiliate && !currentData.isProduct) && currentData?.country) {
      toast.error('To continue, you must be either an Ambassador, a Gamer, or both!');
    }
  }, [currentData.isProduct, currentData.isAffiliate]);

  useEffect(() => {
    if (currentData?.isAffiliate) {
      setOpenAffiliateConfirmModal(true);
    }
  }, [currentData?.isAffiliate]);

  const onConfirmationModalSubmit = () => {
    handleConfirmModalClose();
  };

  // useEffect(() => {
  //   console.log({ formConfiguration });
  // }, [formConfiguration]);

  const onSignUpClick = () => {
    navigate('/login');
  };

  return (
    <AuthLayout style={{ padding: '55px' }}>
      {loading ? <AppLoader overpage /> : ''}
      <Typography sx={{
        display: 'flex', flexDirection: 'column', textAlign: 'center', color: 'var(--primary-color) !important', justifyContent: 'center', justifyItems: 'center', fontSize: '22px', mb: 0, fontWeight: 600,
      }}
      >
        JOIN MEMEGAMES.AI
      </Typography>

      {signupParent && (
        <Box sx={{ mb: 4, mt: 1 }}>
          <p
            style={{
              color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '300',
            }}
          >
            {'Sponsor: '}

          </p>
          <p
            style={{
              color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '200',
            }}
          >

            {signupParent?.firstName && signupParent?.lastName ? `${signupParent?.firstName || ''} ${signupParent?.lastName || ''} ` : signupParent.username}

          </p>
          <p
            style={{
              color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
            }}
          >
            {signupParent.mobile || ''}
          </p>
          <p
            style={{
              color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
            }}
          >
            {signupParent.email || ''}
          </p>
        </Box>
      )}

      <Form
        defaultFormValue={defaultData}
        size="md"
        config={formConfiguration}
        submitLabel="Signup"
        onSubmit={onSubmit}
        errors={errors}
        formLoading={loading}
        setCurrentData={setCurrentData}
        buttonType="button"
      />

      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Typography sx={{ fontSize: '14px' }} color="white">
          {t('Already have an account?')}
        </Typography>
        <Typography
          onClick={() => onSignUpClick()}
          sx={{
            fontSize: '14px', color: 'var(--primary-color) !important', fontWeight: 600, ml: 1, cursor: 'pointer',
          }}
        >
          {t('Log In')}
        </Typography>
      </Container>
      <ConfirmAffiliateModal
        openAffiliateConfirmModal={openAffiliateConfirmModal}
        setOpenAffiliateConfirmModal={setOpenAffiliateConfirmModal}
        t={t}
        handleConfirmModalClose={handleConfirmModalClose}
        onConfirmationModalSubmit={onConfirmationModalSubmit}
      />
    </AuthLayout>
  );
}
