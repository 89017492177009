/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Form from '../../../../../components/Form';
import { formConfig } from './config';
import ConfirmAffiliateModal from '../../../components/confirmAffiliateModal';

export default function Step2({
  onSubmit, errors, loading, data, setErrors, signupParent,
}) {
  const navigate = useNavigate();
  const [formConfiguration, setformConfiguration] = useState(formConfig);
  const { t } = useTranslation();
  const getFormConfig = () => formConfiguration;

  const [openAffiliateConfirmModal, setOpenAffiliateConfirmModal] = useState(false);
  const [currentData, setCurrentData] = useState(data);

  // useEffect(() => {
  //  const [username, ...otherInp] = [...formConfiguration];
  //  setformConfiguration([{ ...username, subLabel: `( ${currentData?.username || 'Name'}.memegames.ai )` }, ...otherInp]);
  // }, [currentData]);

  useEffect(() => {
    if (currentData?.isAffiliate) {
      const [username, ...otherInp] = [...formConfiguration];
      setformConfiguration([{ ...username, subLabel: `( ${currentData?.username || 'Username'}.${process.env.REACT_APP_BASE_DOMAIN} )` }, ...otherInp]);
    } else {
      const [username, ...otherInp] = [...formConfiguration];
      setformConfiguration([{ ...username, subLabel: '' }, ...otherInp]);
    }
  }, [currentData?.isAffiliate]);

  useEffect(() => {
    if ((!currentData?.isAffiliate && !currentData?.isProduct) && currentData?.country) {
      toast.error('To continue, you must be either an Ambassador, a Gamer, or both!');
    }
  }, [currentData?.isProduct, currentData?.isAffiliate]);

  useEffect(() => {
    if (!signupParent) {
      navigate('/');
    }
  }, []);

  const handleConfirmModalClose = () => {
    setOpenAffiliateConfirmModal(false);
  };

  const onConfirmationModalSubmit = () => {
    handleConfirmModalClose();
  };

  useEffect(() => {
    if (currentData?.isAffiliate) {
      setOpenAffiliateConfirmModal(true);
    }
  }, [currentData?.isAffiliate]);

  return (
    <div className="signup-form" style={{ padding: '-10px' }}>
      <div className="admin-login">
        <div className="flex-row justify-center mb-12">
          <Typography sx={{
            display: 'flex', flexDirection: 'column', justifyItems: 'center', textAlign: 'center', color: 'var(--primary-color) !important', justifyContent: 'center', fontSize: '20px !important', mb: 2, fontWeight: 600,
          }}
          >
            JOIN MEMEGAMES.AI
            {signupParent && (
              <Box sx={{ mb: 4, mt: 1 }}>
                <p
                  style={{
                    color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '300',
                  }}
                >
                  {'Sponsor: '}

                </p>
                <p
                  style={{
                    color: 'white', textAlign: 'center', fontSize: '16px', fontWeight: '200',
                  }}
                >

                  {signupParent?.firstName && signupParent?.lastName ? `${signupParent?.firstName || ''} ${signupParent?.lastName || ''} ` : signupParent.username}

                </p>
                <p
                  style={{
                    color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
                  }}
                >
                  {signupParent.mobile || ''}
                </p>
                <p
                  style={{
                    color: 'white', textAlign: 'center', fontSize: '12px', fontWeight: '200',
                  }}
                >
                  {signupParent.email || ''}
                </p>
              </Box>
            )}
          </Typography>
        </div>

        {loading && (
          <div style={{ position: 'absolute', margin: '96px 165px', zIndex: '99' }}>
            <CircularProgress />
          </div>
        )}
        <Form
          size="md"
          config={getFormConfig(t)}
          submitLabel="Signup"
          onSubmit={onSubmit}
          defaultFormValue={data}
          errors={errors}
          formLoading={loading}
          setCurrentData={setCurrentData}
          buttonType="button"
          setErrors={setErrors}
        />
      </div>
      <ConfirmAffiliateModal
        openAffiliateConfirmModal={openAffiliateConfirmModal}
        setOpenAffiliateConfirmModal={setOpenAffiliateConfirmModal}
        t={t}
        handleConfirmModalClose={handleConfirmModalClose}
        onConfirmationModalSubmit={onConfirmationModalSubmit}
      />
    </div>
  );
}
