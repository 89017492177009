import clsx from 'clsx';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function TableMobile({ columns, data, className }) {
  return (
    <div className={clsx('table-cards', className)}>
      {data.length ? data.map((row, i) => (
        <div className="table-card" key={i}>
          {columns.map((col) => (
            <div className="table-card-row" key={col.field}>
              <label>{col.headerName}</label>
              <div className="value">
                {col.renderCell({ row, value: row[col.field] })}
              </div>
            </div>
          ))}
        </div>
      )) : (
        <div className="placeholder">
          <span>Nothing found</span>
        </div>
      )}
    </div>
  );
}
