import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { defaultFormValues, formValidationConfig } from './config';
import { validateAll } from '../../../components/Form/config';
// import useAppStore from '../../../utils/store/app/hook';
import { AppContext } from '../../../utils/store/app';

export default function usePassTypes() {
  const apiBaseUri = '/pass-types';
  const { authPut, authPost } = useApi();
  const {
    passTypes, updatePassTypes, createPassTypes, loadingPassTypes, 
  } = useContext(AppContext);
  const [errors, setErrors] = useState(null);
  const [selectedPassType, setSelectedPassType] = useState();
  const [message, setMessage] = useState();
  const [formLoading, setFormLoading] = useState(false);
  const { t } = useTranslation();

  const onPassTypeClick = (_data) => {
    setMessage();
    setErrors();
    if (_data) {
      setSelectedPassType(_data);
    } else {
      setSelectedPassType(defaultFormValues);
    }
  };

  const handleModalClose = () => {
    setSelectedPassType();
    setErrors(null);
    setMessage();
    setFormLoading(false);
  };

  const submit = async (data) => {
    setFormLoading(true);
    try {
      const checkform = validateAll(data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
        setFormLoading(false);
      } else if (data.id) {
        const _data = { ...data };
        _data.points = Number(data.points || 0);
        _data.price = Number(data.price || 0);
        const res = await authPut(`${apiBaseUri}/${data.id}`, { data: _data });
        if (res?.id) {
          handleModalClose();
          updatePassTypes(res);
          setFormLoading(false);
          toast.success(t('Pack Type Saved Successfully!'));
        }
      } else {
        const res = await authPost(apiBaseUri, { data });
        if (res?.id) {
          toast.success(t('Pack Type Saved Successfully!'));
          handleModalClose();
          createPassTypes(res);
          setFormLoading(false);
        }
      }
    } catch (err) {
      console.log('-----passTypes---', err);
      setErrors(err);
      setFormLoading(false);
      toast.error(t('Error! Something went wrong!'));
    }
  };

  return {
    submit,
    loadingPassTypes,
    errors,
    setErrors,
    passTypes,
    onPassTypeClick,
    selectedPassType,
    handleModalClose,
    message,
    formLoading,
  };
}
