import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { AuthenticationContext } from '../../../utils/store/authentication';
import { defaultUsageReportColumnsConfig } from '../config';
import { browserDownloadBlobData } from '../../../utils/file';

const PAGE_SIZE = 25;

const useAdminReportApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [usageData, setUsageData] = useState([]);
  const [usageDataPagination, setUsageDataPagination] = useState();
  const [loadedUsageData, setLoadedUsageData] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const { authGet } = useApi();

  const fetchUsageData = useCallback(
    async ({ page = 1 }) => {
      setLoadedUsageData(false);

      const response = await authGet('/reports/usage', {
        params: {
          page, perPage: PAGE_SIZE,
        },
      });

      setUsageData(response.data);

      setUsageDataPagination({
        totalUsers: response.total,
        total: Math.ceil(response.total / PAGE_SIZE),
        page,
      });

      setLoadedUsageData(true);
    },
    [authGet],
  );

  const downloadUsageReportCsv = useCallback(
    async () => {
      if (!downloadingCsv) {
        setDownloadingCsv(true);
        const responseData = await authGet('/reports/usage/csv');

        browserDownloadBlobData(responseData, 'usage_report.csv');

        setDownloadingCsv(false);
      }
    },
    [authGet, downloadingCsv],
  );

  useEffect(() => {
    fetchUsageData({});
  }, []);

  // Columns Configuration ===================================
  const usageDataColumnsConfiguration = useMemo(() => defaultUsageReportColumnsConfig(t), [t]);
  // Columns Configuration ===================================

  return {
    t,
    user,

    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,

    usageDataColumnsConfiguration,

    downloadingCsv,
    downloadUsageReportCsv,
  };
};

export default useAdminReportApi;
