import {
  Container, Divider as MUIDivider, Typography,
} from '@mui/material';

export default function Divider() {
  return (
    <Container
      sx={{
        display: 'flex',
        mb: 3,
        mt: 3,
        justifyContent: 'space-evenly',
      }}
    >
      <MUIDivider sx={{ flexBasis: '40%' }} style={{ marginBottom: '10px' }} />
      <Typography
        sx={{
          fontWeight: 700,
          color: 'white',
          p: 'auto',
          m: 'auto',
        }}
        className="my-auto py-auto"
      >
        OR
      </Typography>
      <MUIDivider sx={{ flexBasis: '40%' }} style={{ marginBottom: '10px' }} />
    </Container>
  );
}
