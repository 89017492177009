import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Context } from '../../../context';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import useApi from '../../../../../utils/api';
import ComponentLoader from '../../../../../components/ComponentLoader';
import { renderNumber } from '../../../../../utils/number';

export default function LockButton({ game, coins }) {
  const { authPost } = useApi();
  const [lockModalVisible, setLockModalVisible] = useState(false);
  const { chosenMemeCoin: chosenMemeCoins, refreshMemeCoins } = useContext(Context);
  const [loadingLock, setLoadingLock] = useState(false);
  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === game.id);

  const isTop10 = game?.statistics?.rankingPercentage <= 10;
  const _percentage = isTop10
    ? game?.statistics?.multiplier
    : game?.statistics?.grossMultiplier;

  const biggerThenMultiplier = game.maxMultiplier && _percentage > game.maxMultiplier;
  const percentage = biggerThenMultiplier ? game.maxMultiplier : _percentage;
  const waged = chosenMemeCoin?.mgCoins;
  const potentialPoints = percentage < 1 ? waged * 1 : waged * Math.ceil(percentage);

  const onLockCoin = async () => {
    try {
      setLoadingLock(true);
      await authPost('/schedule-game/lock-game', {
        data: {
          memeCoinsId: chosenMemeCoin.id,
          gameId: game.id,
        },
      });
      refreshMemeCoins();
      setLockModalVisible(false);
    } catch (err) {
      console.log(err);
    }
    setLoadingLock(false);
  };

  if (!chosenMemeCoin) {
    return null;
  }

  return !chosenMemeCoin?.lockPrice ? (
    <>
      <Button
        type="submit"
        className={clsx('mt-4')}
        onClick={() => {
          setLockModalVisible(true);
        }}
      >
        Lock In Current Score
        (
        {renderNumber(potentialPoints)}
        )
      </Button>
      <Modal
        title="Lock in Current Score"
        open={lockModalVisible}
        handleClose={() => setLockModalVisible(false)}
        width="500px"
      >
        {!loadingLock ? (
          <>
            <Typography style={{ textAlign: 'center' }}>
              Are you sure you want to lock in current score?
            </Typography>

            <div className="flex gap-2 mt-4">
              <Button bordered className="flex-1" onClick={() => setLockModalVisible(false)}>
                No
              </Button>

              <Button className="flex-1" onClick={onLockCoin}>
                Yes
              </Button>
            </div>
          </>
        ) : (
          <ComponentLoader />
        )}
      </Modal>
    </>
  ) : (
    <div className="locked-price-cont mt-5">
      <span>
        Score Locked:
        {' '}
        {renderNumber(potentialPoints)}
      </span>
    </div>
  );
}
