const config = {
  viewBox: '0 0 18 17',
  children: (
    <>
      <circle cx="9.09863" cy="8.729" r="7.77637" stroke="currentColor" />
      <path d="M8.49832 10.0247C8.49832 9.77806 8.53832 9.56139 8.61832 9.37473C8.69832 9.18806 8.80165 9.02139 8.92832 8.87473C9.05499 8.72806 9.18832 8.59139 9.32832 8.46473C9.47499 8.33806 9.61165 8.21473 9.73832 8.09473C9.86499 7.97473 9.96832 7.84806 10.0483 7.71473C10.1283 7.58139 10.1683 7.43139 10.1683 7.26473C10.1683 6.98473 10.055 6.75806 9.82832 6.58473C9.60165 6.41139 9.29832 6.32473 8.91832 6.32473C8.55165 6.32473 8.23165 6.39139 7.95832 6.52473C7.69165 6.65806 7.46832 6.84806 7.28832 7.09473L6.31832 6.46473C6.59832 6.08473 6.96499 5.78806 7.41832 5.57473C7.87165 5.35473 8.40832 5.24473 9.02832 5.24473C9.51499 5.24473 9.94165 5.31806 10.3083 5.46473C10.675 5.60473 10.9617 5.81139 11.1683 6.08473C11.375 6.35139 11.4783 6.67806 11.4783 7.06473C11.4783 7.33139 11.435 7.56473 11.3483 7.76473C11.2683 7.96473 11.1617 8.14139 11.0283 8.29473C10.9017 8.44139 10.765 8.58139 10.6183 8.71473C10.4717 8.84139 10.3317 8.96806 10.1983 9.09473C10.0717 9.22139 9.96832 9.35806 9.88832 9.50473C9.80832 9.65139 9.76832 9.82473 9.76832 10.0247H8.49832ZM9.13832 12.4147C8.90499 12.4147 8.71165 12.3381 8.55832 12.1847C8.41165 12.0314 8.33832 11.8481 8.33832 11.6347C8.33832 11.4214 8.41165 11.2414 8.55832 11.0947C8.71165 10.9414 8.90499 10.8647 9.13832 10.8647C9.37165 10.8647 9.56165 10.9414 9.70832 11.0947C9.85499 11.2414 9.92832 11.4214 9.92832 11.6347C9.92832 11.8481 9.85499 12.0314 9.70832 12.1847C9.56165 12.3381 9.37165 12.4147 9.13832 12.4147Z" fill="currentColor" />
    </>
  ),
};

export default config;
