import {
  CircularProgress, Container, Divider, Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';
import useApi, { useNonAuthApi } from '../../../utils/api';

export default function TokenExpired() {
  const { nonAuthPost } = useNonAuthApi();
  const signupUser = useMemo(() => getFromSession('signupUser'), []);
  const { t } = useTranslation();

  const resendEmail = async () => {
    try {
      // test
      const res = await nonAuthPost('/auth/resend-tokenn', { data: { email: signupUser?.Email } });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
 
  return (
    <AuthLayout>
      <Typography sx={{
        color: 'white', textAlign: 'center', mb: 3, fontSize: '18px', 
      }}
      >
        Token Expired!
      </Typography>
   
    </AuthLayout>
  );
}
