/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { defaultTheme } from '../../../constants/theme';

const configLoading = false;
/**
 * Hook where GeneralContext functionallity is created
 */
export default function useGeneralStore() {
  const [config] = useState({});
  const [theme] = useState(defaultTheme);

  return {
    theme,
    config,
    configLoading,
  };
}
