import FlagIcon from '../../components/FlagIcon';
import { renderNumber } from '../../utils/string';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const leaderboardYesterdayColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <>
        <FlagIcon countryCode={value.Country?.Code} />
        <span>{value.Username}</span>
      </>
    ),
  },
  {
    field: 'token',
    headerName: t('LAST 24 HOURS'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
  {
    field: 'multiplierResult',
    headerName: t('% Change'),
    minWidth: 50,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value.percentage) : '0'),
  },
  {
    field: 'mgCoins',
    headerName: t('COINS PLAYED'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];

export const leaderboardColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <>
        <FlagIcon countryCode={value.Country?.Code} />
        <span>{value.Username}</span>
      </>
    ),
  },
  {
    field: 'YesterdayVote',
    headerName: t('LAST 24 HOURS'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value.token) : '0'),
  },
  {
    field: 'tokenTotalHistorical',
    headerName: t('TOTAL MG TOKENS'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];

export const leaderboardFullColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <>
        <FlagIcon countryCode={value.Country?.Code} />
        <span>{value.Username}</span>
      </>
    ),
  },
  {
    field: 'VotesCount',
    headerName: t('PLAYED GAMES'),
    minWidth: 150,
    ...defaultColConfig,
  },
  {
    field: 'YesterdayVote',
    headerName: t('LAST 24 HOURS'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value.token) : '0'),
  },
  {
    field: 'tokenTotalHistorical',
    headerName: t('TOTAL MG TOKENS'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];
