import { useContext } from 'react';
import { Grid, Pagination, Typography } from '@mui/material';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import { MyContext, MyProvider } from './context';
import Modal from '../../../components/Modal';
import Form from '../../../components/Form';
import { formConfig } from './config';
import Button from '../../../components/Button';

function HistoryDescriptions() {
  const {
    data,
    loadingData,
    page,
    total,
    columns,
    totalPages,
    t, 
    editEl,
    changeDescription,
    onEditModalClose,
    onSubmit,
    getData,
    onAddClick,
    
  } = useContext(MyContext);
  return (
    <Layout maxWidth="100%">

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{
          fontSize: '20px', textTransform: 'uppercase', fontWeight: 'bold', display: 'flex', justifyContent: 'center', mb: 4, 
        }}
        >
          {
          t('Descriptions History')
        }
        </Typography>
        <Button onClick={() => onAddClick()}>
          ADD +
        </Button>
      </div>
    
      <Grid container spacing={1}>

        <Grid item xl={12} lg={12}>
          <Table
            columns={columns}
            rows={data}
            className="table"
            sortingOrder={['desc', 'asc']}
            sortModel={[]}
          />
        </Grid>
    
        <Grid item container xs={12} justifyContent="flex-end">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${total} logs`}
          </Typography>
      
          <Pagination
            count={totalPages}
            page={page}
            onChange={(val, _page) => {
              getData(_page);
            }}
          />
      
        </Grid>
      </Grid>
      <Modal wi title="Change Description" open={!!editEl} handleClose={onEditModalClose}>

        <Form
          // errors={errors}
          // setError={setError}
          defaultFormValue={editEl}
          config={formConfig}
          size="sm"
          className=""
          submitLabel="Submit"
          onSubmit={onSubmit}
          // formLoading={formLoading}
          buttonType="button"
        />

      </Modal>
    </Layout>
  );
}

export default () => (
  <MyProvider>
    <HistoryDescriptions />
  </MyProvider>
);
