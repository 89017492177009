import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useAuthenticationApi from '../../features/Authentication/api';

export default function AuthLayout({ children, style }) {
  const { t } = useTranslation();
  const { getParent } = useAuthenticationApi();
  const navigate = useNavigate();

  useEffect(() => {
    const host = window.location.href;
    const uri = new URL(host);
    const { searchParams } = uri;

    if (searchParams.size === 1) {
      const username = searchParams.get('sponsor');
      if (username) {
        const _fetch = async () => {
          try {
            const res = await getParent(username);
            sessionStorage.setItem('signupParent', JSON.stringify(res));
          } catch (err) {
            if (err === 'Not Found') {
              navigate('/dashboard/registration/new');
            } else {
              console.log('err--', err);
              toast.error(err);
            }
          }
        };

        _fetch();
        sessionStorage.setItem('signupParent', JSON.stringify({ username }));
      }
    }
  }, []);

  return (
    <div className="layout-container">
      <div className="flex-1 flex-column pt-15 pb-15">
        <div className="layout-header flex-row justify-center" height={70}>
          <img alt="logo-white" src="/assets/MemeGames-logo-fullwhite.png" width="56px" height={70} />
        </div>

        <Container className="flex-1 mt-12 mb-12 layout-card" style={style} maxWidth="sm">
          {children}
        </Container>

        <div className="mt-0 layout-footer">
          <p>{t('© 2024  MemeGames.ai .')}</p>
          <p>{t('All rights reserved.')}</p>
        </div>
      </div>
    </div>
  );
}
