import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import clsx from 'clsx';

export default function CardChip({
  children, isGrowing, className, noIcon = false,
}) {
  return (
    <div className={clsx('card-chip', className, isGrowing ? 'growing' : 'not-growing')}>
      <div className="flex flex-column mr-1">
        <span>
          {children}
        </span>
      </div>
      {noIcon ? null : (
        <div className="flex flex-column">
          {isGrowing ? (
            <div className="chip-bubble">
              <div className="flex justify-center">
                <ArrowUpwardIcon style={{ width: 10, height: 10, color: 'black' }} />
              </div>
            </div>
          ) : (
            <div className="chip-bubble not-growing-bubble">
              <div className="flex justify-center">
                <ArrowDownwardIcon style={{ width: 10, height: 10, color: 'black' }} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
