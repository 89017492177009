import { useEffect, useState, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../utils/api';
import AuthLayout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';

export default function PaymentStatus() {
  const { authGet, authPost } = useApi();
  const [status, setStatus] = useState();
  const signupUser = useMemo(() => getFromSession('signupUser'), []);
  const navigate = useNavigate();
  const [checkAgain, setCheckAgain] = useState(1);

  const getPaymentStatus = async () => {
    try {
      const res = await authPost('/stripe/payment-status', { data: { user: signupUser } });
      console.log(res);
      setStatus(res.status);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(checkAgain);
    if (checkAgain > 0) {
      getPaymentStatus();
    }
  }, [checkAgain]);

  useEffect(() => {
    if (status === 'succeeded') {
      setTimeout(() => {
        // navigate('/thank-you');
      }, 5000);
    }

    if (status === 'failed') {
      setTimeout(() => {
        // navigate('/');
      }, 5000);
    }

    if (status === 'pending') {
      setTimeout(() => {
        setCheckAgain((prev) => prev + 1);
      }, 3000);
    }
  }, [status, checkAgain]);

  return (
    <AuthLayout>
      {
        status === 'succeeded' && (
          <>
            <Typography color="primary" sx={{ textAlign: 'center', mb: 4 }}>
              Payment Succeeded!
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'center' }}>
              Redirecting
              {' '}
              <ClipLoader color="white" size={12} />
            </Typography>
          </>

        )
      }

      {
        status === 'failed' && (
          <>

            <Typography color="primary" sx={{ textAlign: 'center', mb: 4 }}>
              Something went wrong. Please try again later.
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'center' }}>
              Redirecting to homepage
              {' '}
              <ClipLoader color="white" size={12} />
            </Typography>
          </>
        )
      }

      {
        status === 'pending' && (
          <Typography sx={{ color: 'white', textAlign: 'center' }}>
            Please wait
            {' '}
            <ClipLoader color="white" size={12} />
          </Typography>
        )
      }

    </AuthLayout>
  );
}
