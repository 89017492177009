const config = {
  viewBox: '0 0 17 17',
  children: (
    <>
      <circle cx="8.7207" cy="5.38281" r="4" stroke="#D29F67" />
      <path d="M16.2207 16.8828V16.8828C16.2207 14.1214 13.9821 11.8828 11.2207 11.8828H6.2207C3.45928 11.8828 1.2207 14.1214 1.2207 16.8828V16.8828" stroke="#D29F67" />
    </>
  ),
};

export default config;
