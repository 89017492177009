const config = {
  viewBox: '0 0 29 26',
  children: (
    <>
      <path d="M14.9282 1.68799L17.3072 3.99161L20.5193 3.18611L21.4277 6.3706L24.6122 7.27905L23.8067 10.4911L26.1104 12.8701L23.8067 15.2491L24.6122 18.4612L21.4277 19.3696L20.5193 22.5541L17.3072 21.7486L14.9282 24.0522L12.5492 21.7486L9.33716 22.5541L8.4287 19.3696L5.24422 18.4612L6.04971 15.2491L3.74609 12.8701L6.04971 10.4911L5.24422 7.27905L8.4287 6.3706L9.33716 3.18611L12.5492 3.99161L14.9282 1.68799Z" stroke="#CE965F" strokeWidth="1.5" />
      <path d="M14.9282 1.68799L17.3072 3.99161L20.5193 3.18611L21.4277 6.3706L24.6122 7.27905L23.8067 10.4911L26.1104 12.8701L23.8067 15.2491L24.6122 18.4612L21.4277 19.3696L20.5193 22.5541L17.3072 21.7486L14.9282 24.0522L12.5492 21.7486L9.33716 22.5541L8.4287 19.3696L5.24422 18.4612L6.04971 15.2491L3.74609 12.8701L6.04971 10.4911L5.24422 7.27905L8.4287 6.3706L9.33716 3.18611L12.5492 3.99161L14.9282 1.68799Z" stroke="url(#paint0_linear_1459_3987)" strokeOpacity="0.3" strokeWidth="1.5" style={{ mixBlendMode: 'overlay' }} />
      <path d="M10.9901 12.834L13.3294 15.2967C13.7548 15.7445 14.4803 15.7035 14.8525 15.2107L18.8643 9.89893" stroke="#CE965F" strokeWidth="1.5" />
      <path d="M10.9901 12.834L13.3294 15.2967C13.7548 15.7445 14.4803 15.7035 14.8525 15.2107L18.8643 9.89893" stroke="url(#paint1_linear_1459_3987)" strokeOpacity="0.3" strokeWidth="1.5" style={{ mixBlendMode: 'overlay' }} />
      <defs>
        <linearGradient id="paint0_linear_1459_3987" x1="3.74609" y1="24.0522" x2="26.1104" y2="1.68799" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint1_linear_1459_3987" x1="10.9902" y1="16.1518" x2="17.0805" y2="8.48243" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default config;
