import { useCallback, useEffect, useState } from 'react';
import {
  getToken, getUser, removeToken, removeUser, storeToken, storeUser, 
} from '../../storage';
import useAuthenticationApi from '../../../features/Authentication/api';

/**
 * Hook where AuthenticationContext functionallity is created
 * @returns {Object}  AuthenticationContext variables
 */
export default function useAuthenticaitonStore() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState('');
  const { checkToken } = useAuthenticationApi();
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticationLoading, setAuthenticationLoading] = useState(true);
  const [userImage, setUserImage] = useState('');
  
  /**
   * Function used to update user
   */
  const updateUser = useCallback(async (data = {}) => {
    await storeUser(JSON.stringify(data));
    const tmp = await getUser();
    setUser(JSON.parse(tmp));
  }, []);

  /**
   * Function used to update user
   */
  const updateUserImage = useCallback((imageUrl = '') => {
    const lUser = localStorage.getItem('user');
    const userObj = JSON.parse(lUser);
    userObj.image = imageUrl;
    setUserImage(imageUrl);
    localStorage.setItem('user', JSON.stringify(userObj));
  }, []);
  
  /**
   * Function used to save new user
   */
  const authenticateUser = useCallback(async (_user = {}, _token = '') => {
    await storeToken(_token);
    await storeUser(JSON.stringify(_user));
    setToken(await getToken());
    const tmp = await getUser();
    setUser(JSON.parse(tmp));
    setAuthenticated(true);
    setUserImage(_user?.image);
    return true;
  }, []);

  /**
   * Function used to remove user and token
   */
  const signOut = useCallback(async () => {
    await removeToken();
    await removeUser();
    setUser(null);
    setToken('');
    setAuthenticated(false);
    sessionStorage.clear();
  }, []);

  /**
   * Function used to check user'
   */
  const checkUser = useCallback(async () => {
    const storedToken = await getToken();

    if (storedToken) {
      try {
        const res = await checkToken(storedToken);

        if (res) {
          await authenticateUser(res.user, storedToken);
        }
      } catch (err) {
        signOut();
      }
    } else {
      setAuthenticated(false);
    }

    setAuthenticationLoading(false);
  }, [authenticateUser, checkToken]);

  useEffect(() => {
    checkUser();
  }, []);

  return {
    user,
    setUser,
    token,
    setToken,
    signOut,
    checkUser,
    updateUser,
    authenticated,
    authenticateUser,
    authenticationLoading,
    updateUserImage,
    userImage,
    setUserImage,
  };
}
