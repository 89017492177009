// in case useParams doesnt work

const Url = {
  get get() {
    const vars = {};
    if (window.location.search.length !== 0) {
      window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        key = decodeURIComponent(key);
        if (typeof vars[key] === 'undefined') { vars[key] = decodeURIComponent(value); } else { vars[key] = [].concat(vars[key], decodeURIComponent(value)); }
      }); 
    }
    return vars;
  },
};

export default Url;
