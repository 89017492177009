function validatePassword(password) {
  // Regular expressions for validation
  const minLengthRegex = /.{8,}/; // At least 8 characters
  const capitalLetterRegex = /[A-Z]/; // At least 1 capital letter
  const numberRegex = /\d/; // At least 1 number
  const specialCharacterRegex = /[!@#$%^&*()-+_=\\[\]{};':"\\|,.<>\\/?]/; // At least 1 special character
    
  // Check if the password meets all criteria
  const isMinLengthValid = minLengthRegex.test(password);
  const hasCapitalLetter = capitalLetterRegex.test(password);
  const hasNumber = numberRegex.test(password);
  const hasSpecialCharacter = specialCharacterRegex.test(password);
    
  // Return true if all criteria are met, false otherwise
  console.log({
    isMinLengthValid, hasCapitalLetter, hasNumber, hasSpecialCharacter, 
  });
  return true;
  // return isMinLengthValid && hasCapitalLetter && hasNumber && hasSpecialCharacter && _true;
}
  
export default validatePassword;
