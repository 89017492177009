import {
  useState, useEffect, useMemo, useCallback, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import { Modal } from '@mui/material';
import {
  gamercolumnsConfig, ambassadorcolumnsConfig, modalTypes,
  transferscolumnsConfig,
} from '../config';
import useApi from '../../../utils/api';
import { useAuthentication } from '../../../utils/store';

const PAGE_SIZE = 10;

const useWallets = ({ isGamer }) => {
  const { t } = useTranslation();
  const [loadingHistory, setloadingHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [historyPage, setHistoryPage] = useState(0);
  const [modalData, setModalData] = useState();
  const [defaultWallet, setDefaultWallet] = useState(0);
  const [coopWallet, setCoopWallet] = useState(0);
  const [solanaWallet, setSolanaWallet] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [errors, setErrors] = useState();
  const [commission, setCommission] = useState(10);
  const {
    authGet, authPost, authPut, authDelete,
  } = useApi();
  const [btcWalletNumber, setBtcWalletNumber] = useState('');
  const { user, updateUser } = useAuthentication();
  const [transferLoading, setTransferLoading] = useState(false);
  const [transferTabsValue, setTransferTabsValue] = useState(0);
  const [tokens, setTokens] = useState(0);

  // #region history
  const fetchHistory = async (page = 1) => {
    setloadingHistory(true);
    try {
      const res = await authGet(isGamer ? '/gamer-wallet/history' : '/wallets/history', {
        params: {
          page,
          perPage: PAGE_SIZE,
          action: ['played', 'transaction', 'redeem', 'expired'],
        },
      });
      setHistoryPage(page);
      setHistoryTotal(res.total);
      setHistoryData(res?.data?.map((i) => ({
        ...i, value: i?.token || i?.mgCoins || i?.balance || 0,
      })));
      setloadingHistory(false);
    } catch (err) {
      console.log(err);
      setloadingHistory(false);
    }
  };

  const columns = useMemo(() => {
    if (isGamer) return gamercolumnsConfig(t);
    return ambassadorcolumnsConfig(t);
  }, [t]);

  const totalPages = useMemo(() => Math.ceil(historyTotal / PAGE_SIZE), [historyTotal]);

  // #endregion

  // #region wallet
  const formatWalletData = (_data) => {
    // eslint-disable-next-line no-confusing-arrow
    const _default = _data?.find((i) => isGamer ? i.type === 'mg-coin' : i.type === 'default');
    // const _investment = _data?.find((i) => i.type === 'investment');
    const _solana = _data?.find((i) => i.type === 'solana');

    setDefaultWallet(_default || {});
    // setCoopWallet(_investment || {});
    setSolanaWallet(_solana || null);
  };

  const fetchWalletData = async () => {
    try {
      /// get whatever wallet data is needed
      if (isGamer) {
        const _data = await authGet('/wallets');
        const gamerWallet = await authGet('/gamer-wallet');
        console.log('isGamer', isGamer, _data);
        
        const _solana = _data?.find((i) => i.type === 'solana');
        formatWalletData([gamerWallet, _solana]);
      } else {
        const _data = await authGet('/wallets');
        formatWalletData(_data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onModalClose = () => {
    setModalData();
  };

  const onWithdrawSubmit = useCallback(
    async ({ withdrawType, withdrawAmount, gamePass }) => {
      switch (withdrawType) {
        case 'solana':
          // Create a new record to the manual payout
          if (!solanaWallet) {
            toast.error('You need to connect your Solana Wallet');
            return;
          }
          try {
            await authPost('/withdraw/solana', { data: { withdrawAmount } });
            
            fetchHistory();
            fetchWalletData();

            toast.success('Your redemption has been successfully completed.');
          } catch (err) {
            console.log(err);
            toast.error('Error during redemption process');
          }
          break;

        case 'passes':
          // Buy passes with this withdrawAmount to the selected PassType
          if (gamePass.price > withdrawAmount) {
            toast.error('Insufficient balance for this transaction.');
            return;
          }
          try {
            // Calculate the total passes for this withdraw amount
            const totalPasses = Math.floor(withdrawAmount / gamePass.price);

            await authPost('/withdraw/passes', { data: { passTypeId: gamePass.id, totalPasses } });
            
            fetchHistory();
            fetchWalletData();

            toast.success('Your redemption has been successfully completed.');
          } catch (err) {
            console.log(err);
            toast.error('Error during redeem process');
          }
          break;

        default:
          toast.error('Error with the selected Redeem Type');
          break;
      }
    },
    [authPost, solanaWallet],
  );

  // const onWithdrawSubmit = (_data) => {
  //   const _checkValidation = validateAll(_data, withdrawFormValidationConfig);
  //   if (_checkValidation) {
  //     const tmp = _checkValidation;
  //     if (_data.amount === 0) {
  //       tmp.amount = 'Amount must not be 0!';
  //     }
  //     setErrors(tmp);
  //   } else if (_data.amount === 0) {
  //     setErrors({ amount: 'Amount must not be 0!' });
  //   } else {
  //     setModalData({
  //       ...modalData,
  // data: _data,
  // type: '',
  // confirm: true,
  // confirmTitle: t('Confirm Withdraw'),
  // confirmDescription: t(`Are you sure you want to withdraw ${_data.amount} BTC to your wallet?`),
  //     });
  //   }
  // };

  const onCommitmentSave = (_data) => {
    setCommission(_data);
    onModalClose();
  };

  // #endregion

  // #region btc wallet
  // #endregion

  useEffect(() => {
    setBtcWalletNumber(user.bitcoinAddress);
  }, [user]);
  const [btcwalletModal, setBtcwalletModal] = useState(false);

  const onBtcWalletClick = () => {
    setBtcwalletModal(true);
  };

  const onBtcWalletExit = () => {
    setBtcwalletModal(false);
  };

  const onChangeBtcWalletNumber = async (_data) => {
    try {
      const res = await authPut('/wallets/bitcoinAddress ', { data: { bitcoinAddress: _data } });
      toast.success('BTC Wallet Changed Successfully!');
      setBtcwalletModal(false);
      setBtcWalletNumber(res.bitcoinAddress);
      updateUser(res);
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  // #endregion

  // #region transfers history
  const [historyTransfersTotal, setHistoryTransfersTotal] = useState(0);
  const [transfersData, setTransfersData] = useState([]);
  const [transferesPage, setTransferesPage] = useState(0);
  const [loadingTransfersTable, setLoadingTransfersTable] = useState(false);

  const handleTabTransfersChange = (_, newValue) => {
    setTransferTabsValue(newValue);
  };

  const getTransfersHistory = async (page) => {
    try {
      const res = await authGet(
        '/gamer-wallet/history',
        {
          params: {
            page,
            perPage: PAGE_SIZE,
            action: ['receive', 'transfer'],
          },
        },
      );
      setTransferesPage(page);
      setHistoryTransfersTotal(res.total);
      setTransfersData(res.data);
      setLoadingTransfersTable(false);
    } catch (err) {
      console.log(err);
    }
  };

  const columnsTransfers = useMemo(() => transferscolumnsConfig(t), [t]);

  const totalTransfersPages = useMemo(
    () => Math.ceil(historyTransfersTotal / PAGE_SIZE),
    [historyTransfersTotal],
  );

  // #endregion

  // #region transfer tokens

  const onTransferClick = () => {
    setModalData({ type: modalTypes.TRANSFER, description: 'Transfer Tokens' });
  };

  const onTransferSubmit = (_data) => {
    setModalData({
      ...modalData, value: _data, type: '', confirm: true, confirmTitle: t('Confirm Transfer'), confirmDescription: t(`Are you sure you want to transfer ${_data?.tokens} tokens to ${_data?.userId.value.Username}?`),
    });
  };

  const onActionConfirm = async () => {
    try {
      setTransferLoading(true);
      // whatever btc api integration or smth goes here....
      await authPost('/gamer-wallet/transferToken', { data: { token: modalData.value.tokens, toUserId: modalData.value.userId.value.SID } });
      toast.success(t('Transfer Successfull!'));
      await fetchWalletData();

      onModalClose();
      setModalData();
      setTransferLoading(false);
      fetchHistory();
      getTransfersHistory();
    } catch (err) {
      toast.error(err);
      console.log(err);
      onModalClose();
      setTransferLoading(false);
    }
  };

  // #endregion

  useEffect(() => {
    fetchHistory();
    fetchWalletData();
    if (isGamer) {
      getTransfersHistory();
    }
  }, []);

  const onRedeemClick = () => {
    setModalData({ type: modalTypes.WITHDRAW });
  };

  // #region solana wallet
  const onSaveSolanaWallet = async (publicKey) => {
    try {
      const result = await authPost('/wallets/solana', { data: { publicKey } });
      setSolanaWallet(result);
    } catch (error) {
      toast.error('Invalid Solana Wallet, you must connect a valid Wallet');
      console.log(error);
    }
  };

  const onDeleteSolanaWallet = async (walletId) => {
    try {
      return await authDelete('/wallets/solana', { data: { walletId } });
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  // #endregion

  const getGamerTokens = async () => {
    const res = await authGet('/gamer-wallet');
    setTokens(res?.token || 0);
  };

  useEffect(() => {
    getGamerTokens();
  }, []);

  return {
    t,
    loadingHistory,
    columns,
    historyData,
    totalPages,
    historyPage,
    fetchHistory,
    modalData,
    onTransferSubmit,
    onModalClose,
    defaultWallet,
    setDefaultWallet,
    coopWallet,
    setCoopWallet,
    onWithdrawSubmit,
    setModalData,
    confirmationModal,
    setConfirmationModal,
    onActionConfirm,
    loadingModal,
    setLoadingModal,
    errors,
    setErrors,
    commission,
    setCommission,
    onCommitmentSave,

    onBtcWalletExit,
    onBtcWalletClick,
    btcwalletModal,
    btcWalletNumber,
    onChangeBtcWalletNumber,
    user,

    onTransferClick,
    transferLoading,
    handleTabTransfersChange,
    transferTabsValue,
    columnsTransfers,
    totalTransfersPages,
    transfersData,
    transferesPage,
    loadingTransfersTable,
    getTransfersHistory,
    onRedeemClick,

    onSaveSolanaWallet,
    onDeleteSolanaWallet,
    solanaWallet,
    setSolanaWallet,
    tokens,
  };
};

export default useWallets;
