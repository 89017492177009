const config = {
  viewBox: '0 0 19 22',
  children: (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1255_1116" fill="white">
        <path d="M14.2232 5.49029L10.419 16.5067H4.77393L2.87931 22H13.2982L19 5.49029H14.2232Z" />
        <path d="M14.2232 5.49029L16.1207 0H5.70176L0 16.5067L4.77393 16.5067L8.57801 5.49026L14.2232 5.49029Z" />
      </mask>
      <path d="M10.419 16.5067V17.5067H11.1316L11.3642 16.8331L10.419 16.5067ZM2.87931 22L1.93396 21.674L1.47661 23H2.87931V22ZM13.2982 22V23H14.0108L14.2435 22.3264L13.2982 22ZM19 5.49029L19.9452 5.81673L20.4033 4.49029H19V5.49029ZM16.1207 0L17.0658 0.326655L17.5243 -1H16.1207V0ZM5.70176 0V-1H4.98921L4.75656 -0.326492L5.70176 0ZM0 16.5067L-0.9452 16.1802L-1.4034 17.5067L-6.39255e-06 17.5067L0 16.5067ZM8.57801 5.49026L8.57802 4.49026L7.86538 4.49026L7.63278 5.16387L8.57801 5.49026ZM13.2779 5.16389L9.47377 16.1803L11.3642 16.8331L15.1684 5.8167L13.2779 5.16389ZM10.419 15.5067H4.77393V17.5067H10.419V15.5067ZM3.82857 16.1807L1.93396 21.674L3.82467 22.326L5.71928 16.8328L3.82857 16.1807ZM2.87931 23H13.2982V21H2.87931V23ZM14.2435 22.3264L19.9452 5.81673L18.0548 5.16386L12.353 21.6736L14.2435 22.3264ZM19 4.49029H14.2232V6.49029H19V4.49029ZM15.1683 5.81695L17.0658 0.326655L15.1755 -0.326655L13.278 5.16364L15.1683 5.81695ZM16.1207 -1H5.70176V1H16.1207V-1ZM4.75656 -0.326492L-0.9452 16.1802L0.9452 16.8332L6.64696 0.326492L4.75656 -0.326492ZM-6.39255e-06 17.5067L4.77392 17.5067L4.77393 15.5067L6.39255e-06 15.5067L-6.39255e-06 17.5067ZM5.71916 16.8331L9.52325 5.81666L7.63278 5.16387L3.82869 16.1803L5.71916 16.8331ZM8.57801 6.49026L14.2232 6.49029L14.2232 4.49029L8.57802 4.49026L8.57801 6.49026Z" fill="url(#paint0_linear_1255_1116)" mask="url(#path-1-inside-1_1255_1116)" />
      <defs>
        <linearGradient id="paint0_linear_1255_1116" x1="10.9411" y1="1.00041" x2="-1.39098" y2="22.8038" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CCAD70" />
          <stop offset="1" stopColor="#CC7333" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

export default config;
