/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import {
  useCallback, useState, useEffect, useContext, useMemo, 
} from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../../../utils/api';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import { AppContext } from '../../../../utils/store/app';
import columnsConfig from '../config';

const PAGE_SIZE = 25;

export default function useGenealogy() {
  const { authGet } = useApi();
  const [usersA, setUsersA] = useState([]);
  const [usersG, setUsersG] = useState([]);

  const [genealogyData, setGenealogyData] = useState();
  const { user } = useContext(AuthenticationContext);
  const { passTypes } = useContext(AppContext);
  const { t } = useTranslation();
  const [usersATotal, setusersATotal] = useState(0);
  const [loadingAUsers, setLoadingAUsers] = useState(false);
  const [paginationAData, setPaginationAData] = useState();
  const [usersAPage, setUsersAPage] = useState(0);
  const [filtersA, setFiltersA] = useState({ sortBy: 'default' });
  const [sortModelA, setSortModelA] = useState([
    {
      field: 'displayName',
      sort: 'desc',
    },
  ]);

  const [usersGTotal, setusersGTotal] = useState(0);
  const [loadingGUsers, setLoadingGUsers] = useState(false);
  const [paginationGData, setPaginationGData] = useState();
  const [usersGPage, setUsersGPage] = useState(0);
  const [filtersG, setFiltersG] = useState({ sortBy: 'default' });
  const [sortModelG, setSortModelG] = useState([
    {
      field: 'displayName',
      sort: 'desc',

    },
  ]);
  const [searchText, setSearchText] = useState('');

  const [listTabsValue, setListTabsValue] = useState(0);

  const handleListTabChange = (_, newValue) => {
    setListTabsValue(newValue);
  };
  
  const fetchUsersAmbassadors = useCallback(
    async (page = 1) => {
      setLoadingAUsers(true);
 
      try {
        // orderBy[name]=1
        const _sort = `orderBy[${sortModelA ? sortModelA[0]?.field : 'Name'}]`;
        const res = await authGet('/genealogy/users', {
          params: {
            page, perPage: PAGE_SIZE, search: searchText, [_sort]: sortModelA ? sortModelA[0]?.sort === 'desc' ? '-1' : '1' : '-1', isAffiliate: true, isProduct: true,
          },
        });
        const tree = res.data;
        setUsersA(tree);
        setGenealogyData(res);
        setusersATotal(res?.total || 0);
        setLoadingAUsers(false);
        setUsersAPage(page);
      } catch (err) {
        console.log(err);
        setLoadingAUsers(false);
      }
    },
    [user, sortModelA, searchText],
  );

  const fetchUsersGamers = useCallback(
    async (page = 1) => {
      setLoadingGUsers(true);
 
      try {
        // orderBy[name]=1
        const _sort = `orderBy[${sortModelG ? sortModelG[0]?.field : 'Name'}]`;
        const res = await authGet('/genealogy/users', {
          params: {
            page, perPage: PAGE_SIZE, search: searchText, [_sort]: sortModelG ? sortModelG[0]?.sort === 'desc' ? '-1' : '1' : '-1', isProduct: true, isAffiliate: false,
          },
        });
        const tree = res.data;
        setUsersG(tree);
        setGenealogyData(res);
        setusersGTotal(res?.total || 0);
        setLoadingGUsers(false);
        setUsersGPage(page);
      } catch (err) {
        console.log(err);
        setLoadingGUsers(false);
      }
    },
    [user, sortModelG, searchText],
  );

  const searchClick = useCallback(() => {
    fetchUsersAmbassadors();
    fetchUsersGamers();
  }, [searchText, paginationAData, paginationGData]);

  useEffect(() => {
    fetchUsersGamers();
    fetchUsersAmbassadors();
  }, [user, sortModelA, sortModelG]);

  const [openModal, setOpenModal] = useState();
  const onUserClick = (_data) => {
    setOpenModal(_data);
  };

  const onModalClose = () => {
    setOpenModal();
  };

  const columns = useMemo(() => columnsConfig(t, user, onUserClick, listTabsValue), [t, user, onUserClick, listTabsValue]);
  const totalAPages = useMemo(() => Math.ceil(usersATotal / PAGE_SIZE) || 0, [usersATotal]);
  const totalGPages = useMemo(() => Math.ceil(usersGTotal / PAGE_SIZE) || 0, [usersGTotal]);

  const handleFilterChange = (e, type) => {
    const tmp = { ...filtersA };
    tmp[type] = e;
    setFiltersA(tmp);
    setFiltersG(tmp);
  };

  return {
    passTypes,
    usersA,
    usersG,
    genealogyData,
    fetchUsersAmbassadors,
    fetchUsersGamers,
    columns,
    totalAPages,
    usersATotal,
    totalGPages,
    usersGTotal,
    t,
    loadingGUsers,
    usersAPage,
    usersGPage,

    handleFilterChange,
    filtersA,
    filtersG,
    sortModelA,
    sortModelG,
    setSortModelA,
    setSortModelG,
    searchClick,
    searchText,
    setSearchText,
    setPaginationAData,
    setPaginationGData,
    paginationAData,
    paginationGData,
    openModal,
    onModalClose,
    listTabsValue,
    handleListTabChange,
    loadingAUsers,
    
  };
}
