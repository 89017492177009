import { useCallback } from 'react';
import useApi from '../../../utils/api';

const useProfileApi = () => {
  const { authPost } = useApi();

  const validateSolanaPaymentMembership = useCallback((puid) => authPost(`/payments/membership/solana/${puid}/validate`), [authPost]);

  return {
    validateSolanaPaymentMembership,
  };
};

export default useProfileApi;
