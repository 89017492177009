export const getFromSession = (key, type = 'object') => {
  if (sessionStorage.getItem(key)) {
    try {
      if (type === 'object') {
        return JSON.parse(sessionStorage.getItem(key));
      }
      return sessionStorage.getItem(key);
    } catch (err) {
      console.log(err);
    }
  }
  return null;
};

export default null;
