/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';

export default function UserNode({ user, currentUser }) {
  return (
    <div style={{ position: 'relative' }}>
      <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>{user.username}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>{user.displayName}</Typography>
      {currentUser?.SID === user.enrolledParentId && (
        <Typography
          variant="span"
          sx={{
            width: '5px',
            height: '5px',
            position: 'absolute',
            right: '-15px',
            top: '0',
            backgroundColor: 'var(--primary-color)',
            borderRadius: '50%',
          }}
        />
      )}

      {/* <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        ID:
        {user.userId}
      </Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        Sponsor:
        {user.enrolledParentId}
      </Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        ParentID:
        {user.parentId}
      </Typography> */}
    </div>
  );
}
