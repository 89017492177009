const config = {
  viewBox: '0 0 21 17',
  children: (
    <>
      <path d="M3.86694 10.3818L8.26589 5.4772L12.4362 9.89061L19.4685 2.49391" stroke="black" strokeWidth="1.17924" strokeLinecap="round" />
      <path d="M3.86694 10.3818L8.26589 5.4772L12.4362 9.89061L19.4685 2.49391" stroke="black" strokeWidth="1.17924" strokeLinecap="round" />
      <path d="M3.86694 10.3818L8.26589 5.4772L12.4362 9.89061L19.4685 2.49391" stroke="url(#paint0_linear_1736_4811)" strokeOpacity="0.3" strokeWidth="1.17924" strokeLinecap="round" style={{ mixBlendMode: 'overlay' }} />
      <path d="M1 1.25V15.5093" stroke="black" strokeWidth="1.17924" strokeLinecap="round" />
      <path d="M14.7188 15.5093L1.00012 15.5093" stroke="black" strokeWidth="1.17924" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_1736_4811" x1="3.74094" y1="13.4679" x2="17.0087" y2="-2.29937" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default config;
