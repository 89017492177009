import { Grid } from '@mui/material';
import PassTypeCard from './components/card';
import usePassTypes from './hook';
import PackageModal from './components/modal';
import Button from '../../../components/Button';
import AppLoader from '../../../components/AppLoader';

export default function PassTypes() {
  const {
    submit,
    errors,
    loadingPassTypes,
    passTypes,
    onPassTypeClick,
    selectedPassType,
    handleModalClose,
    message,
    formLoading,
  } = usePassTypes();

  if (loadingPassTypes) {
    return <AppLoader />;
  }

  return (
    <>
      <Grid container columnSpacing={3} sx={{ width: '100%', paddingLeft: { lg: 4 } }}>
        <Grid
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}
          item
        >
          <Button
            size="sm"
            onClick={() => {
              onPassTypeClick();
            }}
          >
            Add +
          </Button>
        </Grid>
        {passTypes?.map((item) => (
          <Grid key={`passType${item.id}`} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <PassTypeCard onPassTypeClick={onPassTypeClick} data={item} />
          </Grid>
        ))}
      </Grid>
      <PackageModal
        errors={errors}
        submit={submit}
        data={selectedPassType}
        handleModalClose={handleModalClose}
        message={message}
        formLoading={formLoading}
      />
    </>
  );
}
