import dayjs from 'dayjs';
import { Grid, Tooltip, Typography } from '@mui/material';
import Icon from '../../../components/Icon';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  sortable: true,
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t, user, onUserClick, listTabsValue) => [
  {
    field: 'displayName',
    headerName: t('NAME'),
    minWidth: 230,
    renderCell: ({ row }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography sx={{ cursor: 'pointer' }} onClick={() => onUserClick(row)}>
            {row.displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ color: '#919191' }}>
            {row.username}
            {' '}
          </Typography>
        </Grid>
      </Grid>
    ), // return `${value}`;

    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    sortable: true,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor first-column' : 'first-column'),

  },
  {
    field: 'email',
    headerName: t('Email'),
    minWidth: 290,
    ...defaultConfig,
    renderCell: ({ value }) => value,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  {
    field: 'mobile',
    headerName: t('Mobile'),
    minWidth: 150,
    ...defaultConfig,
    renderCell: ({ value }) => value,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  {
    field: 'passName',
    headerName: t('Coin Pack'),
    minWidth: 260,
    ...defaultConfig,
    renderCell: ({ value }) => value,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  {
    field: 'dateLastRenewed',
    headerName: t('Activation Date'),
    minWidth: 150,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  {
    field: 'expirationDate',
    headerName: t('Expiration Date'),
    minWidth: 150,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  (
    listTabsValue === 0 ? {
      field: 'rank',
      minWidth: 130,
      headerName: `${t('RANK')}`,
      cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),
      renderCell: ({ value }) => value?.name || '',

    } : ''
  ),
  {
    field: 'icons',
    flex: 1,
    headerName: '',
    align: 'right',
    columnAlign: 'right',
    type: 'actions',
    headerAlign: 'right',
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),
    renderCell: (_data) => (
      <div className="flex justify-between items-center ">
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.training ? (
              <Tooltip title={t('Training: A User that is one of the first 2 users brought in by their sponsor')}>
                <div>
                  <Icon height={20} width={20} name="UserConfig" />
                </div>
              </Tooltip>
            ) : ''
          }
        </div>
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.status === 'inactive' ? (
              <Tooltip id="tooltup" title={t('Inactive: A Gamer that has not paid their monthly or yearly gamer Pack fee')}>
                <div>
                  <Icon style={{ marginBottom: '3px' }} height={17} width={17} name="UserX" />
                </div>
              </Tooltip>
            ) : ''
          }
        </div>
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.qualified ? '' : (
              <Tooltip title={t('Inactive: A User that has not yet signed 3 Active Qualified Ambassadors')}>
                <div>
                  <Icon height={25} width={25} name="UserLupa" />
                </div>
              </Tooltip>
            )
          }
        </div>
      </div>
    ),
  },
];

export default columnsConfig;
