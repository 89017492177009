import useAdminReportApi from './api';

const useAdminReportHook = () => {
  const {
    t,
    user,

    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,

    usageDataColumnsConfiguration,

    downloadingCsv,
    downloadUsageReportCsv,
  } = useAdminReportApi();

  return {
    t,
    user,

    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,

    usageDataColumnsConfiguration,

    downloadingCsv,
    downloadUsageReportCsv,
  };
};

export default useAdminReportHook;
