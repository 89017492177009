/* eslint-disable react/jsx-props-no-spreading */

import { useTranslation } from 'react-i18next';
import useMemberships from './hook';
import Passes from './components/Passes';
import Transfers from './components/Transfers';
import Layout from '../../components/CardLayout';
import RenewMemberships from './components/RenewMemberships';

import PurchaseHistory from './components/PurchasesHistory';

export default function Memberships() {
  const {
    passes,
    passTypes,
    refresh,

    fetchPasses,
    passesTotal,
    passesTotalAvailable,
    passesPage,
    passesLoading,
    setPassesLoading,

    transfers,
    setTransfersLoading,
    transfersLoading,
    fetchTransfers,
    transferesPage,
    transferesTotal,

    tabsHistoryValue,
    handleTabHistoryChange,

    purchaseHistoryLoading,
    PurchaseHistoryPage,
    purchaseHistoryTotal,
    purchaseHistory,
    fetchPurchaseHistory,
    setPurchaseHistoryLoading,
  } = useMemberships();

  const { t } = useTranslation();

  return (
    <div className="memberships">
      <div className="col">
        <Layout size="sm" maxWidth="xl" className="centered-card">
          <RenewMemberships refresh={refresh} />
        </Layout>

        <div>
          <Layout size="sm" maxWidth="xl" className="centered-card">
            <Passes
              fetchPasses={fetchPasses}
              fetchTransfers={fetchTransfers}
              passesTotal={passesTotal}
              passesTotalAvailable={passesTotalAvailable}
              setPassesLoading={setPassesLoading}
              passesPage={passesPage}
              data={passes}
              passTypes={passTypes}
              loading={passesLoading}
              refresh={refresh}
            />
          </Layout>
        </div>

        <Layout size="sm" maxWidth="xl" className="centered-card">
          <div className="coin-packs-card">
            <div className="packs-head">
              <h1>{t('History')}</h1>
            </div>

            {tabsHistoryValue === 0 ? (
              <Transfers
                data={transfers}
                loading={transfersLoading}
                setTransfersLoading={setTransfersLoading}
                fetchTransfers={fetchTransfers}
                transferesPage={transferesPage}
                transferesTotal={transferesTotal}
                tabsHistoryValue={tabsHistoryValue}
                handleTabHistoryChange={handleTabHistoryChange}
              />
            ) : (
              <PurchaseHistory
                data={purchaseHistory}
                loading={purchaseHistoryLoading}
                setTransfersLoading={setPurchaseHistoryLoading}
                fetchTransfers={fetchPurchaseHistory}
                transferesPage={PurchaseHistoryPage}
                transferesTotal={purchaseHistoryTotal}
                tabsHistoryValue={tabsHistoryValue}
                handleTabHistoryChange={handleTabHistoryChange}
              />
            )}
          </div>
        </Layout>
      </div>

      {/* <div className="col right-col">
        <div>
          <Layout size="sm" maxWidth="xl">
            <RenewMemberships refresh={refresh} />
          </Layout>
        </div>

        <div>
          <Layout size="sm" maxWidth="xl">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabsValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Coin Packs" {...a11yProps(0)} />
                <Tab label="Bundles" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabsValue} index={0}>
              <PurchasePasses reset={refresh} />
            </CustomTabPanel>
            <CustomTabPanel value={tabsValue} index={1}>
              <PurchasePackages reset={refresh} />
            </CustomTabPanel>
          </Layout>
        </div>
      </div> */}
    </div>
  );
}
