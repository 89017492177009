/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
  CircularProgress,
  Container, Grid, Pagination, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { AdminReportContext, AdminReportProvider } from '../context';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import ComponentLoader from '../../../components/ComponentLoader';
import Button from '../../../components/Button';

const UsageReportPage = () => {
  const {
    t,
    usageData,
    loadedUsageData,
    fetchUsageData,
    usageDataColumnsConfiguration,
    usageDataPagination,
    downloadingCsv,
    downloadUsageReportCsv,
  } = useContext(AdminReportContext);
  const showPagination = true;

  return (
    <Container maxWidth="xl" className="usage-report-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Usage Report')}
        </Typography>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <div
          className="flex gap-4"
        >
          <Button
            onClick={() => downloadUsageReportCsv()}
            size="sm"
            bordered
            className="mr-4"
            disabled={downloadingCsv}
          >
            <span className="no-wrap">{downloadingCsv ? t('Downloading CSV') : t('Download CSV')}</span>
          </Button>
          {downloadingCsv && (
            <CircularProgress size={30} />
          )}
        </div>
      </Layout>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedUsageData ? (
          <>
            <Table
              loading={!loadedUsageData}
              columns={usageDataColumnsConfiguration}
              rows={usageData}
              className="table"
              showPagination
            />
            {(!!showPagination
              && !!usageDataPagination
              && usageDataPagination?.totalUsers > 25
            ) && (
                <div className="flex justify-end mt-4">
                  <Typography sx={{ mt: 0.5 }}>
                    {`Total of: ${usageDataPagination.totalUsers} users`}
                  </Typography>
                  <Pagination
                    count={usageDataPagination?.total}
                    page={usageDataPagination?.page}
                    onChange={(val, page) => {
                      fetchUsageData({ page });
                    }}
                  />
                </div>
              )}
          </>
        ) : (
          <ComponentLoader />
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <AdminReportProvider>
    <UsageReportPage />
  </AdminReportProvider>
);
