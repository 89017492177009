import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Modal from '../../../../../../../components/Modal';
import Form from '../../../../../../../components/Form';
import {
  defaultValue, formValidationConfig, formConfig,
} from './config';
import useApi from '../../../../../../../utils/api';
import { validateAll } from '../../../../../../../components/Form/config';
import PurchasePassTypeModal from '../PurchasePassTypeModal';
import { AuthenticationContext } from '../../../../../../../utils/store/authentication';
import ConfirmModal from './ConfirmModal';

export default function TransferPassesModal({
  data, handleModalClose, fetchPasses, fetchTransfers,
}) {
  const { user } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [passTypes, setPassTypes] = useState();
  const { authPost, authGet } = useApi();
  const [errors, setErrors] = useState();
  const [confirmModalData, setConfirmModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState();
  const [purchasePassesData, setPurchasePassesData] = useState();
  const [frmConfig, setFrmConfig] = useState();

  const fetchPassTypes = useCallback(async () => {
    setLoading(true);
    setFormLoading(true);
    try {
      let uri = '/pass-types';
      if (user) {
        uri = `${uri}?user=${user.SID}&transferable=true`;
      }
      const res = await authGet(uri);
      if (res) {
        setFrmConfig(formConfig);
        setPassTypes(res);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setFormLoading(false);
  }, [authGet]);

  useEffect(() => {
    fetchPassTypes();
  }, [formConfig]);

  const handleConfirmModalClose = () => {
    setConfirmModalData(null);
    setErrors(null);
  };

  const confirmTransfer = async (_tmp) => {
    const _data = { ..._tmp, username: _tmp.username };
    setFormLoading(true);
    try {
      const checkform = validateAll(_data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
        setFormLoading(false);
      } else {
        const res = await authPost(`passes/check-transfer/${data.pass}`, { data: _data });
        if (res) {
          const { username } = _data;
          const quest = `Are you sure to transfer ${res?.amount} coin ${res?.amount === 1 ? 'pack' : 'packs'} to ${username}?`;
          setConfirmMsg(quest);
          setConfirmModalData(_data);
          setFormLoading(false);
          setErrors(null);
        }
      }
    } catch (err) {
      console.log('-----confirmTransfer---', err);
      toast.error(t(err.message || err));
      setErrors(err);
    }
    setFormLoading(false);
  };

  const submitTransfer = async () => {
    setLoading(true);
    try {
      let res = null;
      res = await authPost(`passes/transfer/${data.pass}`, { data: confirmModalData });
      if (res) {
        fetchPasses(1);
        fetchTransfers(1);
        setFormLoading(false);
        setErrors(null);
        toast.success(t(res.message));
      }
    } catch (err) {
      setErrors(err);
      toast.error(t(err.message || err));
    }
    setConfirmModalData();
    handleModalClose();
    setLoading(false);
  };

  const onChagePassTypes = async (e) => {
    const passType = e.target.getAttribute('data-value');
    const pType = passTypes.find((p) => p.id === passType);

    if (pType && !pType?.total) {
      setLoading(true);
      try {
        const result = await authPost('/payments/passes/start', {
          data: { SID: user.SID, passType: pType.id, amountOfPasses: 1 },
        });

        const serverIpn = `${process.env.REACT_APP_BASE_URL}/payments/passes/ipn/${result.PUID}`;
        const uri = window.location.href;
        const uriParts = new URL(uri);
        const { origin } = uriParts;
        const redirect = `${origin}/packages`;
        // const redirect = `${process.env.REACT_APP_HOST}/packages`;
        setPurchasePassesData({
          ...result,
          formTitle: 'Proceed to checkout?',
          redirect,
          serverIpn,
          fromTransfer: true,
        });
        sessionStorage.setItem('_puid', result.PUID);
      } catch (err) {
        console.log('[buy]', err);
        toast.error(t('We cannot activate your gamer Pack right now, please try again later!'));
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title={t('Transfer')}
      width="550px"
      open={!!data}
      handleClose={handleModalClose}
    >
      <Form
        size="sm"
        config={frmConfig}
        onSubmit={confirmTransfer}
        submitLabel={t('Transfer')}
        defaultValue={defaultValue}
        errors={errors}
        formLoading={formLoading}
        onChangeSelect={() => { }}
        onClickSelectOption={(e) => onChagePassTypes(e)}
        buttonType="button"
        buttonStyle={{ minWidth: '182px' }}
      />

      {confirmModalData && (
        <ConfirmModal
          title={t('Confirm your transfer!')}
          loading={loading}
          confirmModalData={confirmModalData}
          handleConfirmModalClose={handleConfirmModalClose}
          confirmMsg={confirmMsg}
          onConfirmation={submitTransfer}
        />
      )}

      {purchasePassesData && (
        <PurchasePassTypeModal
          width="35%"
          open={!!purchasePassesData}
          data={purchasePassesData}
          setPurchasePassesData={setPurchasePassesData}
          fetchPassTypes={fetchPassTypes}
          fetchPasses={fetchPasses}
        />
      )}
    </Modal>
  );
}
