const defaultConfig = [
  {
    name: 'myAccountTitle',
    isText: true,
    type: 'h6',
    label: 'My Account',
  },

  {
    name: 'enrollmentLink',
    type: 'text',
    label: 'Enrollment Link',
    canCopy: true,
    disabled: true,
  },
  {
    name: 'Name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'Surname',
    type: 'text',
    label: 'Surname',
  },
  {
    name: 'Username',
    type: 'text',
    label: 'Username',
    autocomplete: 'new-user-username',
  },
  {
    name: 'isAffiliate',
    label: 'Ambassador',
    type: 'muiCheckbox',
    formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
  {
    name: 'addressInfoTitle',
    isText: true,
    type: 'h6',
    label: 'Address Info',
  },
  {
    name: 'Address.Line1',
    type: 'text',
    label: 'Address Line 1',
  },
  {
    name: 'Address.Line2',
    type: 'text',
    label: 'Address Line 2',
  },
  {
    name: 'Address.Country.Name',
    type: 'text',
    label: 'Country',
    disabled: true,
  },
  {
    name: 'Address.City',
    type: 'text',
    label: 'City',
  },
  {
    name: 'Address.State',
    type: 'text',
    label: 'State',
  },
  {
    name: 'Address.ZipCode',
    type: 'text',
    label: 'Zip Code',
  },
  
  // Personal Information
  {
    name: 'personalInformationTitle',
    isText: true,
    type: 'h6',
    label: 'Personal Information',
  },
  {
    name: 'Mobile',
    type: 'text',
    label: 'Mobile No',
  },
  {
    name: 'Email',
    type: 'text',
    label: 'email',
  },
  {
    name: 'DateBirth',
    type: 'date',
    label: 'Date Of Birth',
    disabled: true,
  },
];

const isAffiliateConfig = [
  {
    name: 'ambassadorSettingsTitle',
    isText: true,
    type: 'h6',
    label: 'Ambassador Settings',
  },
  {
    name: 'sponsorDisplayTitle',
    isText: true,
    type: 'subtitle1',
    label: 'Sponsor Display on Signup',
  },
  {
    name: 'infoText',
    isText: true,
    type: 'caption',
    label: 'Control what information you display to Gamers when they sign up through your Ambassador Link',
    style: { marginTop: '-20px' },
  },
  {
    name: 'settings.sponsorDetailsOnSignup.name',
    type: 'switch',
    label: 'Name',
  },
  {
    name: 'settings.sponsorDetailsOnSignup.email',
    type: 'switch',
    label: 'Email',
  },
  {
    name: 'settings.sponsorDetailsOnSignup.mobile',
    type: 'switch',
    label: 'Phone Number',
  },
];

const config = (affiliate = false) => {
  const formConfig = defaultConfig;
  if (affiliate) {
    isAffiliateConfig.forEach((item) => {
      if (!formConfig.some((i) => i.name === item.name)) {
        formConfig.push(item);
      }
    });
  }
  return formConfig;
};

export const formValidationConfig = {
  Email: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  Mobile: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  'Address.ZipCode': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.State': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.City': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Country.Name': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Line2': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Line1': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  EnrolledParentID: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  SID: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Surname: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  enrollmentLink: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },

};

export default config;
