import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Filler } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import useApi, { getGeckoApiConfig } from '../../../../../utils/api';

ChartJS.register(Filler);

export default function CoinsChart({ coins = [], game, isYesterday }) {
  const { authGet } = useApi();
  const ref = useRef();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [legend, setLegend] = useState();

  useEffect(() => {
    const _fetch = async () => {
      try {
        const firstCoin = coins[0];
        const secondCoin = coins[1];
        const to = isYesterday ? moment(game.endDate).unix() : moment().unix();
        const from = moment(game.startDate).unix();
        const firstRes = await authGet(`/coins/${firstCoin.apiId}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`, getGeckoApiConfig());
        const secondRes = await authGet(`/coins/${secondCoin.apiId}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`, getGeckoApiConfig());
        const { length } = firstRes.prices;
        const hours = moment(game.endDate).diff(moment(), 'hours');

        const firstPrices = firstRes.prices;
        const secondPrices = secondRes.prices;
        const diff = parseInt((24 * length) / (24 - hours), 10) - length;
        const coin1Start = firstCoin.start.price;
        const coin2Start = secondCoin.start.price;

        for (let i = 0; i < diff; i += 1) {
          firstPrices.push([0, 0]);
          secondPrices.push([0, 0]);
        }

        setLegend({
          coin1: {
            name: coins[0].name,
            icon: coins[0].image,
            color: '#FFB46A',
          },

          coin2: {
            name: coins[1].name,
            icon: coins[1].image,
            color: 'white',
          },
        });
        setChartData({
          labels: firstPrices.map(() => ''),
          datasets: [
            {
              label: 'First dataset',
              data: firstPrices.map(([, p]) => ((p - coin1Start) / p) * 100),
              fill: true,
              pointRadius: 0,
              backgroundColor: 'rgba(255, 180, 106, 0.2)',
              borderColor: '#FFB46A',
            },
            {
              label: 'Second dataset',
              data: secondPrices.map(([, p]) => ((p - coin2Start) / p) * 100),
              fill: true,
              pointRadius: 0,
              borderColor: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (coins.length === 2) {
      _fetch();
    }
  }, [coins]);

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', flex: 1, paddingRight: 2, 
    }}
    >
      <Typography sx={{ fontSize: '10px', letterSpacing: 0, ml: 1 }}>
        Performance
      </Typography>
      <div className="coins-chart" style={{ height: 200, width: '100%' }}>
        <Line
          data={chartData}
          width="80%"
          ref={ref}
          height={200}
          options={{
            maintainAspectRatio: false,
            resizeDelay: 0,
            responsive: true,
            scales: {
              y: {
                ticks: {
                  color: 'white',
                  callback(value) {
                    if (value < 1) {
                      return `${Number(value).toPrecision(2)}%`;
                    }

                    return `${Number(value).toLocaleString('en-us')}%`;
                  },
                },
              },
            },
          }}
        />
      </div>
      {chartData.datasets.length ? (
        <div className="coins-chart-legend" style={{ marginLeft: ref?.current?.chartArea?.left }}>
          <span>
            {moment(game.startDate).format('HH:mm')}
          </span>
          <span>
            {moment(game.startDate).add(6, 'hours').format('HH:mm')}
          </span>
          <span>
            {moment(game.startDate).add(12, 'hours').format('HH:mm')}
          </span>
          <span>
            {moment(game.startDate).add(18, 'hours').format('HH:mm')}
          </span>
          <span>
            {moment(game.endDate).format('HH:mm')}
          </span>
        </div>
      ) : null}

      <Typography sx={{
        fontSize: '10px', letterSpacing: 0, ml: 1, width: '100%', display: 'flex', justifyContent: 'end', pr: '8px',
      }}
      >
        Game Time
      </Typography>
      {
        legend ? (
          <Box sx={{
            display: 'flex', gap: 4, justifyContent: 'center', mt: 3,
          }}
          >

            <Box sx={{ display: 'flex' }}>
              <HorizontalRuleIcon sx={{ color: legend.coin1.color }} />

              <img style={{ borderRadius: '5px' }} alt={legend.coin1.name} src={legend.coin1.icon} />
              <HorizontalRuleIcon sx={{ color: legend.coin1.color }} />
            </Box>

            <Box sx={{ display: 'flex' }}>
              <HorizontalRuleIcon sx={{ color: legend.coin2.color }} />

              <img style={{ borderRadius: '5px' }} alt={legend.coin2.name} src={legend.coin2.icon} />
              <HorizontalRuleIcon sx={{ color: legend.coin2.color }} />
            </Box>

          </Box>
        ) : ''
      }
    </div>
  );
}
