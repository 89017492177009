import { PersonRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

const UserAccordionSummary = ({ image, name, expiration }) => (
  <div className="user-accordion-summary">
    <div className="image">
      {!image ? <PersonRounded fontSize="large" /> : <img src={image} alt="Profile pic" className="profile-pic" height="44" />}
    </div>
    <div className="info">
      <Typography>{expiration}</Typography>
      <h3>{name}</h3>
    </div>
  </div>
);

export default UserAccordionSummary;
