/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';

export default function MembershipsTab({
  config = [],
}) {
  return (
    <div className="memberships-tabs">
      {config.map((conf) => (
        <div className={clsx('tab', conf.isActive && 'active')} key={conf.key} onClick={conf.onClick}>
          {conf.label}
        </div>
      ))}
    </div>
  );
}
