import { Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../context';

const CountdownToMidnight = ({ game }) => {
  const { refreshMemeCoins } = useContext(Context);
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [loading, setLoading] = useState(true);

  const calculateTimeLeft = () => {
    const now = new Date();
    const midnight = new Date(game?.endDate);

    const difference = midnight - now;
    // eslint-disable-next-line no-shadow
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    const initializeCountdown = async () => {
      setTimeLeft(calculateTimeLeft());
      setLoading(false);
    };

    initializeCountdown();

    const timer = setInterval(() => {
      setTimeLeft(() => {
        const now = new Date();
        now.setUTCHours(now.getUTCHours());
        now.setUTCMinutes(now.getUTCMinutes());
        now.setUTCSeconds(now.getUTCSeconds());
        return calculateTimeLeft(now);
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    const { hours, minutes, seconds } = timeLeft;

    if (!hours && !minutes && !seconds) {
      return 'Ended';
    }

    if ((hours === 23 && minutes === 59 && seconds === 59)
      || (hours === 0 && minutes === 0 && seconds === 0)) {
      refreshMemeCoins();
    }
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  if (loading) {
    return (
      <Typography component="h2">
        Loading...
      </Typography>
    );
  }

  return (
    <Typography className="countdown" component="h2">
      <span className="text">
        Current Game Closes in:
      </span>

      {' '}
      <div className="primary-color">
        {formatTimeLeft()}
      </div>
    </Typography>
  );
};

export default CountdownToMidnight;
