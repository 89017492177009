const config = {
  viewBox: '0 0 21 21',
  children: (
    <>
      <circle cx="10.5" cy="10.5" r="10" stroke="#FF6969" />
      <path d="M14 7L7 14" stroke="#FF6969" />
      <path d="M7 7L14 14" stroke="#FF6969" />
    </>
  ),
};

export default config;
