import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/AuthLayout';
import Email from './email';
import { useNonAuthApi } from '../../../utils/api';
import { validateEmail } from '../../../utils/string';
import AppLoader from '../../../components/AppLoader';

export default function ResetPassword() {
  const { nonAuthPut } = useNonAuthApi();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);

  const onSubmitEmail = async (_data) => {
    setloading(true);
    if (_data.length === 0) {
      setErrors('Please write an email!');
      setloading(false);
    } else if (!validateEmail(_data)) {
      setErrors('Email is not valid!');
      setloading(false);
    } else {
      try {
        // check the email'
        await nonAuthPut('/auth/reset-password', { data: { email: _data } });
        toast.success(t('Email sent succssfully'));
        setloading(false);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } catch (err) {
        console.log(err);
        setloading(false);
        toast.error('We cannot find an account with that email address!');
      }
    }
  };

  return (

    <AuthLayout>

      { loading ? <AppLoader overpage /> : ''}
      <Typography sx={{
        display: 'flex', justifyContent: 'center', fontSize: '19px', fontWeight: 'bold', color: 'white',
      }}
      >
        {t('RESET PASSWORD')}
      </Typography>
      <div className="divider" />

      <Email
        loading={loading}
        setErrors={setErrors} 
        errors={errors}
        onSubmitEmail={onSubmitEmail}
      />
    </AuthLayout>
  );
}
