/* eslint-disable no-unreachable */
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import useAuthenticationApi from '../api';
import Layout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';
import { validateAll } from '../../../components/Form/config';
import { formValidationConfig } from './components/Step2/config';

export default function SignUp() {
  const { step } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enrollNow } = useAuthenticationApi();
  const [errors, setErrors] = useState();
  const [newRegisterData, setNewRegisterData] = useState();

  const signupParent = useMemo(() => getFromSession('signupParent'), []);
  const SignupSID = useMemo(() => sessionStorage.getItem('SignupSID'), []);
  const registrationData = useMemo(() => getFromSession('registration'), []);

  useEffect(() => {
    setNewRegisterData({
      dateOfBirth: dayjs(),
      country: 223,
      mobile: '',
      isAffiliate: false,
      isProduct: true,
    });
  }, [setNewRegisterData]);

  const onSubmit = async (val) => {
    setNewRegisterData({ ...newRegisterData, ...val });
    const checkform = validateAll(val, formValidationConfig);
    if (val?.username?.length < 4 || loading) {
      setErrors({ username: 'Username must have 4 characters or more' });
    } else if (checkform) {
      setErrors(checkform);
    } else if (!val.isAffiliate && !val.isProduct) {
      toast.error('To continue, you must be either an Ambassador, a Gamer, or both!');
    } else {
      const _val = {
        Mobile: val.mobile,
        Name: registrationData.givenName || registrationData?.user?.name?.firstName || '',
        Surname: registrationData.familyName || registrationData?.user?.name?.lastName || '',
        DateBirth: val.dateOfBirth.format('YYYY-MM-DD'),
        Email: registrationData.email || registrationData?.user?.email || '',
        Country: val.country,
        username: val.username,
        affiliateUsername: signupParent?.username,
        source: registrationData.source,
        isAffiliate: val.isAffiliate,
      };

      setLoading(true);

      try {
        const res = await enrollNow(_val);
        sessionStorage.setItem('SignupSID', res.SignupSID);
        sessionStorage.setItem('signupUser', JSON.stringify(res.user));
        sessionStorage.setItem('token', res?.token);
        navigate('/dashboard/enroll-now/pay');
      } catch (err) {
        // sessionStorage.removeItem('SignupSID');
        // sessionStorage.removeItem('signupUser');
        // sessionStorage.removeItem('token');
        console.log('---err', err);
        if (err?.username || err?.country || err?.mobile) {
          toast.error(t(err?.username || err?.country || err?.mobile));
          setErrors(err);
        } else {
          toast.error(typeof err === 'string' ? err : t('An error occured'));
        }
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (SignupSID) {
      navigate('/dashboard/enroll-now/pay');
    }
    // if (!registrationData) {
    //  navigate('/');
    // }
  }, []);

  const _render = () => {
    switch (step) {
      case 'new':
        return <Step1 registrationData={registrationData} signupParent={signupParent} />;
      case 'newRegister':
        return (
          <Step2
            errors={errors}
            onSubmit={onSubmit}
            data={newRegisterData}
            loading={loading}
            setErrors={setErrors}
            signupParent={signupParent}
            SignupSID={SignupSID}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout style={{ padding: '80px' }}>
      <div className="sign-up-page">{_render()}</div>
    </Layout>
  );
}
