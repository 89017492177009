/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Typography } from '@mui/material';
import CardChip from '../CardChip';
import CoinsChart from '../CoinsChart';
import { renderNumber } from '../../../../../utils/number';

export default function OthersCont({
  game, chosenMemeCoin, coins, isYesterday,
  percentageChanged, potentialPoints, isTop10,
}) {
  const leaderboardRank = game?.statistics?.rankingPercentage || 0;

  return (
    <div className="today-extra flex flex-column p-12">

      <Box sx={{
        display: 'flex',
        flexDirection: {
          xl: 'column',
          lg: 'column',
          md: 'column',
          sm: 'row',
          xs: 'row',
        },
      }}
      >
        <div className="potential-points mb-12">
          <div className="left">
            {/* // changed to points */}
            <span>
              {isYesterday
                ? isTop10 ? 'Tokens Earned' : 'Coins Refunded'
                : 'Potential Tokens'}
            </span>
          </div>
          <div className="right">
            <span>
              {isYesterday ? renderNumber(potentialPoints) : ((game?.price || 0) * (game?.maxMultiplier || 1)).toLocaleString('en-us')}
            </span>
          </div>
        </div>

        <div className="potential-points-mobile">

          <Typography sx={{
            fontSize: '15px',
            fontWeight: '400',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            Potential Tokens
          </Typography>

          <Typography sx={{
            color: 'var(--primary-color)',
            fontSize: '15px',
            fontWeight: '400',
            justifyContent: 'start',
            alignItems: 'center',
          }}
          >
            {((game?.price || 0) * (game?.maxMultiplier || 1)).toLocaleString('en-us')}
          </Typography>

        </div>
        <CoinsChart isYesterday={isYesterday} game={game} coins={coins} />
      </Box>

      {chosenMemeCoin ? (
        <div className="data">
          <div className="row-item">
            <span>
              My % Change:
            </span>

            <span className="primary-color">
              {isYesterday ? (
                <>
                  {Math.ceil(percentageChanged)}
                  %
                  (
                  {Number(percentageChanged).toFixed(2)}
                  %)
                </>
              ) : percentageChanged}
            </span>
          </div>

          <div className="row-item">
            <span>
              {isYesterday ? 'Final Score:' : 'Current Score:'}
            </span>
            <span className="primary-color">
              {renderNumber(potentialPoints)}
            </span>
          </div>

          <div className="row-item">
            <span>
              Leaderboard Rank:
            </span>
            <div className="meme-coin-data">
              <div className="data-cont">
                <div className="chip">
                  <CardChip isGrowing={leaderboardRank <= 10} noIcon={leaderboardRank > 10} className="with-border">
                    {parseInt(leaderboardRank, 10)}
                    %
                  </CardChip>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
