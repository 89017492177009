/* eslint-disable import/no-absolute-path */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Icon from '../components/Icon';

// eslint-disable-next-line no-confusing-arrow
const getColor = (focused, theme) => (!focused ? 'rgba(255,255,255, 0.7)' : theme.PRIMARY_COLOR);

const drawerItems = [
  {
    id: '0',
    label: 'Home',
    path: '/',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Home" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '1',
    label: 'Leaderboard',
    path: '/leaderboard',
    isProduct: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Trophy" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '2',
    label: 'Game Rules',
    path: '/game-rules',
    isProduct: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Star" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '3',
    label: 'Coin Packs',
    path: '/packages',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Memberships" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '4',
    label: 'Redeem Tokens',
    path: '/redeem-token',
    roles: ['all'],
    isProduct: true,
    icon: (focused, theme) => (
      <Icon name="MoneyHand" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '5',
    label: 'Game History',
    path: '/game-history',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="ClockArrow" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '6',
    label: 'Admin',
    isAffiliate: true,
    isProduct: true,
    path: '/admin/members',
    roles: ['admin', 'superadmin', 'owner', 'root'],
    icon: (focused, theme) => (
      <Icon name="Admin" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: '6-a',
        label: 'Member Admin',
        path: '/admin/members',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-b',
        label: 'Packs',
        path: '/admin/passes',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-c',
        label: 'Bundles',
        path: '/admin/packages',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-d',
        label: 'Pack Types',
        path: '/admin/pass-types',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-e',
        label: 'Ranks',
        path: '/admin/ranks',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-f',
        label: 'Meme Coins',
        path: '/admin/meme-coins',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-g',
        label: 'Meme Games',
        path: '/admin/meme-games',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-h',
        label: 'Descriptions History',
        path: '/admin/descriptions-history',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-i',
        label: 'Game Payouts',
        path: '/admin/payouts',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-j',
        label: 'Send Email',
        path: '/admin/send-email',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: '6-k',
        label: 'Pending Withdrawals',
        path: '/admin/pending-withdrawals',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
    ],
  },
  {
    id: '15',
    label: 'Admin Reports',
    isAffiliate: true,
    isProduct: true,
    path: '/admin/reports/usage',
    roles: ['admin', 'superadmin', 'owner', 'root'],
    icon: (focused, theme) => (
      <Icon name="ReportFile" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: '15-a',
        label: 'Usage Report',
        path: '/admin/reports/usage',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
    ],
  },
  {
    id: '8',
    label: 'Genealogy',
    isAffiliate: true,
    path: '/sales/list',
    noPath: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Genealogy" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: '8-a',
        label: 'Personals',
        path: '/sales/list',
        roles: ['all'],
      },
      {
        id: '8-b',
        label: 'Team',
        path: '/sales/tree',
        roles: ['all'],
      },
    ],
  },
  {
    id: '9',
    label: 'Gamer Wallet',
    path: '/gamer-wallets',
    roles: ['all'],
    isProduct: true,
    isAffiliate: false,
    icon: (focused, theme) => (
      <Icon name="Wallets" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '10',
    label: 'Ambassador Wallet',
    path: '/ambassador-wallets',
    roles: ['all'],
    isProduct: false,
    isAffiliate: true,
    icon: (focused, theme) => (
      <Icon name="Wallets" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '11',
    label: 'Wallets',
    isAffiliate: true,
    isProduct: true,
    path: '/ambassador-wallets',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Wallets" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: '11-a',
        label: 'Ambassador Wallet',
        path: '/ambassador-wallets',
        roles: ['all'],
      },
      {
        id: '11-b',
        label: 'Gamer Wallet',
        path: '/gamer-wallets',
        roles: ['all'],
      },
    ],
  },
  {
    id: '12',
    label: 'Profile',
    path: '/profile',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Profile" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '13',
    label: 'Comp Plan',
    path: '/comp-plan',
    isAffiliate: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Dollar" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  {
    id: '18',
    label: 'Resources',
    isAffiliate: true,
    noPath: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <InfoOutlinedIcon
        sx={{ fontSize: '16px' }}
        name="Genealogy"
        color={getColor(focused, theme)}
        width={14}
        height={14}
      />
    ),
    children: [
      {
        id: '18-a',
        label: 'Deck',
        roles: ['all'],
        file: {
          href: 'https://s3.amazonaws.com/cdn.memegames.ai/downloads/mgdeck.pdf',
          download: 'deck.pdf',
        },
      },
    ],
  },
  {
    id: '14',
    label: 'Support',
    path: '/',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Support" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
];

export default drawerItems;
