import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form from '../../../components/Form';

const formConfig = [

  {
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Password',
    format: (item) => item.trim(),
  
  },
  
  {
    name: 'rewritePassword',
    type: 'password',
    label: 'Rewrite Your Password',
    format: (item) => item.trim(),
  
  },
  
];

export default function NewPassword({ onSubmit, config }) {
  const [formConfiguration, setformConfiguration] = useState(formConfig);
  const { t } = useTranslation();
  const getFormConfig = () => formConfiguration;

  return (
    <Form
      size="md"
      config={getFormConfig(t)}
      submitLabel="Submit"
      onSubmit={onSubmit}
      errors={config.errors}
      formLoading={config.loading}
    />
  );
}
