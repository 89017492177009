const config = {
  width: '32',
  height: '28',
  viewBox: '0 0 32 28',
  children: (
    <>
      <path d="M15.428 1.5293C20.7415 1.5293 25.0484 5.75985 25.0484 10.9792C25.0484 16.1986 20.7415 20.4292 15.428 20.4292C10.1145 20.4292 5.80762 16.1986 5.80762 10.9792C5.80762 5.75985 10.1152 1.5293 15.428 1.5293Z" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4985 3.55176C19.6245 3.55176 22.9686 6.83731 22.9686 10.8894" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.3165 17.6572L30.1686 22.4233L27.137 25.402L22.1133 20.468" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4456 6.06543C16.7355 6.06543 17.7814 7.14953 17.7814 8.48613C17.7814 9.38954 17.3042 10.1765 16.5962 10.5931C16.2491 10.8048 15.845 10.9265 15.4125 10.9265C14.1226 10.9265 13.0767 9.84238 13.0767 8.50578C13.0767 7.60237 13.5538 6.81538 14.2611 6.39958C14.6082 6.1879 15.0131 6.06619 15.4448 6.06619L15.4456 6.06543Z" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.6378 16.438L20.4392 13.9221C20.3584 12.0064 10.8358 12.1493 10.5279 13.9032C10.3063 14.8565 10.2524 14.6917 10.2231 16.4388" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3784 15.9248L12.3545 18.0257" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5061 18.0257L18.4814 15.9248" stroke="#8FC2FF" strokeWidth="1.44949" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
};
  
export default config;
