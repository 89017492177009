import { useContext } from 'react';
import OthersCont from '../OthersCont';
import { Context } from '../../../context';

export default function YesterdayOtherCoins({ game = {}, coins, leaderboardRank }) {
  const { chosenYesterdayMemeCoin: chosenYesterdayMemeCoins } = useContext(Context);
  const chosenMemeCoin = chosenYesterdayMemeCoins.find((c) => c.gameId === game.id);
  const coin = chosenMemeCoin ? coins.find((c) => c.id === chosenMemeCoin.id) : null;

  const isTop10 = game?.statistics?.rankingPercentage <= 10;
  const percentage = isTop10
    ? game?.statistics?.multiplier
    : game?.statistics?.grossMultiplier;

  const waged = chosenMemeCoin?.mgCoins;

  // eslint-disable-next-line no-nested-ternary
  const potentialPoints = coin?.winner
    ? percentage < 1 ? waged * 1 : waged * Math.ceil(percentage) : 0;

  const percentageChanged = chosenMemeCoin?.priceChange ? chosenMemeCoin?.priceChange : 0;

  return (
    <OthersCont
      game={game}
      isYesterday
      coins={coins}
      isTop10
      chosenMemeCoin={chosenMemeCoin}
      leaderboardRank={leaderboardRank}
      potentialPoints={potentialPoints}
      percentageChanged={percentageChanged}
    />
  );
}
