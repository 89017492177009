/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import AppLoader from '../../../components/AppLoader';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import NumberInput from '../../../components/Inputs/NumberInput';
import useApi from '../../../utils/api';

const defaultConfig = [
  {
    rankingPercentage: 1,
    multiplier: 10,
  },
  {
    rankingPercentage: 2,
    multiplier: 9,
  },
  {
    rankingPercentage: 3,
    multiplier: 8,
  },
  {
    rankingPercentage: 4,
    multiplier: 7,
  },
  {
    rankingPercentage: 5,
    multiplier: 6,
  },
  {
    rankingPercentage: 6,
    multiplier: 5,
  },
  {
    rankingPercentage: 8,
    multiplier: 4,
  },
  {
    rankingPercentage: 9,
    multiplier: 3,
  },
  {
    rankingPercentage: 10,
    multiplier: 2,
  },

];

export default function GamePayouts() {
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(true);
  const { authGet, authPut } = useApi();

  const onChangeConfig = (key, val, i) => {
    setConfig((p) => p.map((conf, j) => {
      if (i === j) {
        return { ...conf, [key]: val };
      }
      return conf;
    }));
  };

  const onAddNew = () => {
    setConfig((p) => [...p, {}]);
  };

  const onDelete = (i) => {
    if (config.length <= 1) {
      return;
    }

    setConfig((p) => [...p.filter((_, j) => i !== j)]);
  };

  const onSave = async () => {
    setLoading(true);

    try {
      const multipliers = config.filter((c) => c.rankingPercentage && c.multiplier);
      await authPut('/payout', {
        data: { multipliers },
      });
      toast.success('Payout saved successfully!');
    } catch (err) {
      toast.error('Something went wrong. Please try again later!');
    }

    setLoading(false);
  };

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true);

      try {
        const { multipliers } = await authGet('/payout');
        setConfig(multipliers);
      } catch (err) {
        toast.error('Something went wrong. Please try again later!');
        setConfig(defaultConfig);
      }

      setLoading(false);
    };

    _fetch();
  }, []);

  return (
    <>
      {loading && <AppLoader overpage />}
      <Layout className="game-payouts" maxWidth="xl" cardMainClassName="pt-10 pb-10" size="xsm">
        <div className="flex input-row">
          <Typography className="flex-1">
            Percenage (%)
          </Typography>
          <Typography className="flex-1 pr-7">
            Multiplier
          </Typography>
        </div>
        {config && config.map((c, i) => (
          <div className="flex input-row" key={i}>
            <NumberInput
              className="flex-1"
              value={c.rankingPercentage}
              placeholder="Percenage"
              onChange={(v) => onChangeConfig('rankingPercentage', v, i)}
            />
            <NumberInput
              className="flex-1"
              value={c.multiplier}
              placeholder="Multiplier"
              onChange={(v) => onChangeConfig('multiplier', v, i)}
            />
            <div className="flex flex-column cursor-pointer" onClick={() => onDelete(i)}>
              <Delete color="error" />
            </div>
          </div>
        ))}

        <Button className="mt-4 mb-4" onClick={onAddNew}>
          Add New
        </Button>

        <Button onClick={onSave}>
          Save
        </Button>
      </Layout>
    </>
  );
}
