/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { useContext } from 'react';
import {
  Grid, Pagination, CircularProgress, Typography,
  Container,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { Context, Provider } from './context';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import UserModal from './components/modal';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';
import UserAccordion from './components/UserAccordion';

function Users() {
  const {
    loadingUsers,
    usersA,
    usersG,
    totalAPages,
    usersAPage,
    searchText,
    setSearchText,
    handleKeyDown,
    searchClick,
    openModal,
    onModalClose,
    listTabsValue,
    handleListTabChange,
    fetchUsersAmbassadors,
    fetchUsersGamers,
    totalGPages,
    usersGPage,
    usersATotal,
    usersGTotal,
    loadingGUsers,
    loadingAUsers,

  } = useContext(Context);

  return (
    <Container
      sx={{ mt: 4, px: 0 }}
      spacing={10}
      className="genealogy-list"
      maxWidth="lg"
    >
      {loadingUsers ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid item xs rowSpacing={2}>
          <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className="personals-tabs"
          >
            <Box>
              <Tabs
                value={listTabsValue}
                onChange={handleListTabChange}
                aria-label="basic tabs example"
                className="personals-tabs-header"
              >
                <Tab label="Ambassadors" {...a11yProps(0)} />
                <Tab label="Gamers" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={listTabsValue}
              index={0}
              className="personals-tabs-body"
            >
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ paddingBottom: 4 }}>
                  <Typography
                    fontSize="14px"
                    color="white"
                    textAlign="center"
                    padding="20px 60px"
                    style={{ opacity: 0.5 }}
                  >
                    What would you like to search for?
                  </Typography>
                  <TextInput
                    size="sm"
                    className="search-input mb-4"
                    placeholder="Start typing here"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onKeyDown={handleKeyDown}
                    endAdornment={(
                      <Button
                        onClick={searchClick}
                        className="my-auto mr-5"
                        bordered
                        size="sm"
                      >
                        <span className="no-wrap">
                          <img
                            alt="logo"
                            src="/assets/finder.svg"
                            height="14px"
                          />
                        </span>
                      </Button>
                    )}
                  />
                  {loadingAUsers ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography
                        fontSize="14px"
                        color="white"
                        textAlign="center"
                        padding="20px"
                        fontWeight="500"
                      >
                        {`${usersATotal} found`}
                      </Typography>
                      {usersA && usersA.map((user, idx) => <UserAccordion user={user} key={idx} />)}
                    </>
                  )}
                </Grid>

                {usersATotal > 25 && (
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-end"
                    className="custom-pagination"
                  >
                    <Typography sx={{ mt: 0.5 }}>
                      {`Total of: ${usersATotal} ambassadors`}
                    </Typography>
                    <Pagination
                      count={totalAPages}
                      page={usersAPage}
                      onChange={(val, page) => {
                        fetchUsersAmbassadors(page);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel
              value={listTabsValue}
              index={1}
              className="personals-tabs-body"
            >
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ paddingBottom: 4 }}>
                  <Typography
                    fontSize="14px"
                    color="white"
                    textAlign="center"
                    padding="20px 60px"
                    style={{ opacity: 0.5 }}
                  >
                    What would you like to search for?
                  </Typography>
                  <TextInput
                    size="sm"
                    className="search-input mb-4"
                    placeholder="Start typing here"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onKeyDown={handleKeyDown}
                    endAdornment={(
                      <Button
                        onClick={searchClick}
                        className="my-auto mr-5"
                        bordered
                        size="sm"
                      >
                        <span className="no-wrap">
                          <img
                            alt="logo"
                            src="/assets/finder.svg"
                            height="14px"
                          />
                        </span>
                      </Button>
                    )}
                  />
                  {loadingGUsers ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography
                        fontSize="14px"
                        color="white"
                        textAlign="center"
                        padding="20px"
                        fontWeight="500"
                      >
                        {`${usersGTotal} found`}
                      </Typography>
                      {usersG && usersG.map((user, idx) => <UserAccordion user={user} key={idx} />)}
                    </>
                  )}
                </Grid>

                {usersGTotal > 25 && (
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-end"
                    className="custom-pagination"
                  >
                    <Typography sx={{ mt: 0.5 }}>
                      {`Total of: ${usersGTotal} gamers`}
                    </Typography>
                    <Pagination
                      count={totalGPages}
                      page={usersGPage}
                      onChange={(val, page) => {
                        fetchUsersGamers(page);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </CustomTabPanel>
          </Grid>
        </Grid>
      )}
      {openModal && (
        <UserModal
          selectedUser={openModal}
          handleUserModalClose={onModalClose}
        />
      )}
    </Container>
  );
}

export default () => (
  <Provider>
    <Users />
  </Provider>
);
