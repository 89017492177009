export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const renderNumber = (val) => {
  const _val = Number(val);

  if (Number.isNaN(_val)) {
    return 0;
  }

  if (_val < 1) {
    return _val;
  }

  return _val.toLocaleString('en-us');
};

export default null;
