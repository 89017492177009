import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';
import Button from '../../../../../../../components/Button';

export default function ConfirmModal({
  title,
  loading,
  confirmModalData,
  handleConfirmModalClose,
  confirmMsg,
  onConfirmation,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      width="450px"
      open={!!confirmModalData}
      handleClose={handleConfirmModalClose}
    >
      <div className="flex flex-column justify-center">
        <div className="mx-auto">
          <Typography
            sx={{
              fontSize: '18px',
              mb: 3,
              mt: 2,
            }}
          >
            {t(confirmMsg)}
          </Typography>
        </div>
        <div className="mx-auto" />
        <Button
          type="submit"
          size="md"
          className="max-width"
          disabled={loading}
          onClick={() => onConfirmation()}
        >
          {t('Ok')}
        </Button>
      </div>
    </Modal>
  );
}
