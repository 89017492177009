import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../utils/store';

const AffiliateRoute = ({ children, isAffiliate, isProduct }) => {
  const { user } = useAuthentication();
  if (!user.isAffiliate && user.Role !== 'owner' && user.Role !== 'admin') {
    // user is not authorized
    return <Navigate to="/" />;
  }

  // 11EJ affiliate=true product=false
  if (user?.isAffiliate && isAffiliate && !user.isProduct === isProduct && user.Role !== 'owner' && user.Role !== 'admin') {
    return '';
  }

  // 33EJ affiliate=false product=true
  if (!user.isAffiliate && !isAffiliate && user.isProduct === isProduct && user.Role !== 'owner' && user.Role !== 'admin') {
    return '';
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AffiliateRoute;
