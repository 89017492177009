import React, { createContext } from 'react';
import useStyleStore from './hook';

export const StyleContext = createContext({
  muiTheme: {},
});

/**
 * StyleContext Provider
 * @returns {ReactNode}  wrapper for StyleContext
 */
export const StyleProvider = ({ children = null }) => {
  const value = useStyleStore();

  return (
    <StyleContext.Provider value={value}>{children}</StyleContext.Provider>
  );
};
