import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import config from './config';
import Form from '../../../../components/Form';
import useChangePassword from './hook';

export default function ChangePassword() {
  const { submitPassword, errors, message } = useChangePassword();
  const { t } = useTranslation();

  useEffect(() => {
    const el = document.getElementById('alert-message-password');
    if (el) {
      el.focus();
    }
  }, [message]);

  return (
    <>
      {message && (
        <Alert
          id="alert-message-password"
          sx={{ mb: '15px' }}
          className={message?.type}
          severity={message?.type}
        >
          {t(message?.label)}
        </Alert>
      )}
      {' '}
      {config && (
        <Form
          errors={errors}
          onSubmit={submitPassword}
          config={config}
          size="sm"
          submitLabel="Change My Password"
          clearAfterSubmit
          defaultFormValue={{ newPassword: '', oldPassword: '', retypePassword: '' }}
        />
      )} 
    </>
  );
}
