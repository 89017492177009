import { Container } from '@mui/material';
import ComponentLoader from '../../../components/ComponentLoader';
import useLeaderboardApi from '../context/api';
import Table from '../../../components/Table';

const TopYesterday = () => {
  const {
    loadingTopRankYesterday,
    topRankYesterdayData,
    leaderboardTopYesterdayColumns,
  } = useLeaderboardApi();

  return loadingTopRankYesterday ? (
    <ComponentLoader />
  ) : (
    <Container maxWidth="100%" sx={{ padding: { xs: 0 } }}>
      <Table columns={leaderboardTopYesterdayColumns} rows={topRankYesterdayData} className="table" />
    </Container>
  );
};

export default TopYesterday;
