import { useCallback } from 'react';
import useAuthApi from '../../../utils/api';

export default function useMemeCoinsApi() {
  const {
    authGet, authPut, authPost, authDelete,
  } = useAuthApi();

  // TODO: add endpoint, pagination?
  const getMemeCoins = useCallback(() => authGet('/memecoins'), [authGet]);

  // TODO: add endpoint
  const createMemeCoin = useCallback((data) => authPost('/memecoins', { data }), [authPost]);

  // TODO: add endpoint
  const updateMemeCoin = useCallback((data, id) => authPut(`/memecoins/${id}`, { data }), [authPut]);

  // TODO: add endpoint
  const deleteMemeCoin = useCallback((id) => authDelete(`/memecoins/${id}`), [authDelete]);

  return {
    getMemeCoins,
    createMemeCoin,
    updateMemeCoin,
    deleteMemeCoin,
  };
}
