import clsx from 'clsx';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '../../Button';

export default function NumberInput({
  name = '',
  className,
  withButtons,
  value = '',
  focused = false,
  placeholder = '',
  style,
  size = 'md',
  endAdornment = '',
  startAdornment = '',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
}) {
  return (
    <div style={style} className="form-input-group">
      <div
        className={clsx(
          'form-input text-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
          withButtons && 'centered-input absolute-adornment',
        )}
      >
        {withButtons ? (
          <div className="flex flex-column justify-center adornment left-adornment">
            <Button size="small" bordered className="ml-1" onClick={() => onChange(Number(value) - 1)}>
              <div className="flex flex-column justify-center p-1">
                <RemoveIcon fontSize="small" />
              </div>
            </Button>
          </div>
        ) : startAdornment}

        <input
          id={name}
          type="text"
          value={String(value)}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          className={clsx(focused && 'focused', size, disabled && 'disabled', error && 'error')}
          onChange={(e) => {
            const val = e.target.value ? Number(e.target.value.match(/\d/g)?.join('')) : '';
            if (!Number.isNaN(val)) onChange(val);
          }}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
        />

        {withButtons ? (
          <div className="flex flex-column justify-center adornment right-adornment">
            <Button size="small" bordered className="mr-1" onClick={() => onChange(Number(value) + 1)}>
              <div className="flex flex-column justify-center p-1">
                <AddIcon fontSize="small" />
              </div>
            </Button>
          </div>
        ) : endAdornment}
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

NumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
