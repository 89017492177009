import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import useApi from '../../../utils/api';

export default function AjaxSelect({
  name = '',
  value = '',
  focused = false,
  placeholder = '',
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  size = 'md',
  endPoint = '/',
  mapper = (val) => val,
  disabled = false,
  error,
}) {
  const { authGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const _fetch = async () => {
      try {
        setLoading(true);
        const res = await authGet(endPoint);

        if (Array.isArray(res)) {
          setOptions(res.map(mapper));
        } else if (Array.isArray(res?.data)) {
          setOptions(res?.data?.map(mapper));
        } else if (Array.isArray(Object.keys(res.data))) {
          setOptions(Object.keys(res.data).map(mapper));
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    _fetch();
  }, []);

  return (
    <div className="form-input select-input flex-col">
      <MUISelect
        id={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        className={clsx('select', focused && 'focused', size, error ? 'error' : 'clean')}
        onChange={(e) => { onChange(e.target.value); }}
        disabled={loading || disabled}
      >
        {options.map((o) => (
          <MenuItem value={o.value} key={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </MUISelect>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

AjaxSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  mapper: PropTypes.func,
  onChange: PropTypes.func,
};
