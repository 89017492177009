import moment from 'moment';
import useApi from '../../../utils/api';

export const usePickPoints = () => {
  const { authGet } = useApi();

  const getSpeedMultiplier = ({ todayGame, startDate }) => {
    if (true) {
      return 1;
    }

    let speedMultiplier = 1;
    const voteDate = moment(startDate);
    const _startDate = moment(todayGame.startDate);
    const diffInHours = voteDate.diff(_startDate, 'hours');

    speedMultiplier = 24 - diffInHours;

    if (speedMultiplier < 1) {
      speedMultiplier = 1;
    }

    return speedMultiplier;
  };

  const getPercentageMultiplier = ({ coinData, maxMultiplier }) => {
    let percentageMultiplier = 1;

    const startPrice = Number(coinData.start.price ? parseFloat(coinData.start.price) : 0);
    const endPrice = Number(coinData?.data?.market_data?.current_price?.usd || 0);
    const priceChangeFromVote = endPrice && startPrice ? (
      Math.ceil(
        ((
          (endPrice - startPrice) / startPrice
        ) * 100
        ).toFixed(2),
      )
    ) : 0;

    if (priceChangeFromVote > 1) {
      percentageMultiplier = Math.round(priceChangeFromVote);
    } else {
      percentageMultiplier = 1;
    }

    return maxMultiplier && percentageMultiplier > maxMultiplier
      ? maxMultiplier : percentageMultiplier;
  };

  const getStreakMultiplier = async ({ todayGame }) => {
    if (true) {
      return 1;
    }

    let _streakMultiplier = 1;

    try {
      const res = await authGet(`/schedule-game/streak-multiplier/${todayGame.id}`);
      const _res = Number(res);
      _streakMultiplier = Number.isNaN(_res) ? 1 : _res;
    } catch (err) {
      console.error(err);
    }

    return _streakMultiplier;
  };

  return {
    getSpeedMultiplier, getPercentageMultiplier, getStreakMultiplier,
  };
};

export default usePickPoints;

export const tabTypes = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
};
