import { Container, Typography, Box } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function Banner({ data }) {
  return (
    <Container
      sx={{
        background: 'black',
        borderRadius: '5px',
        mt: 3,
        mb: 5,
        p: '5px 8px',
        borderColor: 'var(--primary-color)',
        borderStyle: 'solid',
        borderWidth: '1px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <NotificationsNoneIcon
        sx={{ fontSize: '40px', color: 'var(--primary-color)' }}
      />
      <Box>
        <Typography sx={{ fontStyle: 'italic', color: 'white' }}>
          <span>
            {data?.message}
          </span>
        
        </Typography>
        <Typography sx={{ fontStyle: 'italic', color: 'white', fontSize: '13px' }}>
        
          <span>
            {data?.message1}
          </span>
        </Typography>
      </Box>
    
    </Container>
  );
}
