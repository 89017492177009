/* eslint-disable max-len */
import { useContext } from 'react';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { modalTypes, transferFormConfig } from '../config';
import Form from '../../../components/Form';

export default function TransferModal() {
  const {
    modalData, onModalClose, onTransferSubmit, t, transferLoading,
  } = useContext(Context);

  if (modalData?.type === modalTypes.TRANSFER) {
    return (
      <Modal width="550px" title={modalData.description} open={modalData} handleClose={onModalClose}>
       
        <Form
          config={transferFormConfig}
          size="sm"
          defaultFormValue={{}}
          onSubmit={onTransferSubmit}
          formLoading={transferLoading}
          submitLabel="Transfer"
        />
      </Modal>
    );
  }
}
