import React from 'react';
import LoopIcon from '@mui/icons-material/Loop';

/**
 * AppLoader component
 * @returns {ReactNode}  AppLoader component
 */
export default function AppLoader({ overpage = false }) {
  return (
    <div className={`app-loader ${overpage ? 'overpage' : ''} `}>
      <div className="loading-div">
        <span className="loading-text">LOADING ...</span>
        <LoopIcon color="primary" className="loading-icon" />
      </div>
    </div>
  );
}
