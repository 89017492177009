/* eslint-disable max-len */
import { useContext, useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import Button from '../../../components/Button';
import TextInput from '../../../components/Inputs/TextInput';
import { modalTypes } from '../config';

export default function CommitmentModal() {
  const {
    modalData, onModalClose, onCommitmentSave, t,
  } = useContext(Context);
  const [data, setData] = useState(modalData?.value || 0);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setData(modalData?.value || 0);
  }, [modalData]);

  if (modalData?.type === modalTypes.COMMITMENT) {
    return (
      <Modal width="550px" title={t('Change Commitment')} open={modalData} handleClose={onModalClose}>
        <Container>
          <Grid
            container
            columnSpacing={2}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xl={2} lg={3} md={3} sm={4} xs={4} className="py-auto my-auto">
              <Button
                bordered
                className="w-fit"
                onClick={() => {
                  if (data > 0) {
                    setData((i) => i - 1);
                  }
                }}
              >
                <span className="ml-1 mr-1 my-auto py-auto">-</span>
              </Button>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={4} xs={4} className="py-auto my-auto">
              <TextInput
                value={data}
                type="number"
                onChange={(e) => setData(e)}
                endAdornment={(
                  <Typography sx={{
                    display: 'flex', justifyContent: 'end', alignItems: 'center', fontWeight: 600, mx: 1,
                  }}
                  >
                    %
                  </Typography>
                )}
              />
            </Grid>

            <Grid item xl={2} lg={3} md={3} sm={4} xs={4} className="py-auto my-auto">
              <Button
                className="w-fit"
                bordered
                onClick={() => {
                  if (data < 100) {
                    setData((i) => i + 1);
                  }
                }}
              >
                <span className="ml-1 mr-1 my-auto py-auto">+</span>
              </Button>
            </Grid>
            <Grid item xl={12} lg={12} md={12}>

              <Typography sx={{
                display: 'flex', justifyContent: 'center', color: 'brown', fontSize: '13px', mt: 1,
              }}
              >
                {validation}
              </Typography>

            </Grid>

            <Grid item xl={12} lg={12} md={12}>
              <Button
                className="flex-1 mt-3"
                onClick={() => {
                  if (data > 100) {
                    setValidation(t('Value must be less than 100!'));
                  } else if (data < 0) {
                    setValidation(t('Value must be more or equal to 0!'));
                  } else {
                    setValidation();
                    onCommitmentSave(data);
                  }
                }}
              >
                <span>Save</span>
              </Button>
            </Grid>

          </Grid>

          <Typography
            onClick={onModalClose}
            sx={{
              textDecoration: 'underline',
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
              cursor: 'pointer',
            }}
          >
            {' '}
            Cancel
          </Typography>
        </Container>
      </Modal>
    );
  }
}
