import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import PassTypes from '../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Divider from '../../../../Authentication/PayNow/components/Divider';
import useApi from '../../../../../utils/api';
import PaymentRenewModal from './PaymentRenewModal';

export default function RenewMembershipModal({
  data,
  handleModalClose,
  selectedPackage,
  setSelectedPackage,
}) {
  const { user, availablePasses } = data;
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const { authPost } = useApi();
  const [buyFormData, setBuyFormData] = useState();

  const onRenew = async () => {
    setLoading(true);
    const token = availablePasses.find((p) => p.id === selectedPackage)?.firstAvailableToken;

    if (token) {
      try {
        const result = await authPost('/passes/consume', { data: { token } });
        if (result.success) {
          toast.success(t('You have succefully activated your membership!'));
          handleModalClose();
        }
      } catch (err) {
        console.log('[renew error]', err);
        toast.error(t('We cannot activate your membership right now, please try again later!'));
      }
    }
    setLoading(false);
  };

  const onBuy = async () => {
    setLoading(true);
    setBuyFormData({ user });
    setLoading(false);
  };

  const handleBuyModalClose = async () => {
    setBuyFormData(null);
  };

  return (
    <Modal
      title={t('Activate Membership!')}
      width="350px"
      open={!!data}
      handleClose={handleModalClose}
    >
      <Grid container spacing={2}>
        <PassTypes
          packages={availablePasses}
          loading={loading}
          formDescription="You have available Packs to activate your membership. Do you want to use one?"
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
        />
        <Grid container item style={{ marginTop: '20px' }}>
          <Button
            style={{ background: 'none' }}
            onClick={onRenew}
            bordered
            className="full-width"
            type="button"
            disabled={loading}
          >
            {t('Activate Now')}
          </Button>
        </Grid>
        <Divider />
        <BuyButton disabled={loading} onSubmitBitcoin={onBuy} />
      </Grid>

      {buyFormData && (
        <PaymentRenewModal
          open
          data={buyFormData}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          handleModalClose={handleBuyModalClose}
          location="profile"
        />
      )}

    </Modal>
  );
}
