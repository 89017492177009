import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from '../../../../../components/Button';

export default function BuyButton({
  disabled, onSubmitBitcoin, title, type, style,
}) {
  const { t } = useTranslation();
  return (
    <Grid container item>
      <Button
        type={type || 'button'}
        className="full-width"
        onClick={onSubmitBitcoin}
        disabled={disabled}
        style={style}
        title={t(title || 'Continue to Payment')}
      >
        {t(title || 'Continue to Payment')}
      </Button>
    </Grid>
  );
}
