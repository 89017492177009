import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AuthenticationContext } from '../../../utils/store/authentication';
import useApi from '../../../utils/api';

const PAGE_SIZE = 10;

const useGameHistoryApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [loadedData, setLoadedData] = useState(false);
  const [gamesData, setGamesData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [tokens, setTokens] = useState(0);

  const { authGet } = useApi();

  const groupGamesByDate = (games) => {
    const groupedData = games.reduce((acc, game) => {
      const date = dayjs(game.endDate).format('DD MMM YYYY').toString();
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(game);

      return acc;
    }, []);

    return groupedData;
  };

  const getPaginatedData = useCallback(
    async ({
      page = 1, game = undefined, price = 0, days = 0, 
    }) => {
      try {
        setLoadedData(false);
        
        const response = await authGet('/schedule-game/history', {
          params: {
            page, perPage: PAGE_SIZE, game, price, days,
          },
        });

        setGamesData(groupGamesByDate(response.data));
        setPaginationData({
          totalUsers: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });

        setLoadedData(true);
      } catch (err) {
        console.log(err);
      }
    },
    [authGet],
  );

  const getGamerTokens = async () => {
    const res = await authGet('/gamer-wallet');
    setTokens(res?.token || 0);
  };

  useEffect(() => {
    getGamerTokens();
    getPaginatedData({});
  }, []);

  return {
    t,
    user,
    loadedData,
    gamesData,
    getPaginatedData,
    paginationData,
    tokens,
  };
};

export default useGameHistoryApi;
