import IconButton from '@mui/material/IconButton';
import Icon from '../../../Icon';
import { useGeneral } from '../../../../utils/store';

const CustomCollapseIcon = ({ toggleDrawer }) => {
  const { theme } = useGeneral();

  return (
    <IconButton
      sx={{
        flexBasis: '17%',
        padding: 0,
        marginLeft: 0,
        marginRight: 3,
        background: 'var(--collapse-button)',
        height: '45px',
        width: '30px',
        display: 'flex',
        borderRadius: '0 10px 10px 0',
      }}
      className="icon-container"
      onClick={toggleDrawer}
    >
      <Icon name="DoubleChevronLeft" color={theme.PRIMARY_COLOR} width={14} height={14} />
    </IconButton>
  );
};

export default CustomCollapseIcon;
