import { createContext } from 'react';
import useGenealogy from './hook';

export const Context = createContext({
  passTypes: [],
  users: [],
  genealogyData: [],
  getUsersForTree: () => {},
  t: () => {},
  columns: [],
  totalPages: 0,
  loadingUsers: false,
  usersAPage: 0,
  usersGPage: 0,
  handleFilterChange: () => {},
  sortModel: [],
  setSortModel: () => {},
  searchClick: () => {},
  searchText: '',
  setSearchText: () => {},
  setPaginationAData: () => {},
  setPaginationGData: () => {},
  paginationAData: 0,
  paginationGData: 0,
  openModal: null,
  onModalClose: () => {},
  listTabsValue: 0,
  handleListTabChange: () => {},
  fetchUsersAmbassadors: () => {},
  fetchUsersGamers: () => {},
  filtersA: {},
  filtersG: {},
  sortModelA: {},
  sortModelG: {},
  setSortModelA: () => {},
  setSortModelG: () => {},
  usersA: [],
  usersG: [],
  usersATotal: 0,
  usersGTotal: 0,
  loadingGUsers: false,
  
});

export const Provider = ({ children }) => {
  const value = useGenealogy();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
