/* eslint-disable no-unused-vars */
import React, { createContext } from 'react';
import useAuthenticaitonStore from './hook';

export const AuthenticationContext = createContext({
  token: '',
  user: null,
  signOut: () => { },
  checkUser: () => { },
  updateUser: (_user = {}) => { },
  authenticated: false,
  authenticationLoading: true,
  authenticateUser: (_user = {}, _token = '') => { },
  updateUserImage: (_image = {}) => { },
  userImage: null,
  setUserImage: (_image = '') => { },
});

/**
 * AuthenticationContext Provider
 * @returns {ReactNode}  wrapper for AuthenticationContext
 */
export const AuthenticationProvider = ({ children = null }) => {
  const value = useAuthenticaitonStore();

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
