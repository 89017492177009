/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import {
  useCallback, useState, useEffect, useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../../../utils/api';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import { AppContext } from '../../../../utils/store/app';

export default function useGenealogy() {
  const { authGet, authPost } = useApi();
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthenticationContext);
  const { passTypes } = useContext(AppContext);
  const { t } = useTranslation();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [changeTeamModal, setChangeTeamModal] = useState(false);
  const [loadingselectedUser, setLoadingselectedUser] = useState(false);
  const [volume, setVolume] = useState(0);
  const [personalVolume, setPersonalVolume] = useState(0);
  const [qualificationPercent, setQualificationPercent] = useState();

  const fetchVolume = async () => {
    try {
      const res = await authGet('/dashboard/volumes');
      setVolume(res || {});
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = useCallback(
    async () => {
      setLoadingUsers(true);

      try {
        // orderBy[name]=1
        const res = await authGet('/genealogy', {
        });

        setUsers(res);
        setLoadingUsers(false);
      } catch (err) {
        console.log(err);
        setLoadingUsers(false);
      }
    },
    [user],
  );

  const nrOfRanks = useMemo(() => {
    if (user.rank.rank < 2) {
      return 1;
    }

    if (user.rank.rank >= 2 && user.rank.rank <= 9) {
      return 2;
    }

    if (user.rank.rank >= 10) {
      return 3;
    }

    return 1;
  }, [user]);

  const fetchPersonalVolume = async () => {
    try {
      const resp = await authGet('/payments/personal-volume');
      setPersonalVolume(resp?.pv);
      const qpResp = await authGet('/payments/qualification');
      setQualificationPercent(qpResp);
    } catch (err) {
      console.log(err); 
    }
  };
   
  useEffect(() => {
    fetchUsers();
    fetchVolume();
    fetchPersonalVolume();
  }, []);

  const fetchSelectedUser = async (_user) => {
    setLoadingselectedUser(true);
    try {
      const res = await authGet(`/genealogy/user/${_user.userId}`);
      setSelectedUser(res);
      setLoadingselectedUser(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserModalClose = () => {
    setSelectedUser();
  };

  const onChangeTeamClick = () => {
    setChangeTeamModal(true);
  };

  const handleCloseTeamModal = () => {
    setChangeTeamModal(false);
  };

  const onActiveTeamSubmit = async (_data) => {
    try {
      await authPost('/genealogy/change-user-team ', { data: { activeTeam: _data } });
      setChangeTeamModal(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    users,
    passTypes,
    loadingUsers,
    user,
    t,
    selectedUser,
    handleUserModalClose,
    fetchSelectedUser,
    onChangeTeamClick,
    changeTeamModal,
    handleCloseTeamModal,
    onActiveTeamSubmit,
    loadingselectedUser,
    volume,
    nrOfRanks,
    personalVolume,
    qualificationPercent,
  };
}
