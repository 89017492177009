const config = {
  viewBox: '0 0 14 14',
  children: (
    <g opacity="1">
      <mask id="path-1-inside-1_186_3412" fill="currentColor">
        <path d="M3 0.34375H11L13.6562 4.34375L7 13.6562L0.34375 4.34375L3 0.34375Z" />
      </mask>
      <path
        opacity={0.7}
        d="M3 0.34375V-0.65625H2.46365L2.16695 -0.209447L3 0.34375ZM11 0.34375L11.833 -0.209447L11.5363 -0.65625H11V0.34375ZM13.6562 4.34375L14.4698 4.92525L14.8705 4.36462L14.4893 3.79055L13.6562 4.34375ZM7 13.6562L6.18645 14.2377L7 15.376L7.81355 14.2377L7 13.6562ZM0.34375 4.34375L-0.4893 3.79055L-0.870516 4.36462L-0.469799 4.92525L0.34375 4.34375ZM3 1.34375H11V-0.65625H3V1.34375ZM10.167 0.896947L12.8232 4.89695L14.4893 3.79055L11.833 -0.209447L10.167 0.896947ZM12.8427 3.76225L6.18645 13.0748L7.81355 14.2377L14.4698 4.92525L12.8427 3.76225ZM7.81355 13.0748L1.1573 3.76225L-0.469799 4.92525L6.18645 14.2377L7.81355 13.0748ZM1.1768 4.89695L3.83305 0.896947L2.16695 -0.209447L-0.4893 3.79055L1.1768 4.89695Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_186_3412)"
      />
    </g>
  ),
};

export default config;
