import { api, getGeckoApiConfig } from '.';

const getGeckoData = async (coinId) => {
  try {
    const response = await api.get(`/coins/${coinId}?localization=false&tickers=false&market_data=true&community_data=true&developer_data=false&sparkline=true`, getGeckoApiConfig());
    return response.data;
  } catch (err) {
    console.log(err);
  }
  return null;
};

export default getGeckoData;
