import { useAuthentication } from '../utils/store';
import AppLoader from '../components/AppLoader';

const PublicRoute = ({ children }) => {
  const { authenticationLoading } = useAuthentication();

  if (authenticationLoading) {
    return <AppLoader />;
  }

  return children;
};

export default PublicRoute;
