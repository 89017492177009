import { Grid } from '@mui/material';
import usePasses from './hook';
import PassCard from './components/passCard';
import PassModal from './components/modal';
import Button from '../../../components/Button';
import TextInput from '../../../components/Inputs/TextInput';
import AppLoader from '../../../components/AppLoader';

export default function Passes() {
  const {
    passes = [],
    errors,
    onPassClick,
    submit,
    selectedPass,
    handleModalClose,
    setSearchText,
    searchText,
    searchClick,
    loading,
    message,
    formLoading,
  } = usePasses();

  if (loading) {
    return <AppLoader />;
  }

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xl={10} lg={10} md={10} sm={9} xs={12} sx={{ mt: 3 }}>
          <TextInput
            size="sm"
            placeholder="Lookup Key History"
            value={searchText}
            onChange={(e) => {
              setSearchText(e);
            }}
            endAdornment={(
              <Button onClick={searchClick} className="py-auto my-auto mr-5" bordered size="sm">
                <span className="no-wrap">Lookup</span>
              </Button>
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12} className="flex justify-end" sx={{ mt: 3 }}>
          <Button className="my-auto py-auto" size="sm" onClick={() => onPassClick()}>
            Add +
          </Button>
        </Grid>
        {passes?.map((item) => (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <PassCard key={item.Token} data={item} />
          </Grid>
        ))}
      </Grid>
      <PassModal
        errors={errors}
        submit={submit}
        data={selectedPass}
        handleModalClose={handleModalClose}
        message={message}
        formLoading={formLoading}
      />
    </>
  );
}
