/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import useApi from '../../../utils/api';

const ApplyPassToggle = ({ setIsProcessing, paymentId }) => {
  const { authPut } = useApi();
  const [apply, setApply] = useState(false);
  const [processing, setProcessing] = useState(false);

  const changePaymentApplyImmediately = useCallback(
    async (newValue) => authPut(`/payments/${paymentId}/immediately`, {
      data: { applyImmediately: newValue },
    }),
    [authPut, paymentId],
  );

  const handleChange = async () => {
    setProcessing(true);
    const newValue = !apply;

    const changed = await changePaymentApplyImmediately(newValue);

    if (changed) {
      setApply(newValue);
    } else {
      toast.error('Something went wrong. Please try again later!');
    }

    setProcessing(false);
  };

  useEffect(() => {
    setIsProcessing(processing);
  }, [processing]);

  return (
    <label className="apply-immediately" htmlFor="apply-immediately-checkbox">
      <Checkbox
        checked={apply}
        onChange={handleChange}
        value="Immediately apply my MG Coins to my account"
        disabled={processing}
        id="apply-immediately-checkbox"
      />
      <span>Immediately apply my MG Coins to my account</span>
    </label>
  );
};

export default ApplyPassToggle;
