const config = [
  {
    name: 'title',
    isText: true,
    type: 'h6',
    label: 'Change Password',
  },
  {
    name: 'oldPassword',
    type: 'password',
    label: 'Old Password',
  },
  {
    name: 'newPassword',
    type: 'password',
    label: 'New Password',
  },
  {
    name: 'retypePassword',
    type: 'password',
    label: 'Retype Password',
  },
];

export const formValidationConfig = {
  oldPassword: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  newPassword: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  retypePassword: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
};

export default config;
