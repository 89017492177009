/* eslint-disable react/jsx-filename-extension */
import { Link } from 'react-router-dom';

export const getFormConfig = (t = (s) => s) => [
  {
    name: 'username',
    type: 'text',
    label: 'Member ID / Username',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    header: (
      <Link className="forgot-pass-link" to="/forgot-password">{t('Forgot Password')}</Link>
    ),
  },
];

export default getFormConfig;
