import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Modal from '../../../../components/Modal';

export default function UserModal({
  selectedUser,
  handleUserModalClose,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${selectedUser?.displayName}`}
      width="550px"
      open={!!selectedUser}
      handleClose={handleUserModalClose}
    >
      <Container className="user-modal">
        <Typography className="section-title">{t('MEMBER DETAILS')}</Typography>
        <div className="divider" />

        <div className="user-data">
          <Typography className="user-data-title">{t('Name')}</Typography>
          <Typography className="user-data-value">
            {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          </Typography>
        </div>

        <div className="user-data">
          <Typography className="user-data-title">{t('Username')}</Typography>
          <Typography className="user-data-value">
            {selectedUser?.username}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Qualified')}</Typography>
          <Typography className="user-data-value">
            {selectedUser?.qualified ? 'Yes' : 'No'}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Join Date')}</Typography>
          <Typography className="user-data-value">
            {dayjs(selectedUser?.dateJoined).format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Expire Date')}</Typography>
          <Typography className="user-data-value">
            {dayjs(selectedUser?.dateExpiring).format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Rank')}</Typography>
          <Typography className="user-data-value">
            {`${selectedUser?.rank?.name || '-'}`}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Mobile')}</Typography>
          <Typography className="user-data-value">
            {`${selectedUser?.mobile}`}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Ambassador')}</Typography>
          <Typography className="user-data-value">
            {selectedUser?.isAffiliate ? 'Yes' : 'No'}
          </Typography>
        </div>
        <div className="user-data">
          <Typography className="user-data-title">{t('Gamer')}</Typography>
          <Typography className="user-data-value">
            {selectedUser?.isProduct ? 'Yes' : 'No'}
          </Typography>
        </div>

      </Container>
    </Modal>
  );
}
