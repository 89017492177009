/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import {
  useState, useEffect, useCallback, useMemo, 
} from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { colConfig, historyTable } from './config';
import useApi from '../../../../../utils/api';
import useAuthenticaitonStore from '../../../../../utils/store/authentication/hook';
import validatePassword from '../../../../../utils/data/validatePassword';

export default function useViewMember() {
  const { t } = useTranslation();
  const { authenticateUser } = useAuthenticaitonStore();
  const params = useParams();
  const { authGet, authPost } = useApi();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [clearCacheAlertMessage, setClearCacheAlertMessage] = useState(null);
  const [passChangeAlertMessage, setPassChangeAlertMessage] = useState(null);
  const [sponsorChangeAlertMessage, setSponsorChangeAlertMessage] = useState(null);
  const [suspendAlertMessage, setSuspendAlertMessage] = useState(null);
  const [suspendedText, setSuspendText] = useState();
  const [terminateAlertMessage, setTerminateAlertMessage] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const res = await authGet(`/users/${params.id}`);
      setData(res);
    } catch (err) {
      console.log(err);
    }
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [params]);

  // #region Account Information Table
  const accountInformationTableRows = useMemo(
    () => [
      {
        name: t('Name'),
        adminUser: `${data?.Name || ''} ${data?.Surname || ''}`,
        id: 1,
      },
      {
        name: t('Member ID'),
        adminUser: data?.SID || '',
        id: 2,
      },
      {
        name: t('Username'),
        adminUser: data?.Username || '',
        id: 3,
      },
      {
        name: t('Sponsor Name'),
        adminUser: `${data?.sponsor?.Name || ''} ${data?.sponsor?.Surname || ''}`,
        id: 5,
      },
      {
        name: t('Sponsor Username'),
        adminUser: `${data?.sponsor?.Username || ''} (${data?.EnrolledParentID || ''})`,
        id: 6,
      },
      {
        name: t('Rank'),
        adminUser: data?.rankName || '',
        id: 7,
      },
      {
        name: t('Status'),
        adminUser: data?.Active ? 'Active' : 'Inactive',
        id: 8,
      },
      {
        name: t('Email'),
        adminUser: data?.Email || '',
        id: 9,
      },
      {
        name: t('Mobile'),
        adminUser: data?.Mobile || '',
        id: 10,
      },
      {
        name: t('Date of Birth'),
        adminUser: data?.DateBirth ? dayjs(data?.DateBirth).format('DD-MM-YYYY') : '-',
        id: 11,
      },
      {
        name: t('Country'),
        adminUser: data?.Address?.Country?.Name || '',
        id: 12,
      },
      {
        name: t('Join Date'),
        adminUser: dayjs(data?.DateJoined).format('MMM DD, YYYY'),
        id: 13,
      },

      {
        name: t('Expiration Date'),
        adminUser: dayjs(data.DateExpiring).format('MMM DD, YYYY'),
        id: 14,
      },

      {
        name: t('Last Login Date'),
        adminUser: dayjs(data?.DateLastLogin).format('MMM DD, YYYY'),
        id: 15,
      },
      {
        name: t('Gamer'),
        adminUser: data?.isProduct ? 'Yes' : 'No',
        id: 16,
      },
      {
        name: t('Ambassador'),
        adminUser: data?.isAffiliate ? 'Yes' : 'No',
        id: 17,
      },
 
    ],
    [data],
  );

  const accountInformationTableColumns = [
    {
      field: 'name',
      headerName: 'NAME',
      width: 200,
      ...colConfig,
    },
    {
      field: 'adminUser',
      headerName: t('INFO'),
      width: 500,
      ...colConfig,
    },
  ];
  // #endregion

  // #region CHange password and sponsor
  const changeSponsor = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        if (!data?.sponsorId) {
          setErrors({ sponsorId: 'Sponsor Id should not be empty!' });
          if (sponsorChangeAlertMessage) {
            setSponsorChangeAlertMessage(null);
          }
          setIsLoading(false);
        } else {
          const res = await authPost(`users/change-sponsor/${data.SID}`, { data });
          if (res) {
            setErrors(null);
            setSponsorChangeAlertMessage({
              type: 'success',
              label: `Sponsor for ${data.fullName} changed successfully!`,
            });
            setIsLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        // const errMsg = process.env.NODE_ENV === 'development'
        //   ? err
        //   : 'Something went wrong. Please try again later!';
        setSponsorChangeAlertMessage({ type: 'error', label: err });
        setIsLoading(false);
      }
    },
    [authPost, setIsLoading],
  );

  const changePassword = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        if (!data?.password) {
          setErrors({ password: 'Password should not be empty!' });
          if (passChangeAlertMessage) {
            setPassChangeAlertMessage(null);
          }
          setIsLoading(false);
        } else if (!validatePassword(data.password)) {
          setErrors({ password: 'Password must contain at least 8 characters. It must contain at least 1 capital letter, 1 number, and 1 special character!' });
          if (passChangeAlertMessage) {
            setPassChangeAlertMessage(null);
          }
          setIsLoading(false);
        } else if (data?.retypePassword !== data.password) {
          setErrors({ retypePassword: "Password confirmation doesn't met!" });
          if (passChangeAlertMessage) {
            setPassChangeAlertMessage(null);
          }
          setIsLoading(false);
        } else {
          await authPost(`users/change-pass/${data.SID}`, { data });
          toast.success('Password changed successfully!');

          setErrors(null);
          setPassChangeAlertMessage({
            type: 'success',
            label: `Password for ${data.fullName} changed successfully!`,
          });
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        const errMsg = process.env.NODE_ENV === 'development'
          ? err
          : 'Something went wrong. Please try again later!';
        setPassChangeAlertMessage({ type: 'error', label: errMsg });
        setIsLoading(false);
        toast.error('Something went wrong. Please try again later!');
      }
    },
    [authPost, setIsLoading],
  );
  // #endregion

  // #region Renewal History
  const [renewalHistoryHandles, setRenewalHistoryHandles] = useState({
    0: true,
    1: false,
    2: false,
  });

  const renewalHistory = [
    {
      id: 1,
      title: 'Renewal History',
      details: 'No activation history for this member',
    },
    {
      id: 2,
      title: 'Extension History',
      details: 'No activation history for this member',
    },
    {
      id: 3,
      title: 'E-Wallet History',
      details: 'No activation history for this member',
    },
  ];

  const history = useMemo(() => {
    if (data?.RenewalHistory) {
      const renewCols = [
        {
          field: 'Date',
          headerName: t('Date'),
          width: 150,
          ...colConfig,
        },
        {
          field: 'Extension',
          headerName: t('Extension'),
          width: 150,
          ...colConfig,
        },
        {
          field: 'Method',
          headerName: t('Method'),
          width: 150,
          ...colConfig,
        },
        {
          field: 'Reference',
          headerName: t('Reference'),
          width: 150,
          ...colConfig,
        },
      ];
      const renewRows = data?.RenewalHistory?.map((item, index) => ({
        id: index,
        ...item,
        Date: dayjs(item.Date).format('DD-MM-YYYY'),
      }));

      return [
        {
          id: 1,
          title: 'Activation History',
          details: historyTable({ columns: renewCols, rows: renewRows }),
        },
        {
          id: 2,
          title: 'Extension History',
          details: t('No history for this member'),
        },
        {
          id: 3,
          title: 'E-Wallet History',
          details: t('No history for this member'),
        },
      ];
    }
    return [];
  }, [data, params]);
  // #endregion

  // #region Wallet Management
  const wallets = [
    {
      id: 0,
      name: 'Legacy Wallet',
      value: 0,
    },
    {
      id: 1,
      name: 'E-Wallet',
      value: 0,
    },
    {
      id: 2,
      name: 'E-Wallet',
      value: 0,
    },
  ];

  const appendWallet = (value, index) => {
    console.log(value, index);
  };
  // #endregion

  // #region action buttons
  const [actionButtonModal, setActionButtonModal] = useState(false);
  const [actionDataModal, setActionDataModal] = useState();

  const clearCache = useCallback(async (sid) => {
    try {
      setIsLoading(true);
      const res = await authGet(`/users/clear-cache/${sid}`);
      if (res.success) {
        setClearCacheAlertMessage({ type: 'success', label: 'Cache flushed successfully!' });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  });

  const suspend = useCallback(
    async (_data) => {
      setIsLoading(true);
      try {
        if (!_data?.reason) {
          setErrors({ reason: 'Reason should not be empty!' });
          if (suspendAlertMessage) {
            setSuspendAlertMessage(null);
          }
          setIsLoading(false);
        } else {
          const path = !_data.suspended ? 'unsuspend' : 'suspend';
          const res = await authPost(`users/${path}/${data.SID}`, {
            data: { reason: _data?.reason },
          });
          if (res) {
            setData({ ...data, Suspended: _data.suspended });
            setSuspendText(data.suspended ? 'UNSUSPEND' : 'SUSPEND');
            setErrors(null);
            setSuspendAlertMessage({
              type: 'success',
              label: `User ${
                data?.fullName || `"${data.Name} ${data.Surname}"`
              } ${path}ed successfully!`,
            });
            setIsLoading(false);
            toast.success(
              t(
                `User ${
                  data?.fullName || `"${data.Name} ${data.Surname}"`
                } ${path}ed successfully!`,
              ),
            );
          }
        }
      } catch (err) {
        console.log(err);
        const errMsg = process.env.NODE_ENV === 'development'
          ? err
          : 'Something went wrong. Please try again later!';
        setSuspendAlertMessage({ type: 'error', label: errMsg });
        setIsLoading(false);
      }
    },
    [authPost, setIsLoading, data, setSuspendText],
  );

  const terminate = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await authPost(`users/terminate/${data.SID}`);
      if (res) {
        setErrors(null);
        setTerminateAlertMessage({
          type: 'success',
          label: `Gamer ${data.fullName} terminated successfully!`,
        });
        setIsLoading(false);
        toast.success(t(`${data.Name} ${data.Surname} terminated successfully!`));
        setActionButtonModal();
        setData({ ...data, Terminated: true });
      }
    } catch (err) {
      console.log(err);
      const errMsg = process.env.NODE_ENV === 'development'
        ? err
        : 'Something went wrong. Please try again later!';
      setTerminateAlertMessage({ type: 'error', label: errMsg });
      setIsLoading(false);
      toast.error(t('Something went wrong.Please try again later!'));
      setActionButtonModal();
    }
  }, [data]);

  const adjustMembershipDateConfirmed = useCallback(
    async (_data) => {
      try {
        await authPost(`users/${_data.type}-expiration/${data.SID}`, {
          data: { days: _data.days },
        });
        toast.success(t('Membership Date was adjusted successfully!'));
        setActionButtonModal();
      } catch (err) {
        console.log(err);
        toast.error(t('Something went wrong. Please try again later!'));
        setActionButtonModal();
      }
    },
    [data],
  );

  const adjustMembershipDate = useCallback(
    async (_data) => {
      setActionButtonModal('confirm-adjustment');
      setActionDataModal(_data);
    },
    [data],
  );
  // #endregion

  const onSimulate = useCallback(async () => {
    if (data) {
      try {
        const res = await authGet(`/auth/impersonate?sid=${data.SID}`);
        authenticateUser(res.user, res.token);
        sessionStorage.setItem('_imp', 1);
        // navigate('/');
        window.location.href = '/';
      } catch (err) {
        console.log(err);
      }
    }
  }, [data]);
  
  return {
    accountInformationTableColumns,
    accountInformationTableRows,
    t,
    renewalHistory,
    isLoading,

    passChangeAlertMessage,
    setPassChangeAlertMessage,

    sponsorChangeAlertMessage,
    setSponsorChangeAlertMessage,

    clearCacheAlertMessage,
    setClearCacheAlertMessage,

    suspend,
    suspendAlertMessage,
    setSuspendAlertMessage,

    terminate,
    terminateAlertMessage,
    setTerminateAlertMessage,

    suspendedText,
    setSuspendText,

    errors,
    setErrors,
    renewalHistoryHandles,
    setRenewalHistoryHandles,
    wallets,
    changePassword,
    appendWallet,
    actionButtonModal,
    setActionButtonModal,
    actionDataModal,
    setActionDataModal,
    clearCache,
    data,
    history,
    changeSponsor,

    adjustMembershipDate,
    adjustMembershipDateConfirmed,
    
    onSimulate,
  };
}
