import { Container, Grid, Typography } from '@mui/material';
import Layout from '../../components/CardLayout';

export default function Payments() {
  return (
    <Grid container className="home">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Layout maxWidth="xl">
          <Container sx={{ pb: 6 }}>
            <Container sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
              <Typography style={{ color: '#fff' }} variant="h5" sx={{ fontWeight: 600 }}>
                {' '}
                Processing...
                {' '}
              </Typography>
            </Container>
          </Container>
        </Layout>
      </Grid>
    </Grid>
  );
}
