import { useContext } from 'react';
import { StyleContext } from './style';
import { GeneralContext } from './general';
import { AuthenticationContext } from './authentication';

/**
 * Hook used to access GeneralContext values
 */
export const useGeneral = () => {
  const value = useContext(GeneralContext);
  return value;
};

/**
 * Hook used to access StyleContext values
 */
export const useStyle = () => {
  const value = useContext(StyleContext);
  return value;
};

/**
 * Hook used to access AuthenticationContext values
 */
export const useAuthentication = () => {
  const value = useContext(AuthenticationContext);
  return value;
};
