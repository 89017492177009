const config = {
  viewBox: '0 0 59 73',
  children: (
    <svg width="59" height="73" viewBox="0 0 59 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M42.5286 15.6562H58V28.6848" stroke="white" strokeMiterlimit="10" />
        <path d="M17.2856 23.8V13.2143C17.2856 6.46874 22.7544 1 29.4999 1C36.2455 1 41.7142 6.46874 41.7142 13.2143V23.8" stroke="white" strokeMiterlimit="10" />
        <path d="M14.0286 23.8008H20.5429" stroke="white" strokeMiterlimit="10" />
        <path d="M38.457 23.8008H44.9713" stroke="white" strokeMiterlimit="10" />
        <path d="M18.1001 15.6562H36.0144" stroke="white" strokeMiterlimit="10" />
        <path d="M58 15.6562V64.0277C58 68.5258 54.3553 72.1705 49.8571 72.1705H9.14286C4.64474 72.1705 1 68.5258 1 64.0277V15.6562H11.5857" stroke="white" strokeMiterlimit="10" />
        <path d="M40.1361 42.9151C38.9916 42.8939 37.8461 42.9106 36.7013 42.9106H32.148V39.9079H26.6776L26.6482 42.906L21.5772 42.9196L21.5393 37.9462L43 37.9425V33H18.2329C16.7435 33.2474 16.0142 34.148 16.0084 35.7502C15.9971 38.8488 15.9971 41.9474 16.009 45.0457C16.0161 46.9132 16.9802 47.8002 19.0188 47.805C21.5717 47.8109 42.9599 47.7979 42.9599 47.7979C42.9578 47.0369 42.9575 46.2755 42.9547 45.5144C42.9489 43.8958 41.8741 42.9474 40.1361 42.9151Z" stroke="white" />
        <path d="M32.148 50.0967H26.6776V58H32.148V50.0967Z" stroke="white" />
      </g>
    </svg>
  ),
};
  
export default config;
