import { Typography, Grid } from '@mui/material';
import { useContext } from 'react';
import CardMedia from '@mui/material/CardMedia';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import PrevNextButtons from './prevNext';
import MiniChart from '../../../components/Crypto/miniChart';
import { newsTabs } from '../config';

const defaultNewsImages = [
  '/assets/images/bitcoin.jpg',
  '/assets/images/tech.jpg',
  '/assets/images/realestate.jpg',

];

export default function News() {
  const {
    t,
    // cryptoTimeSpan, setCryptoTimeSpan, changeNewsTab, 
    newsTab, newsTabTab,
    changeNewsTabTab, news,
  } = useContext(Context);

  return (
    <Layout sx={{}} maxWidth="xl" maxHeight="100%">
      <Grid container rowSpacing={1}>

        {/* // ## HEADER ## */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

          <Grid container rowSpacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Typography sx={{
                fontSize: '26px',
                fontWeight: 600,
                alignItems: 'center',
                justifyContent: {
                  xl: 'start', lg: 'start', md: 'center', sm: 'start', xs: 'center',
                },
                display: 'flex',
              }}
              >
                {t(newsTabs[newsTab].name)}
              </Typography>
            </Grid>
            {/* <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: {
                  xl: 'end', lg: 'end', md: 'center', sm: 'end', xs: 'center',
                },
                alignItems: 'center',
              }}
            >
              <PrevNextButtons
                onClickPrev={() => {
                  changeNewsTab('prev');
                }}
                onClickNext={() => {
                  changeNewsTab('next');
                }}
              />
            </Grid> */}
          </Grid>

        </Grid>

        {/* // ## CONTENT ## // */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="divider" />
            </Grid>
            {
              newsTab === 0 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MiniChart />
                </Grid>
              )
            }
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

              <Grid container spacing={2} sx={{ mt: 1 }}>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                  <Grid container sx={{ display: 'flex' }}>
                    {/* // Foto */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <CardMedia
                        sx={{
                          height: 180,
                          borderRadius: '8px 8px 0 0',
                        }}
                        image={news[newsTab][newsTabTab]?.image || defaultNewsImages[newsTab]}
                        title="green iguana"
                      />

                    </Grid>

                    {/* // COntent */}
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        // boxShadow: `box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.36);
                        // -webkit-box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.36);
                        // -moz-box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.36);`, 

                        borderRadius: '0 8px 8px 0',
                      }}

                    >
                      <Grid container direction="column">
                        <Grid item>
                          <Typography sx={{
                            color: 'var(--primary-color)', fontWeight: 500, fontSize: '18px', mb: 1, mt: 2,
                          }}
                          >
                            {news[newsTab][newsTabTab].title}
                          </Typography>
                          <Typography>
                            {news[newsTab][newsTabTab].description}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            color="primary"
                            sx={{
                              textDecoration: 'underline',
                              color: 'var(--primary-color) !important',
                              cursor: 'pointer',
                              fontSize: '14px',
                              mt: 1,
                            }}
                          >
                            <a style={{ color: 'var(--primary-color) !important' }} href={news[newsTab][newsTabTab].url} target="_blank" rel="noreferrer">  Read More</a>
                          </Typography>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                {/* // Buttonat */}
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center',
                    },
                  }}
                >
                  <PrevNextButtons onClickPrev={() => changeNewsTabTab('prev')} onClickNext={() => changeNewsTabTab('next')} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Layout>
  );
}
