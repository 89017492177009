/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function Button({
  type,
  children,
  className,
  size = 'md',
  icon = null,
  bordered = false,
  onClick = () => { },
  color = false,
  rounded = false,
  disabled = false,
  style,
  title,
}) {
  switch (type) {
    case 'button':
      return (
        <button
          title={title}
          type="button"
          style={style}
          disabled={disabled ? 'disabled' : ''}
          className={clsx(
            'btn',
            size,
            bordered ? 'bordered' : 'default',
            rounded ? 'rounded' : 'normal',
            color,
            className,
            disabled ? 'disabled' : '',
          )}
          onClick={onClick}
        >
          {!!icon && <div className="icon-cont">{icon}</div>}

          {children}
        </button>
      );
    case 'submit':
      return (
        <button
          type="submit"
          style={style}
          className={clsx(
            'btn',
            size,
            bordered ? 'bordered' : 'default',
            rounded ? 'rounded' : 'normal',
            color,
            className,
            disabled ? 'disabled' : '',
          )}
          onClick={onClick}
        >
          {!!icon && <div className="icon-cont">{icon}</div>}

          {children}
        </button>
      );
    default:
      return (
        <div
          style={style}
          className={clsx(
            'btn',
            size,
            bordered ? 'bordered' : 'default',
            rounded ? 'rounded' : 'normal',
            color,
            className,
            disabled ? 'disabled' : '',
          )}
          onClick={onClick}
        >
          {!!icon && <div className="icon-cont">{icon}</div>}

          {children}
        </div>
      );
  }
}

Button.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.any,
  size: PropTypes.oneOf(['md', 'sm', 'lg']),
  bordered: PropTypes.bool,
  onClick: PropTypes.any,
};
