import { Grid } from '@mui/material';
import RanksCard from './components/card';
import useRankss from './hook';
import PackageModal from './components/modal';
import Button from '../../../components/Button';
import AppLoader from '../../../components/AppLoader';

export default function Rankss() {
  const {
    submit,
    errors,
    loadingRankss,
    onRanksClick,
    selectedRanks,
    handleModalClose,
    message,
    formLoading,
    ranks,
    formConfig,
  } = useRankss();

  if (loadingRankss) {
    return <AppLoader />;
  }

  return (
    <>
      <Grid container columnSpacing={3} sx={{ width: '100%' }}>
        <Grid
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}
          item
        >
          <Button
            size="sm"
            onClick={() => {
              onRanksClick();
            }}
          >
            Add +
          </Button>
        </Grid>
        {ranks?.map((item) => (
          <Grid key={`pranks${item.id}`} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <RanksCard onRanksClick={onRanksClick} data={item} />
          </Grid>
        ))}
      </Grid>
      {
        selectedRanks ? (
          <PackageModal
            errors={errors}
            submit={submit}
            data={selectedRanks}
            handleModalClose={handleModalClose}
            message={message}
            formLoading={formLoading}
            formConfig={formConfig}
          />
        ) : ''
      }
      
    </>
  );
}
