export const teamVolumes = {
  Ambassador: 0,
  Founder: 400,
  'VIP Founder': 1000,
  'Elite Founder': 25000,
  '1 Star Founder': 50000,
  '2 Star Founder': 100000,
  '3 Star Founder': 150000,
  '4 Star Founder': 200000,
  '5 Star Founder': 300000,
  Director: 500000,
  'Gold Director': 750000,
  'Platinum Director': 1000000,
  'Diamond Director': 2000000,
  'Presidential Director': 3000000,
};
export const nextTeam = {
  Founder: 'VIP Founder',
  'VIP Founder': 'Elite Founder',
  'Elite Founder': '1 Star Founder',
  '1 Star Founder': '2 Star Founder',
  '2 Star Founder': '3 Star Founder',
  '3 Star Founder': '4 Star Founder',
  '4 Star Founder': '5 Star Founder',
  '5 Star Founder': 'Director',
  Director: 'Gold Director',
  'Gold Director': 'Platinum Director',
  'Platinum Director': 'Diamond Director',
  'Diamond Director': 'Presidential Director',
  'Presidential Director': 'Presidential Director',
};

export default null;

export const onlyTeams = {
  Ambassador: 0,
  Founder: 400,
  Director: 500000,
 
};

export const teams = {
  team1: '0',
  team2: '400',
  team3: '500000',
  team4: '3000000',
};
