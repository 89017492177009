import { Grid, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Icon from '../../components/Icon';

export default {};

export const colConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const affiliateColumnsConfig = (t) => [
  {
    field: 'description',
    minWidth: 930,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `$${value}`,
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
];

export const eventsDummy = [
  {
    id: 0,
    date: '27 Feb 2024',
    title: 'Grand Opening at Affiliate World Conference Dubai!',
    description: 'Join us on center stage for our grand opening event taking place February 28 and 29 in Dubai!',
  },

];

export const rankRowsDummy = [
  {
    id: 0,
    rank: '',
    name: '',
  },
  {
    id: 1,
    rank: '',
    name: '',
  },
];

export const salesColumnsConfig = (t) => [
  {
    field: 'displayName',
    headerName: 'Name',
    flex: 0.3,
    minWidth: 380,
    ...colConfig,
    renderCell: ({ row }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography xs={12}>
            {row.displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ color: '#919191' }}>
            {row.username}
            {' '}
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'isAffiliate',
    headerName: t('Ambassador'),
    flex: 0.1,
    minWidth: 100,
    ...colConfig,
    renderCell: ({ value }) => (
      <Grid item xs={12}>
        {value ? (
          <div>
            <Icon height={20} width={20} name="Checkmark" />
          </div>
        ) : (
          <div>
            <Icon height={20} width={20} name="UserX" />
          </div>
        )}
      </Grid>
    ),
  },
  {
    field: 'isProduct',
    headerName: t('Gamer'),
    flex: 0.1,
    minWidth: 100,
    ...colConfig,
    renderCell: ({ value }) => (
      <Grid item xs={12}>
        {value ? (
          <div>
            <Icon height={20} width={20} name="Checkmark" />
          </div>
        ) : (
          <div>
            <Icon height={20} width={20} name="UserX" />
          </div>
        )}
      </Grid>
    ),
  },
  {
    field: 'dateJoined',
    headerName: t('Signup Date'),
    flex: 0.15,
    minWidth: 170,
    ...colConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
  {
    field: 'dateExpiring',
    headerName: t('Expiration Date'),
    flex: 0.15,
    minWidth: 170,
    ...colConfig,
    renderCell: ({ value, row }) => {
      const daysDiff = dayjs(dayjs(value)).diff(new Date(), 'days');

      let expirationColor = 'var(--text-color)';

      expirationColor = daysDiff < 4 ? 'var(--warning)' : expirationColor;
      expirationColor = daysDiff < 1 ? 'var(--toastify-icon-color-error)' : expirationColor;

      if (row.isAffiliate && !row.isProduct) {
        return (
          <div>
            <Typography> -</Typography>
          </div>
        );
      }

      return (
        <span style={{ color: expirationColor }} data-days={daysDiff}>
          {`${dayjs(value).format('DD MMM YYYY')}`}
        </span>
      );
    },
  },
  {
    field: 'icons',
    flex: 0.15,
    headerName: '',
    align: 'right',
    columnAlign: 'right',
    type: 'actions',
    headerAlign: 'right',
    minWidth: 180,
    renderCell: (_data) => {
      const active = dayjs(_data.row.dateExpiring).diff(new Date(), 'days') > 0;
      return (
        <div className="flex justify-between items-center ">
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              _data.row.training ? (
                <Tooltip title={t('Training: A User that is one of the first 2 users brought in by their sponsor')}>
                  <div>
                    <Icon height={20} width={20} name="UserConfig" />
                  </div>
                </Tooltip>
              ) : ''
            }
          </div>
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              !active ? (
                <Tooltip id="tooltup" title={t('Inactive: A Gamer that has not paid their monthly or yearly gamer Pack fee')}>
                  <div>
                    <Icon style={{ marginBottom: '3px' }} height={17} width={17} name="UserX" />
                  </div>
                </Tooltip>
              ) : ''
            }
          </div>
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              _data.row.qualified ? '' : (
                <Tooltip title={t('Inactive: A User that has not yet signed 3 Active Qualified Ambassadors')}>
                  <div>
                    <Icon height={25} width={25} name="UserLupa" />
                  </div>
                </Tooltip>
              )
            }
          </div>
        </div>
      );
    },
  },
];

export const salesRowsDummy = [

];

export const rankColumnsConfig = (t) => [
  {
    field: 'name',
    headerName: t('NAME'),
    flex: 0.5,
    ...defaultConfig,

  },
  {
    field: 'rank',
    headerName: '',
    headerAlign: 'right',
    flex: 0.5,
    columnAlign: 'right',
    renderCell: ({ value }) => (
      <Typography sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        {value}
      </Typography>
    ),
  },

];

export const rankProgressDummy = [
  {
    id: 0,
    type: 'PV',
    value: '0',
    valueNext: '450',
    valuePrev: '0',

  },
  {
    id: 1,
    type: 'TV',
    value: '0',
    valueNext: '0',
    valuePrev: '0',

  },
];

export const newsTabs = [

  {
    id: 0,
    name: 'Crypto News',
  },
  {
    id: 1,
    name: 'Tech News',
  },
  {
    id: 2,
    name: 'Real Estate News',
  },

];

export const cryptoSpanOptions = [
  {
    label: '24H', value: '24H',
  },
  {
    label: '1W', value: '1W',
  },
  {
    label: '1M', value: '1M',
  },
  {
    label: '1Y', value: '1Y',
  },
];

export const dummyNews = {
  0: [
    {
      id: 0,
      title: 'Crypto News 1',
      description: 'Crypto Description',
      url: '',
    },
    {
      id: 1,
      title: 'Crypto News 2',
      description: 'Crypto Description',
      url: '',

    },
    {
      id: 2,
      title: 'Crypto News 3',
      description: 'Crypto Description',
      url: '',

    },
  ],
  1: [
    {
      id: 0,
      title: 'Tech News 1',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 1,
      title: 'Tech News 2',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 2,
      title: 'Tech News 3',
      description: 'Tech Description',
      url: '',

    },
  ],
  2: [
    {
      id: 0,
      title: 'Real Estate News 1',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 1,
      title: 'Real Estate News 2',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 2,
      title: 'Real Estate News 3',
      description: 'Real Estate Description',
      url: '',

    },
  ],
};

export const aiExperts = [

  {
    id: 0,
    name: 'Matt the Tech Guy',
    descriptiom: 'Matt has been carefully trained by the TREC Global team to answer questions about foundational and emerging strategies for investing in Technology opportunities.',
    placeholder: 'What is momentum trading?',
  },
  {
    id: 1,
    name: 'Ms. Jones',
    descriptiom: 'Ms. Jones was trained by Real Estate Millionaires to answer questions on strategy for residential, commercial and industrial trends and insights.',
    placeholder: 'What is wholesaling?',
  },
  {
    id: 2,
    name: 'BTCharles',
    descriptiom: 'BTCharles (chuck for short) is trained as an expert on terminology, current trends, technology behind each coin, and current pricing.',
    placeholder: 'What is swing trading?',
  },

];
