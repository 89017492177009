import { createContext } from 'react';
import useRedeemToken from './hook';

export const RedeemTokenContext = createContext({
  t: () => {},
  user: {},

  loadedGamesData: false,
  gamesData: [],

  loadedWalletData: false,
  walletData: [],
  
  fetchRedeemData: () => {},
});

export const RedeemTokenProvider = ({ children }) => {
  const value = useRedeemToken();
  return <RedeemTokenContext.Provider value={value}>{ children }</RedeemTokenContext.Provider>;
};
