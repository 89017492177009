import { Typography, Grid } from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';

export default function MemecoinVideo() {
  const {
    t,

  } = useContext(Context);

  return (
    <Layout sx={{ maxHeight: '100%' }} maxWidth="100%">
      <Grid container>

        {/* // ## HEADER ## */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

          <Grid container rowSpacing={1}>
            <Grid item>
              <Typography sx={{
                fontSize: '26px',
                fontWeight: 600,
                alignItems: 'center',
                justifyContent: {
                  xl: 'start', lg: 'start', md: 'center', sm: 'start', xs: 'center',
                },
                display: 'flex',
                pb: 2,
              }}
              >
                {t('Memecoin Video')}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

        {/* // ## CONTENT ## // */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container rowSpacing={1}>
            <div
              className="video-responsive"
              style={{
                overflow: 'hidden',
                paddingBottom: '56.25%',
                position: 'relative',
                width: '100%',
              }}
            >
              <iframe
                width="853"
                height="480"
                src="https://www.youtube.com/embed/-vZ6Ve7SQZQ?si=LcUqYMVdQJRWn-LC"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Memecoin Video"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          </Grid>
        </Grid>

      </Grid>
    </Layout>
  );
}
