import useGameHistoryApi from './api';

const useGameHistory = () => {
  const {
    t,
    user,
    loadedData,
    gamesData,
    getPaginatedData,
    paginationData,
    tokens,
  } = useGameHistoryApi();

  return {
    t,
    user,
    loadedData,
    gamesData,
    getPaginatedData,
    paginationData,
    tokens,
  };
};

export default useGameHistory;
