/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';

export default function Tag({
  color, children, size = 'md', user, onClick = () => { }, current = false,
}) {
  return (
    <div onClick={() => onClick(user)} className={clsx('tag', ' cursor-pointer', color, size, current ? 'current' : '')}>
      {children}
    </div>
  );
}
