import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useState } from 'react';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import WebinarForm from './components/WebinarForm';

const GamificationWebinarPage = () => {
  const navigate = useNavigate();
  const [openWebinarModal, setOpenWebinarModal] = useState(false);

  const onSignIn = () => {
    navigate('/signin');
  };

  const handleCloseModal = () => {
    setOpenWebinarModal(false);
  };

  return (
    <div className="gamification-webinar">
      <div className="nav-container">
        <div className="img-container">
          <img src="/assets/MemeGames-logo-white.png" alt="memegames-logo" />
        </div>

        <div className="button-container flex-1 flex">
          <Button
            onClick={onSignIn}
            bordered
            icon={<Icon name="UserIcon" width={17} height={17} />}
            size="sm"
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '500',
                alignSelf: 'center',
              }}
            >
              Login
            </Typography>
          </Button>
        </div>
      </div>

      <div className="section-container">
        <div className="text-container">
          <Typography className="subtitle">MEMEGAMES.AI</Typography>

          <Typography className="title">
            Affiliate Marketing Tech Gamification Webinar
          </Typography>

          <Typography className="content">
            {`Welcome to the registration for the tech
              gamification webinar. Please enter your contact
              info to be directed to the Zoom webinar at
              7pm EST Thursday, August 8th`}
          </Typography>

          <Button size="sm" style={{ width: 'fit-content' }} onClick={() => { setOpenWebinarModal(true); }}>
            <Typography sx={{ mx: '16px' }}>Interested?</Typography>
          </Button>
          <Modal width="550px" title="Tech Gamification Webinar" open={openWebinarModal} handleClose={handleCloseModal}>
            <WebinarForm handleCloseModal={handleCloseModal} />
          </Modal>
        </div>

        <div className="image-container">
          <img src="/images/smile.png" alt="smile" />
          <div className="img-shadow" />
        </div>
      </div>

      <footer className="footer-container flex flex-column">
        <div className="flex justify-center">
          <img src="/images/white-logo.png" alt="white-logo" />
        </div>
        <div className="flex justify-center">
          <span>
            ©
            {new Date().getFullYear()}
            {' '}
            MemeGames.ai. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default GamificationWebinarPage;
