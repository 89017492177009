import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { columnsConfig } from './config';
import Table from '../../../../components/Table';
import { PAGE_SIZE } from '../../api';
import Select from '../../../../components/Inputs/Select';
import TableMobile from '../../../../components/Table/TableMobile';
import TablePagination from '../../../../components/Table/Pagination';
import MembershipsTab from '../MembershipsTab';

export default function Transfers({
  data = [],
  loading,
  fetchTransfers,
  transferesPage,
  transferesTotal,
  setTransfersLoading,
  tabsHistoryValue,
  handleTabHistoryChange = () => { },
}) {
  const { t } = useTranslation();
  const columns = useMemo(() => columnsConfig(t), [t]);
  const totalPages = useMemo(() => Math.ceil(transferesTotal / PAGE_SIZE), [transferesTotal]);
  const [filters, setFilters] = useState({ page: transferesPage });

  const handleChange = (selectedFilter) => {
    setTransfersLoading(true);
    if (selectedFilter !== 'all') {
      setFilters({ ...filters, direction: selectedFilter });
      fetchTransfers({ page: 1, direction: selectedFilter });
    } else {
      setFilters({ ...filters, direction: selectedFilter });
      fetchTransfers({ page: 1 });
    }
    setTransfersLoading(false);
  };

  const tabsConfig = [
    {
      label: 'Transfer',
      key: 'transfer',
      isActive: tabsHistoryValue === 0,
      onClick: () => handleTabHistoryChange(null, 0),
    },
    {
      label: 'Purchases',
      key: 'purchases',
      isActive: tabsHistoryValue === 1,
      onClick: () => handleTabHistoryChange(null, 1),
    },
  ];

  return (
    <>
      <div className="flex packs-filters">
        <div className="flex">
          <Select
            className="transfers-filter-select"
            placeholder="Direction"
            size="sm"
            value={filters?.direction || 'all'}
            onChange={(e) => handleChange(e)}
            options={[
              { value: 'all', label: 'All' },
              { value: 'sent', label: 'Sent' },
              { value: 'received', label: 'Received' },
            ]}
          />
        </div>

        <div className="flex flex-1 justify-center">
          <div className="flex flex-column justify-center">
            <MembershipsTab config={tabsConfig} />
          </div>
        </div>

        <div className="desktop">
          <TablePagination
            count={totalPages}
            page={transferesPage}
            onChange={(val, page) => {
              fetchTransfers({ ...filters, page });
            }}
          />
        </div>
      </div>

      <div className="table-cont">
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="desktop">
              <Table loading={loading} columns={columns} rows={data} className="table mb-4" />
            </div>
            <div className="mobile">
              <TableMobile columns={columns} data={data} className="mb-6" />
              <TablePagination
                isMobile
                count={totalPages}
                page={transferesPage}
                onChange={(val, page) => {
                  fetchTransfers({ ...filters, page });
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
