import { Typography } from '@mui/material';
import GameMatch from '../../../components/GameMatch';

const GamesGroup = ({ date, games, userTokens }) => (
  <div className="game-group">
    <div className="game-group-header">
      <Typography sx={{
        fontSize: { xs: '16px' }, fontWeight: 'bold', display: 'flex', justifyContent: 'center',
      }}
      >
        {date}
      </Typography>
    </div>
    <div className="game-group-games">
      {games.map((game, idx) => (
        <GameMatch game={game} key={idx} userTokens={userTokens} />
      ))}
    </div>
  </div>
);

export default GamesGroup;
