/* eslint-disable max-len */
import { useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import Button from '../../../components/Button';

export default function ConfirmationModal() {
  const {
    onModalClose, t, modalData, onActionConfirm,
  } = useContext(Context);

  if (modalData?.confirm) {
    return (
      <Modal width="550px" title={modalData.confirmTitle} open={modalData?.confirm} handleClose={onModalClose}>
        <Container>
          <Grid
            container
            columnSpacing={2}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Typography sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
                {modalData.confirmDescription}
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <Button onClick={() => onModalClose()} bordered>
                {t('Cancel')}
              </Button>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <Button onClick={() => onActionConfirm()}>
                {t('Submit')}
              </Button>
            </Grid>
          </Grid>

        </Container>
      </Modal>
    );
  }
}
