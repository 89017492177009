export const memeModalConfig = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'image',
    label: 'Image',
    type: 'text',
  },
  {
    name: 'apiId',
    label: 'API ID',
    type: 'text',
  },
  {
    name: 'symbol',
    label: 'Symbol',
    type: 'text',
  },

];

export default null;
