/* eslint-disable max-len */
import { Grid } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Provider } from './context';
import WalletManagementAmbassador from './components/walletManagementAmbassador';
import WalletManagementGamer from './components/walletManagementGamer';

import TransactionHistory from './components/transactionHistory';
import TransferModal from './components/transferModal';
import WithdrawModal from './components/withdrawModal';
import ConfirmationModal from './components/confirmationModal';
import CommitmentModal from './components/commitmentModal';
import { useAuthentication } from '../../utils/store';
import TransferHistory from './components/transferHistory';

function WalletsAmbassador({ isGamer }) {
  return (
    <Grid container spacing={2}>

      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        {/* <Banner data={{ message: 'Commissions are calculating for rank up qualified on 7/3/24, commissions will be generated 7/4/24, and will be posted by 7/5/24.' }} /> */}

        <WalletManagementAmbassador isGamer={isGamer} />
        <TransferModal />
        <WithdrawModal />
        <ConfirmationModal />
        <CommitmentModal />
      </Grid>
      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        <TransactionHistory />
      </Grid>
    </Grid>
  );
}

function WalletsGamer({ isGamer }) {
  return (
    <Grid container spacing={2}>

      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        {/* <Banner data={{ message: 'Commissions are calculating for rank up qualified on 7/3/24, commissions will be generated 7/4/24, and will be posted by 7/5/24.' }} /> */}
        <WalletManagementGamer isGamer={isGamer} />
        <TransferModal />
        <WithdrawModal />
        <ConfirmationModal />
        <CommitmentModal />
      </Grid>
      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        <TransferHistory />
      </Grid>
    </Grid>
  );
}

export function WalletRedirect() {
  const { user } = useAuthentication();

  if (user.isAffiliate) {
    return <Navigate to="/ambassador-wallets" />;
  }

  if (user.isProduct) {
    return <Navigate to="/gamer-wallets" />;
  }

  return <Navigate to="/" />;
}

export default ({ isGamer }) => (
  <Provider isGamer={isGamer}>
    {
      isGamer ? <WalletsGamer isGamer={isGamer} /> : <WalletsAmbassador isGamer={isGamer} />
    }
  </Provider>
);
