import { useCallback } from 'react';
import useAuthApi from '../../../utils/api';

export default function useMemeGameApi() {
  const {
    authGet, authPut, authPost, authDelete, 
  } = useAuthApi();

  // TODO: add endpoint, pagination?
  const getMemeGames = useCallback((start, end) => authPost('schedule-game/get-by-range', { data: { startDate: start, endDate: end } }), [authGet]);

  // TODO: add endpoint
  const createMemeGame = useCallback((data) => authPost('/schedule-game', { data }), [authPost]);

  // TODO: add endpoint
  const updateMemeGame = useCallback((data) => authPut('/schedule-game', { data }), [authPut]);
  
  const deleteMemeGame = useCallback((data) => authDelete(`/schedule-game/${data.id}`), [authPut]);

  return {
    getMemeGames,
    createMemeGame,
    updateMemeGame,
    deleteMemeGame,
  };
}
