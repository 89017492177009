const config = {
  viewBox: '0 0 25 26',
  children: (
    <>
      <g clipPath="url(#clip0_689_346)">
        <path d="M9.18248 4.16608C9.65053 3.94611 10.1397 3.7655 10.6471 3.62635L10.867 1.20878C12.3162 0.926968 13.8096 0.926266 15.2594 1.20808L15.4801 3.62564C16.2243 3.82945 16.9313 4.12392 17.587 4.4985L19.4536 2.94324C20.0292 3.33188 20.5788 3.77744 21.0918 4.28274C21.6498 4.83162 22.1389 5.42336 22.5606 6.04813L21.0054 7.91541C21.3792 8.57111 21.6744 9.2774 21.8782 10.0216L24.2972 10.2416C24.579 11.6908 24.5797 13.1835 24.2979 14.634L21.8782 14.8547C21.6744 15.5989 21.3799 16.3052 21.0061 16.9609L22.562 18.8282C21.77 20.0025 20.7278 20.9646 19.5569 21.782L17.6643 20.2569C17.015 20.6414 16.3136 20.9478 15.5729 21.1628L15.3915 23.5853C13.9466 23.8903 12.4532 23.9142 10.9999 23.6556L10.7405 21.2408M5.51818 16.9799L5.51326 24.059C5.51186 25.7196 8.55209 25.7014 8.53522 24.0337L8.47829 20.543L8.40661 11.7709M8.48813 16.0838C8.91191 17.2596 9.54863 17.4072 9.9963 17.1387C10.7342 16.696 10.4791 15.7676 10.4468 15.0346L10.217 9.84103C10.1355 7.99483 1.12651 8.13257 0.834857 9.82276C0.595912 10.8692 0.562178 12.908 0.535473 14.7113C0.522823 15.5905 0.212194 17.3924 1.48493 17.2526C1.85178 17.2273 2.25236 16.9075 2.54894 16.0845M2.63046 11.7716L2.55878 20.5437L2.50185 24.0344C2.48498 25.7021 5.51397 25.7267 5.51256 24.0597M11.1594 16.9721C13.5278 17.9757 16.2609 16.8702 17.2652 14.5019C17.6229 13.6585 17.7121 12.7695 17.573 11.9276M11.1692 8.34411C13.5376 7.34054 16.2707 8.44601 17.275 10.8144C17.6327 11.6577 17.722 12.5467 17.5828 13.3887M5.44931 2.27068C6.67004 2.27068 7.66026 3.31502 7.66026 4.60321C7.66026 5.47325 7.20837 6.23226 6.53862 6.63354C6.20972 6.83735 5.82741 6.95471 5.41839 6.95471C4.19766 6.95471 3.20744 5.91038 3.20744 4.62219C3.20744 3.75214 3.65933 2.99314 4.32838 2.59185C4.65728 2.38735 5.04029 2.27068 5.44931 2.27068Z" stroke="#FFF493" strokeWidth="1.19048" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_689_346">
          <rect width="25" height="25.2839" fill="white" transform="translate(0 0.505859)" />
        </clipPath>
      </defs>
    </>
  ),
};
  
export default config;
