import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '../../../components/Button';

export default function PrevNextButtons({
  disabledPrev, disabledNext, onClickPrev = () => {}, onClickNext = () => {}, style = {},
}) {
  return (
    <div style={style} className="flex gap-1">
      <Button
        tooltip="Previous"
        disabled={disabledPrev}
        onClick={() => { onClickPrev(); }}
        bordered
        size="sm"
        type="button"
      >
        <ArrowBackIosIcon sx={{
          fontSize: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', 
        }}
        />
      </Button>
      <Button
        tooltip="Next"
        disabled={disabledNext}
        onClick={() => { onClickNext(); }}
        bordered
        size="sm"
        type="button"
      >
        <ArrowForwardIosIcon sx={{
          fontSize: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', 
        }}
        />
      </Button>
    </div>
  );
}
