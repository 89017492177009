import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button from '../Button';
import Modal from '../Modal';
import { renderNumber } from '../../utils/string';
import useApi from '../../utils/api';
import getGeckoData from '../../utils/api/getGeckoData';
import { useAuthentication } from '../../utils/store';

const RedeemButton = ({
  totalTokens, gameId, coin, buttonSize = 'sm', bordered = false, buttonText = null,
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [totalReward, setTotalReward] = useState(0);
  const [timer, setTimer] = useState(null);
  const { authPost, authGet } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthentication();
  const [solanaWallet, setSolanaWallet] = useState(null);

  const minTokens = Number(process.env.REACT_APP_REDEMPTION_MIN_TOKENS || 2000);
  const mgTokenPrice = Number(process.env.REACT_APP_MG_TOKEN_PRICE || 0.1);

  const getTotalPurchasingCoins = useCallback(async () => {
    const coinInfo = await getGeckoData(coin?.apiId);
    
    let price = Number(coinInfo?.market_data?.current_price?.usd);
    
    if (price < 1) {
      price = Number(coinInfo?.market_data?.current_price?.usd).toFixed(16);
    } else {
      price = renderNumber(coinInfo?.market_data?.current_price?.usd);
    }

    const total = Math.ceil(minTokens * (mgTokenPrice / price));

    setTotalReward(total);
  }, [coin]);

  const getSolanaWallet = useCallback(async () => {
    const _data = await authGet('/wallets');
    const _solana = _data?.find((i) => i.type === 'solana');
    setSolanaWallet(_solana || null);
  }, [authGet]);

  const handleOpenRedeemModal = () => {
    const interval = setInterval(() => {
      if (!processing) {
        getTotalPurchasingCoins();
      }
    }, 60000);
    setTimer(interval);
    setOpenRedeemModal(true);
    getSolanaWallet();
  };

  const handleCloseRedeemModal = () => {
    clearInterval(timer);
    setTimer(null);
    setOpenRedeemModal(false);
  };

  const handleOnClick = async () => {
    if (totalTokens >= minTokens) {
      const redeemUrl = '/redeem-token';
      if (location.pathname === redeemUrl) {
        handleOpenRedeemModal();
        setProcessing(true);

        getTotalPurchasingCoins();

        setProcessing(false);
      } else {
        navigate(redeemUrl, { state: { openInstructions: true } });
      }
    } else {
      setOpenMessageModal(true);
    }
  };

  const handleRedeemOnClick = async () => {
    if (!processing) {
      setProcessing(true);

      const redeemData = {
        tokenAmount: minTokens,
        memeCoinId: coin?.id,
        scheduleGameId: gameId,
        solanaWalletKey: solanaWallet?.publicKey,
      };

      try {
        await authPost('/redemptions/redeem-tokens', { data: redeemData });

        navigate('/redeem-token', { state: { reloadWallet: true }, replace: true });

        toast.success(t(`Thank you! You have redeemed ${renderNumber(minTokens)} tokens for ${renderNumber(totalReward)} ${coin?.name}. Your coins will be delivered to your Solana Wallet within 5 business days.`));
      } catch (err) {
        console.log({ err });
        toast.error(t(err));
      }

      setProcessing(false);

      handleCloseRedeemModal();
    }
  };

  const handleGoToWalletsButton = () => {
    const walletUrl = user.isAffiliate ? '/ambassador-wallets' : '/gamer-wallets';

    navigate(walletUrl);
  };
  
  useEffect(() => {
    if (location.state?.openInstructions) {
      location.state.openInstructions = false;
      setOpenInstructionsModal(true);
    }
  });

  return (
    <>
      <Button
        onClick={handleOnClick}
        size={buttonSize}
        bordered={bordered}
      >
        <span className="no-wrap">{buttonText ? t(buttonText) : t('Redeem')}</span>
      </Button>
      <Modal
        title={t('Redeem Tokens')}
        width="550px"
        open={openInstructionsModal}
        handleClose={() => { setOpenInstructionsModal(false); }}
      >
        <p className="mb-4 text-center">Choose a Meme Coin from any of your winning games to receive $200 USD in those tokens at the time of redemption to your attached Solana Wallet!</p>
      </Modal>
      <Modal
        title={t('Not enough Tokens')}
        width="550px"
        open={openMessageModal}
        handleClose={() => { setOpenMessageModal(false); }}
      >
        <p className="mb-4 text-center">{`Minimum redemption is ${renderNumber(minTokens)} Tokens - keep playing!`}</p>
      </Modal>
      <Modal
        title={t('Redeem Tokens')}
        width="550px"
        open={openRedeemModal}
        handleClose={() => { handleCloseRedeemModal(); }}
      >
        {processing && (
          <div
            className="flex justify-center"
            style={{
              position: 'absolute', width: '100%', left: 0, top: '50px',
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div className="redeem-coin text-center mb-4">
          <div className="redeem-coin-name">
            <strong>{coin?.name}</strong>
          </div>
          <div className="redeem-coin-image">
            <img src={coin?.image} height={90} width={90} alt={coin?.name} />
          </div>
          <div className="redeem-coin-reward">
            <strong>Total Reward:</strong>
            <span>{` ${renderNumber(totalReward)} ${coin?.name}`}</span>
          </div>
        </div>
        <div className="divider" />
        {(totalTokens > minTokens) ? (
          <p className="mb-4 text-center">{`Redeem ${renderNumber(minTokens)} from your ${renderNumber(totalTokens)} Tokens`}</p>
        ) : (
          <p className="mb-4 text-center">{`Redeem your ${renderNumber(totalTokens)} Tokens`}</p>
        )}
        {solanaWallet ? (
          <Button onClick={handleRedeemOnClick} disabled={processing}>Redeem</Button>
        ) : (
          <>
            <Button onClick={handleGoToWalletsButton} bordered>Connect Wallet</Button>
            <Typography textAlign="center" marginTop="8px" fontSize="14px">You need to connect your Solana Wallet to the system</Typography>
          </>
        )}
      </Modal>
    </>
  );
};

export default RedeemButton;
