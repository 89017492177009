import { Typography } from '@mui/material';
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFromSession } from '../../../utils/session';
import AuthLayout from '../../../components/AuthLayout';

export default function UserExists() {
  const registrationData = useMemo(() => getFromSession('registration'), []);
  const paidSignupUser = useMemo(() => getFromSession('paidSignupUser'), []);
  const navigate = useNavigate();

  useEffect(() => {
    if (!registrationData && !paidSignupUser) {
      navigate('/');
    }
  }, []);

  return (
    <AuthLayout>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '18px',
          whiteSpace: 'nowrap',
        }}
        color="white"
      >
        User
        {'  '}
        <span
          style={{
            color: 'var(--primary-color)',
            marginLeft: '3px',
            marginRight: '3px',
            whiteSpace: 'nowrap',
          }}
        >
          {registrationData
            ? `  ${registrationData?.givenName} ${registrationData?.familyName}  `
            : ' '}
        </span>
        {'  '}
        already exists!
      </Typography>
      <Typography sx={{ display: 'flex', justifyContent: 'center', my: 3 }} color="primary">
        THANK YOU!
      </Typography>
    </AuthLayout>
  );
}
