const config = {
  viewBox: '0 0 17 16',
  children: (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g opacity="1">
        <path d="M9 15L2 8L9 1" stroke="currentColor" strokeWidth="2" />
        <path d="M16 15L9 8L16 1" stroke="currentColor" strokeWidth="2" />
      </g>
    </svg>
  ),
};

export default config;
