// Keys that will be saved in storage manager
export const STORAGE_MANAGER_KEYS = {
  TOKEN: 'token',
  THEME: 'theme',
  CONFIG: 'config',
  USER: 'user',
};

/**
 * Function that stores data in async storage
 * @param   {String} key    Key of the value that will be stored
 * @param   {String} value  Value that will be stored
 * @returns {Boolean}       True if saved successfuly, false otherwise
 */
export const storeData = async (key = '', value = '') => {
  localStorage.setItem(key, value);
};

/**
 * Function that gets data from async storage
 * @param   {String} key  Key of the value
 * @returns {String}      Value that is retrieved from storage manager
 */
export const retrieveData = async (key = '') => localStorage.getItem(key);

export const getItem = async (key = '') => localStorage.getItem(key);

/**
 * Function that removes data from async storage
 * @param {String} key  Key of the value that will be removed
 */
export const removeItem = async (key = '') => localStorage.removeItem(key);

/**
 * Function that retrieves token from async storage
 * @returns {String}  Value that is retrieved from storage manager
 */
export const getToken = () => retrieveData(STORAGE_MANAGER_KEYS.TOKEN);
export const getUser = () => retrieveData(STORAGE_MANAGER_KEYS.USER);
export const getUserObject = async () => {
  try {
    return JSON.parse(await getItem(STORAGE_MANAGER_KEYS.USER));
  } catch (err) {
    console.log(err);
  }
  return null;
};

/**
 * Function that removes token from async storage
 */
export const removeToken = () => removeItem(STORAGE_MANAGER_KEYS.TOKEN);
export const removeUser = () => removeItem(STORAGE_MANAGER_KEYS.USER);

/**
 * Function that stores token in async storage
 * @param   {String} token  Value of token
 * @returns {Boolean}       True if saved successfuly, false otherwise
 */
export const storeToken = (token = '') => storeData(STORAGE_MANAGER_KEYS.TOKEN, token);
export const storeUser = (user = '') => storeData(STORAGE_MANAGER_KEYS.USER, user);

/**
 * Async Storage Hook
 */
export function useStorageManager() {
  return {
    getToken,
    storeData,
    removeItem,
    storeToken,
    removeToken,
    retrieveData,
  };
}
