import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useApi from '../../../utils/api';

export default function AjaxRadioGroup({
  name = '',
  value = '',
  focused = false,
  placeholder = '',
  onChange = () => { },
  size = 'md',
  endPoint = '/',
  mapper = (val) => val,
  disabled = false,
  error,
  setErrors,
}) {
  const { authGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const _fetch = async () => {
      try {
        setLoading(true);
        const res = await authGet(endPoint);

        if (Array.isArray(res)) {
          setOptions(res.map(mapper));
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    _fetch();
  }, []);

  return (
    <RadioGroup
      id={name}
      value={value}
      placeholder={placeholder}
      className={clsx('flex-1 select', focused && 'focused', size)}
      onChange={(e) => {
        onChange(e.target.value);
        setErrors(null);
      }}
      disabled={loading || disabled}
    >
      {error && <div className="form-input-error">{error}</div>}
      {options.map((o) => (
        <FormControlLabel label={o.label} control={<Radio />} value={o.value} key={o.value} />
      ))}
    </RadioGroup>
  );
}

AjaxRadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  mapper: PropTypes.func,
  onChange: PropTypes.func,
};
