import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PassTypeCard({ data, onRanksClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {data.name}
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onRanksClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>
      <Typography>
        Commission :
        <span className="pl-2">
          {Number(data?.commission || 0)}
          %
        </span>
      </Typography>
      <Typography>
        Match :
        <span className="pl-2">
          {data?.match}
          %
        </span>
      </Typography>
      <Typography>
        Minimal TV :
        <span className="pl-2">
          $
          {data?.minTv || 0}
        </span>
      </Typography>
      <Typography>
        Minimal PV :
        <span className="pl-2">
          $
          {data?.minPv || 0}
        </span>
      </Typography>

    </Layout>
  );
}
