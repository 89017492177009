import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material';
import Navigation from './navigation';
import AppLoader from './components/AppLoader';
import { useAuthentication, useGeneral, useStyle } from './utils/store';

function App() {
  const { muiTheme } = useStyle();
  const { configLoading } = useGeneral();
  const { authenticationLoading } = useAuthentication();

  if (configLoading || authenticationLoading) {
    return <AppLoader />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_FIREBASE_ID}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={muiTheme}>
          <Navigation />
        </ThemeProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
