import { Box, Typography } from '@mui/material';

export default function StagingBanner() {
  if (window.location.hostname === 'staging.memegames.ai') {
    return (
      <Box
        className="staging-banner"
        sx={{
          background: 'lawngreen', position: 'absolute', right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 99999999999,
        }}
      >
        <Typography sx={{
          fontSize: '14px', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', color: 'black !important',
        }}
        >
          Staging server
        </Typography>
      </Box>
    );
  }

  if (window.location.hostname === 'localhost') {
    return (
      <Box
        sx={{
          background: 'yellow', position: 'absolute', right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 99999999999,
        }}
      >
        <Typography
          className="local-banner"
          sx={{
            fontSize: '14px', fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', color: 'black !important',
          }}
        >
          Local Server
        </Typography>
      </Box>
    );
  }

  return ('');
}
