import { Grid, Typography, Alert } from '@mui/material';
import { useCallback, useEffect } from 'react';
import Layout from '../../../../../components/CardLayout';
import Table from '../../../../../components/Table';
import useViewMember from './hook';
import CustomAccordion from '../../../../../components/Accordion';
import Button from '../../../../../components/Button';
// import TextInput from '../../../../../components/Inputs/TextInput';
import Form from '../../../../../components/Form';
import { changePasswordForm, changeSponsorForm } from './config';
import renderModals from './components/modals';

export default function ViewUser() {
  const {
    accountInformationTableColumns,
    t,
    renewalHistoryHandles,
    setRenewalHistoryHandles,
    // wallets,
    changePassword,
    // appendWallet,
    actionButtonModal,
    setActionButtonModal,
    accountInformationTableRows,
    actionDataModal,
    setActionDataModal,
    history,
    data,
    errors,
    setErrors,
    passChangeAlertMessage,
    sponsorChangeAlertMessage,
    isLoading,
    clearCache,
    changeSponsor,
    suspend,
    suspendedText,
    setSuspendText,
    terminate,
    adjustMembershipDate,
    adjustMembershipDateConfirmed,
    onSimulate,
  } = useViewMember();

  useEffect(() => {
    setSuspendText(data.Suspended ? 'UNSUSPEND' : 'SUSPEND');
  }, [suspendedText, setSuspendText]);

  const onChangePass = useCallback(
    async (inputData) => {
      if (data) {
        const _data = { ...inputData, SID: data.SID, fullName: `${data.Name} ${data.Surname}` };
        changePassword(_data);
      }
    },
    [data, changePassword],
  );

  const onChangeSponsor = useCallback(
    async (inputData) => {
      if (data) {
        const _data = { ...inputData, SID: data.SID, fullName: `${data.Name} ${data.Surname}` };
        changeSponsor(_data);
      }
    },
    [data, changeSponsor],
  );

  return (
    <Grid columnSpacing={3} container className="members-view">
      {/* // Account Info Table and Accordions */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Layout maxWidth="100%" sx={{ mt: 4 }}>
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            {t('Member Info')}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              mb: 2,
            }}
          >
            {t('Account Information')}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Table
                columns={accountInformationTableColumns}
                rows={accountInformationTableRows}
                className="table"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              {history?.map((item, index) => (
                <CustomAccordion
                  key={`history-accordion-${item.id}`}
                  expanded={renewalHistoryHandles[index]}
                  toggleText={renewalHistoryHandles[index] ? 'HIDE HISTORY' : 'SHOW HISTORY'}
                  setExpanded={() => {
                    const tmp = { ...renewalHistoryHandles };
                    tmp[index] = !renewalHistoryHandles[index];
                    setRenewalHistoryHandles(tmp);
                  }}
                  summary={<span>{item.title}</span>}
                  details={item.details}
                />
              ))}
            </Grid>
          </Grid>
        </Layout>
      </Grid>

      {/* // Member Actions */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Layout maxWidth="100%">
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Typography sx={{ mb: 2 }}>{t('Member Actions')}</Typography>

              <div className="flex gap-1">
                <Button rounded size="sm" color="brown" onClick={onSimulate}>
                  <span>{t(`Simulate ${data?.Username}`)}</span>
                </Button>
                <Button
                  onClick={() => {
                    setActionButtonModal('adjust-membership-date');
                    setActionDataModal(0);
                  }}
                  rounded
                  size="sm"
                  color="dark-red"
                >
                  <span>{t('Adjust Membership Date')}</span>
                </Button>
                <Button
                  onClick={() => {
                    clearCache(data.SID);
                    setActionButtonModal('cache-flushed');
                  }}
                  rounded
                  size="sm"
                  color="purple"
                >
                  <span>{t('Flush Cache')}</span>
                </Button>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Typography sx={{ mb: 2 }}> Disciplinary Actions</Typography>
              <div className="flex gap-1">
                <Button
                  onClick={() => {
                    setActionButtonModal('suspend');
                    const _data = {
                      suspended: !data.Suspended,
                      SID: data.SID,
                      fullName: `${data.Name} ${data.Surname}`,
                    };
                    setActionDataModal(_data);
                    // if (!isLoading) {
                    //   setActionDataModal('');
                    // }
                  }}
                  size="sm"
                  disabled={!!isLoading}
                  bordered
                >
                  <span>{t(`${data.Suspended ? 'UNSUSPEND' : 'SUSPEND'}`)}</span>
                </Button>
                <Button
                  onClick={() => {
                    if (!data.Terminated) {
                      setActionButtonModal('terminate');
                    }
                  }}
                  size="sm"
                  bordered
                  disabled={data.Terminated}
                >
                  <span>{!data.Terminated ? t('TERMINATE') : t('Terminated')}</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Layout>
      </Grid>

      {/* // Wallet Management */}
      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Layout maxWidth="xl">
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              mb: 5,
            }}
          >
            {t('Wallet Management')}
          </Typography>
          <Grid container columnSpacing={5}>
            {wallets.map((item) => (
              <Grid
                key={`w${item.id}`}
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                className="view-table"
              >
                <div className="view-table-header">
                  <span className="view-table-name">{item.name}</span>
                  <span className="view-table-value">{item.value}</span>
                </div>
                <TextInput
                  value={item.value}
                  onChange={(e) => {
                    appendWallet(e, item);
                  }}
                  endAdornment={(
                    <Button className="py-auto my-auto mr-5" bordered size="sm">
                      <span className="no-wrap">APPEND</span>
                    </Button>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Layout>
      </Grid> */}

      {/* // Change Password for user */}
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Layout maxWidth="xl">
          {passChangeAlertMessage && (
            <Alert
              id="alert-message-profile"
              sx={{ mb: '15px' }}
              className={passChangeAlertMessage?.type}
              severity={passChangeAlertMessage?.type}
            >
              {t(passChangeAlertMessage?.label)}
            </Alert>
          )}
          <Typography sx={{ mb: 5, fontSize: '16px', fontWeight: 500 }}>
            {' '}
            Change Password for
            {' '}
            {data?.fullName || `"${data.Name} ${data.Surname}"`}
          </Typography>
          <Form
            config={changePasswordForm}
            onSubmit={onChangePass}
            errors={errors}
            setErrors={setErrors}
            submitLabel="Change Password"
            formLoading={isLoading}
            defaultFormValue={{ password: '', retypePassword: '' }}
            clearAfterSubmit
          />
        </Layout>
      </Grid>

      {/* // Change sponsor for admin */}
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Layout maxWidth="xl">
          {sponsorChangeAlertMessage && (
            <Alert
              id="alert-message-profile"
              sx={{ mb: '15px' }}
              className={sponsorChangeAlertMessage?.type}
              severity={sponsorChangeAlertMessage?.type}
            >
              {t(sponsorChangeAlertMessage?.label)}
            </Alert>
          )}
          <Typography sx={{ mb: 5, fontSize: '16px', fontWeight: 500 }}>
            {' '}
            Change Sponsor for
            {' '}
            {data?.fullName || `"${data.Name} ${data.Surname}"`}
          </Typography>
          <Form
            config={changeSponsorForm}
            onSubmit={onChangeSponsor}
            errors={errors}
            setErrors={setErrors}
            formLoading={isLoading}
            submitLabel="Change Sponsor"
          />
        </Layout>
      </Grid>

      {renderModals({
        type: actionButtonModal,
        open: !!actionButtonModal,
        onModalClose: () => {
          setActionButtonModal(false);
        },
        data: actionDataModal,
        setData: setActionDataModal,
        onSuspend: suspend,
        setSuspendText,
        terminate,
        adjustMembershipDate,
        adjustMembershipDateConfirmed,
        t,
      })}
    </Grid>
  );
}
