import { DataGrid } from '@mui/x-data-grid';

export default function Table({
  rows,
  columns,
  checkboxSelection = false,
  disableColumnFilter = true,
  disableColumnMenu = true,
  disableColumnSelector = true,
  disableDensitySelector = true,
  disableRowSelectionOnClick = true,
  hideFooterPagination = true,
  hideFooterSelectedRowCount = true,
  onColumnHeaderClick = () => false,
  onColumnHeaderOver = () => false,
  initialState = {},
  isRowSelectable = () => false,
  sortingOrder = [],
  rowSelection = false,
  className = '',
  sx = {},
  hideFooter = true,
  isCellEditable = () => false,
  loading,
  slotProps,
  onSortModelChange = () => { },
  sortModel,
  children,
  getRowClassName = () => { },
  getRowId = (row) => row.id,
  onRowSelectionModelChange = () => { },
}) {
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        getRowId={getRowId}
        className={className}
        rows={rows}
        columns={columns}
        initialState={initialState}
        pageSizeOptions={[5, 10]}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        loading={loading}
        disableColumnSelector={disableColumnSelector}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        hideFooterPagination={hideFooterPagination}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        onColumnHeaderClick={onColumnHeaderClick}
        onColumnHeaderOver={onColumnHeaderOver}
        disableDensitySelector={disableDensitySelector}
        isRowSelectable={isRowSelectable}
        showColumnVerticalBorder={false}
        withBorderColor={false}
        sortingOrder={sortingOrder}
        hideFooter={hideFooter}
        rowSelection={rowSelection}
        showCellVerticalBorder={false}
        isCellEditable={isCellEditable}
        slotProps={slotProps}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        getRowClassName={getRowClassName}
        onRowSelectionModelChange={onRowSelectionModelChange}
        sx={{
          boxShadow: 2,
          borderWidth: 0,
          borderRadius: 3,
          borderColor: 'primary.dark',
          backgroundColor: 'rgba(217, 217, 217, 0.03)',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-main': {
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
          },
          '& .super-app-theme--header': {},
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '0 none',
            backgroundColor: 'rgba(217, 217, 217, 0.03)',
            borderRadius: '8px 8px 0 0',
            fontSize: '12px',
          },

          '.MuiDataGrid-columnHeaders': {
            padding: '0px',
          },

          '& .MuiDataGrid-withBorderColor': {
            borderWidth: '0',
            borderColor: 'var(--table-row_border)',
            borderStyle: 'none',
          },
          '& .MuiDataGrid-row': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'var(--table-row_border)',
            '&:last-child': {
              borderBottom: 'none', // Remove the bottom border for the last row
            },
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
            outlineWidth: '1px',
            outlineOffset: '-1px',
          },
          '.MuiDataGrid-cell:focus-within': {
            outline: 'none',
            outlineWidth: '1px',
            outlineOffset: '-1px',
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            margin: '0px ',
            padding: '0px',
            borderRadius: ' 0 0 8px 8px',
            minHeight: 30,
          },
          '.MuiDataGrid-virtualScrollerConten': {},
          ...sx,
        }}
      />
      {children}
    </div>
  );
}
