import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import useAuthenticationApi from '../api';
import Button from '../../../components/Button';
import Layout from '../../../components/AuthLayout';
import AppLoader from '../../../components/AppLoader';
import { getFromSession } from '../../../utils/session';
import { useAuthentication } from '../../../utils/store';

export default function SignupSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authenticateUser } = useAuthentication();
  const { logInGoogle, logInApple } = useAuthenticationApi();
  const paidSignupUser = useMemo(() => getFromSession('paidSignupUser'), []);
  const registration = useMemo(() => getFromSession('registration'), []);

  const clearSession = () => {
    // Clear signup session
    sessionStorage.removeItem('signupParent');
    sessionStorage.removeItem('registration');
    sessionStorage.removeItem('paidSignupUser');
    sessionStorage.removeItem('SignupSID');
    sessionStorage.removeItem('signupUser');
    sessionStorage.removeItem('token');
  };

  const onContinue = async () => {
    const { source } = registration;
    setLoading(true);

    try {
      if (source === 'apple') {
        const res = await logInApple(registration);
        clearSession();
        authenticateUser(res.user, res.token);
      } else {
        const res = await logInGoogle({ user: registration });
        clearSession();
        authenticateUser(res.user, res.token);
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!paidSignupUser || !registration) {
      navigate('/');
    }
  }, []);

  if (loading) {
    return <AppLoader />;
  }

  return (
    <Layout>
      <div className="sign-up-page sign-up-success">
        <h1 className="mb-3">{t('Congratulations')}</h1>
        <p className="mb-8">{t('Your account has been created')}</p>
        <h2 className="mb-4">{t('Your details')}</h2>

        <div className="form-item mb-2">
          <span>{t('Username')}</span>
          <span className="value">{paidSignupUser.Username}</span>
        </div>

        <div className="form-item mb-2">
          <span>{t('Email')}</span>
          <span className="value">{paidSignupUser.Email}</span>
        </div>

        <Button onClick={onContinue} className="mt-4">
          {t('Continue')}
        </Button>
      </div>
    </Layout>
  );
}
