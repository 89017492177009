import { useContext, useEffect } from 'react';
import {
  Container, Grid, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { RedeemTokenContext, RedeemTokenProvider } from './context';
import GameMatch from '../../components/GameMatch';
import ComponentLoader from '../../components/ComponentLoader';
import Layout from '../../components/CardLayout';
import TextInput from '../../components/Inputs/TextInput';
import { renderNumber } from '../../utils/string';

const RedeemTokenPage = () => {
  const navigate = useNavigate();
  const {
    t,
    user,
    loadedGamesData,
    gamesData,
    loadedWalletData,
    walletData,
    fetchRedeemData,
  } = useContext(RedeemTokenContext);
  const location = useLocation();

  if (!user) {
    navigate('/landing');
  }

  useEffect(() => {
    if (location.state?.reloadWallet) {
      location.state.reloadWallet = false;
      fetchRedeemData();
    }
  });

  return (
    <Container className="redeem-token-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Gamer Wallet')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        {loadedWalletData && !!walletData ? (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              value={renderNumber(walletData.token).toString()}
              readOnly
              endAdornment={(
                <div className="flex items-center">
                  <Typography
                    sx={{
                      mr: 3,
                      ml: 1,
                      opacity: 0.5,
                      fontWeight: 500,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    Tokens
                  </Typography>
                </div>
              )}
            />
          </Grid>
        ) : (
          <ComponentLoader />
        )}
      </Layout>

      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedGamesData ? (
          <>
            {gamesData?.map((game, index) => (
              <GameMatch game={game} key={index} userTokens={walletData.token} />
            ))}
            {gamesData?.length < 1 && (
              <Typography sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '26px',
                fontWeight: 600,
                py: 4,
                width: '100%',
              }}
              >
                {t('There are no tokens available for redemption')}
              </Typography>
            )}
          </>
        ) : (
          <ComponentLoader />
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <RedeemTokenProvider>
    <RedeemTokenPage />
  </RedeemTokenProvider>
);
