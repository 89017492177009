/* eslint-disable max-len */
import { useContext, useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import Button from '../../../components/Button';
import TextInput from '../../../components/Inputs/TextInput';
import { modalTypes } from '../config';
import { getDisclaimerContent } from './walletManagementAmbassador';

export default function BtcWalletNumber() {
  const {

    onBtcWalletExit,
    onBtcWalletClick,
    btcwalletModal,
    btcWalletNumber,
    onChangeBtcWalletNumber,
  } = useContext(Context);
  const [data, setData] = useState(btcWalletNumber || '');
  const [validation, setValidation] = useState();

  useEffect(() => {
    setData(btcWalletNumber);
  }, [btcWalletNumber]);

  const validate = () => {
    if (data.length === 0) {
      setValidation(true);
    } else {
      onChangeBtcWalletNumber((data));
    }
  };

  return (
    <Modal width="550px" title=" Default (BTC) Wallet" open={btcwalletModal} handleClose={onBtcWalletExit}>
      <Container>
        <Typography>Add Your Wallet Address</Typography>
        <TextInput value={data} onChange={(e) => { setData(e); setValidation(false); }} />
        {validation && <Typography color="brown" sx={{ fontSize: '13px', mt: 1 }}> Please provide a btc wallet! </Typography>}
        <div className="mt-2">
          {getDisclaimerContent()}
        </div>
      </Container>
      <Container sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <Button onClick={() => { validate(); }} style={{ width: '100%' }}>
          Submit
        </Button>
        <Button onClick={() => onBtcWalletExit()} style={{ width: '100%' }} bordered>
          Cancel
        </Button>
      </Container>
    </Modal>
  );
}
