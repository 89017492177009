/* eslint-disable max-len */
import { useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
// import ButtonGroup from '../../../components/ButtonGroup';
import PrevNextButtons from './prevNext';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import AskAIModal from './askAIModal';
import { aiExperts } from '../config';
// const buttonGroupConfig = [
//   {
//     id: 0,
//     title: 'New',
//   },
//   {
//     id: 1,
//     title: 'History',
//   },
// ];

export default function AskAI() {
  const {
    t,
    // clickedAIButton, 
    // setClickedAIButton, 
    setAskAIModal,
    setAiQuestion,
    askAIModal,
    aiQuestion,
    changeExpert,
    expert,
  } = useContext(Context);

  return (
    <Layout sx={{}} style={{ height: '100%' }} maxWidth="100%" maxHeight="100%" className="ask-ai-card">
      <Grid container rowSpacing={1}>
        <Grid item xl={6} lg={6} md={12} sm={6} xs={12}>
          <Typography sx={{
            fontSize: '26px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: {
              xl: 'start', lg: 'start', md: 'center', sm: 'start', xs: 'center',
            },
          }}
          >
            {t('Ask Our Experts')}
          </Typography>
        </Grid>
        {/* <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={6}
          xs={12}
          sx={{ display: 'flex', justifyContent: {
              xl: 'end', lg: 'end', md: 'center', sm: 'end', xs: 'center', 
            },  alignItems: 'center' }}
        >
          <ButtonGroup
            type="auto"
            setClicked={setClickedAIButton}
            clicked={clickedAIButton}
            config={buttonGroupConfig}
          />
        </Grid> */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xl: 'start', lg: 'start', md: 'center', sm: 'start', xs: 'center',
            },
          }}
        >
          <img src="/assets/images/johndoe.png" alt="john" />
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={6}
          xs={12}
          sx={{
            alignItems: 'center',

          }}
        >
          <Container sx={{
            display: 'flex',
            justifyContent: {
              xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'center',
            },
          }}
          >
            <PrevNextButtons
              onClickPrev={() => {
                changeExpert('prev');
              }}
              onClickNext={() => {
                changeExpert('next');
              }}
            />
          </Container>

          <Typography sx={{
            fontSize: '16px',
            fontWeight: 600,
            mb: 2,
            mt: 2,
            justifyContent: {
              xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'center',
            },
            display: 'flex',
          }}
          >
            {aiExperts[expert].name}
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#f9f9f9',
            justifyContent: {
              xl: 'end', lg: 'end', md: 'center', sm: 'end', xs: 'center',
            },
            display: 'flex',
            textAlign: 'center',
          }}
          >
            {aiExperts[expert].descriptiom}
          </Typography>

        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 4, mb: 4 }}>
          <TextInput
            onChange={(e) => {
              setAiQuestion(e);
            }}
            value={!askAIModal ? aiQuestion : ''}
            type="textarea"
            placeholder={aiExperts[expert].placeholder}
            inputStyle={{ resize: 'none' }}
            rows={3}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
          {/* <Button style={{ width: '100%' }} size="md" bordered>
            <span> Share</span>
          </Button> */}
          <Button
            onClick={() => setAskAIModal(true)}
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
            }}
          >
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Ask an Expert
            </span>
          </Button>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
          <Typography sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'gray',
            justifyContent: {
              xl: 'end', lg: 'end', md: 'center', sm: 'end', xs: 'center',
            },
            display: 'flex',
            textAlign: 'center',
            mt: 1,
          }}
          >
            {'Memegames.ai has trained it\'s AIs to answer questions in their field of knowledge. They are not for financial advice but are intended as a learning tool.'}
          </Typography>
        </Grid>
      </Grid>
      <AskAIModal />
    </Layout>
  );
}
