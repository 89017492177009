import { Container, Pagination, Typography } from '@mui/material';
import ComponentLoader from '../../../components/ComponentLoader';
import useLeaderboardApi from '../context/api';
import Table from '../../../components/Table';

const AllRank = () => {
  const {
    leaderboardColumns,
    loadingAllRank,
    allRankData,
    paginationData,
    getPaginatedRankData,
  } = useLeaderboardApi();
  const showPagination = false;

  return loadingAllRank ? (
    <ComponentLoader />
  ) : (
    <Container
      maxWidth="100%"
      sx={{ padding: { xs: 0 }, maxWidth: { xs: '100%' } }}
    >
      <Table
        columns={leaderboardColumns}
        rows={allRankData}
        className="table"
        hideFooterPagination
        hideFooter
      />
      
      {(!!showPagination && !!paginationData && paginationData?.totalUsers > 25) && (
        <div className="flex justify-end mt-4">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${paginationData.totalUsers} users`}
          </Typography>
          <Pagination
            count={paginationData?.total}
            page={paginationData?.page}
            onChange={(val, page) => {
              getPaginatedRankData(page);
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default AllRank;
