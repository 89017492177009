import { Typography, Container } from '@mui/material';
import Modal from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import AppLoader from '../../../../../../components/AppLoader';

export default function RemoveBonusModal({
  user, open, handleClose, onRemoveBonus, loading,
}) {
  return (
    <Modal width="550px" title="Remove Bonus Volume" open={open} handleClose={handleClose}>
      <Container sx={{ pt: 0, mt: 0 }}>
        {loading && <AppLoader overpage />}
        {!Array.isArray(user) && (
          <>
            <Typography
              sx={{
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              {`${user?.Name} ${user?.Surname} (${user?.Username})`}
            </Typography>
            {user?.rankName && (
              <Typography
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  color: 'grey',
                  justifyContent: 'center',
                  mb: 3,
                }}
              >
                {`${user.rankName}`}
              </Typography>
            )}
          </>
        )}

        <Typography
          sx={{
            fontSize: '22px',
            display: 'flex',
            color: 'green',
            justifyContent: 'center',
            fontWeight: 'bold',
            mb: 3,
          }}
        >
          {`${user?.team1BonusVolume ? `$${user?.team1BonusVolume.toLocaleString()}` : ''}`}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'center',
            mb: 3,
            mt: 2,
          }}
        >
          Are you sure you want to remove the bonus volume?
        </Typography>

        <Button
          type="button"
          style={{ width: '100%' }}
          onClick={() => {
            onRemoveBonus(user);
          }}
          disabled={loading}
        >
          Remove Bonus
        </Button>
      </Container>
    </Modal>
  );
}
