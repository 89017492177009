import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@mui/material';
import { renderNumber } from '../../utils/string';
import RedeemButton from '../RedeemButton';

const CoinComponent = ({
  coin, coinData, isWinner, myPick, tokens, gameId, leaderboardRank, tokenReceived,
}) => (
  <div className={`game${isWinner ? ' winner' : ' '}`}>
    <div className="game-header">
      {myPick && (
        <Typography
          sx={{ fontSize: { xs: '12px' }, fontWeight: 600 }}
          className="picked"
        >
          MY PICK
        </Typography>
      )}
    </div>
    <div className="game-body">
      <div className="game-body-icon">
        <div className="img">
          <img src={coin?.image} height={90} width={90} alt={coin?.name} />
        </div>
        <div className="results">
          <div className="text">
            <Typography
              sx={{ fontSize: { xs: '10px' } }}
              className="text-value"
            >
              Leaderboard Rank:
            </Typography>
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-title"
            >
              {`TOP ${parseInt(leaderboardRank, 10)}%`}
            </Typography>
          </div>
          <div className="text">
            <Typography
              sx={{ fontSize: { xs: '10px' } }}
              className="text-value"
            >
              Coins Received:
            </Typography>
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-title"
            >
              {renderNumber(tokenReceived)}
            </Typography>
          </div>
        </div>
      </div>
      <div className="game-body-text">
        <div className="text">
          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-value"
          >
            Coin:
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
            className="text-title"
          >
            {coin?.name}
          </Typography>
        </div>

        <div className="text">
          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-value"
          >
            Change:
          </Typography>
          <div className="flex">
            <div className="card-chip">
              <div className="flex flex-column mr-2">
                <span style={{ fontSize: '10px' }}>
                  {coinData.priceChange}
                  %
                </span>
              </div>
              <div className="flex flex-column">
                {coinData.priceChange > 0 ? (
                  <div className="chip-bubble">
                    <div className="flex justify-center">
                      <ArrowUpwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                    </div>
                  </div>
                ) : (
                  <div className="chip-bubble not-growing-bubble">
                    <div className="flex justify-center">
                      <ArrowDownwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="text">
          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-value"
          >
            Start Price:
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
            className="text-title"
          >
            <span style={{ fontSize: '8px' }}>$</span>
            {renderNumber(coinData.start.price)}
          </Typography>
        </div>

        <div className="text">
          <Typography
            sx={{ fontSize: { xs: '10px' } }}
            className="text-value"
          >
            End Price:
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
            className="text-title"
          >
            <span style={{ fontSize: '8px' }}>$</span>
            {renderNumber(coinData.end.price)}
          </Typography>
        </div>
      </div>
    </div>
    {(isWinner && myPick) && (
      <div className="game-footer">
        <RedeemButton
          totalTokens={tokens}
          gameId={gameId}
          coin={coin}
          buttonText={tokens > 2000 ? 'Redeem 2,000' : null}
          bordered
        />
      </div>
    )}
  </div>
);

export default CoinComponent;
