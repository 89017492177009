import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import TextInput from '../../../../../components/Inputs/TextInput';
import Button from '../../../../../components/Button';

export default function EnterKey({
  passKey, setKey, validatingKey, onSubmitKey, keyValidation,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-row mb-3">
        <TextInput
          className="flex-1"
          focused
          size="sm"
          value={passKey}
          placeholder="Enter it here"
          onChange={setKey}
          disabled={validatingKey}
        />

        <div className="flex flex-column ml-4">
          <Button
            type="button"
            bordered
            onClick={onSubmitKey}
            size="sm"
            className="flex-1 flex flex-column bordered-nb"
            disabled={validatingKey}
          >
            {t('Go')}
          </Button>
        </div>
      </div>
      {keyValidation && (
        <Typography
          sx={{
            color: 'brown !important',
            fontSize: '14px',
            opacity: '100% !important',
            mb: 3,
          }}
        >
          {keyValidation}
        </Typography>
      )}
    </div>
  );
}
