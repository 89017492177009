import { Container, Typography } from '@mui/material';
import { useContext } from 'react';
import Table from '../../../components/Table';
import { Context } from '../context';
import Layout from '../../../components/CardLayout';
import Icon from '../../../components/Icon';

export default function SalesTable() {
  const { sales, salesColumns, t } = useContext(Context);

  if (sales?.length) {
    return (
      <Container style={{ padding: 0 }} maxWidth="xl">
        <Table
          columns={salesColumns}
          rows={sales}
          className="table"
          sortingOrder={['desc', 'asc']}
          sortModel={[{ field: 'dateJoined', sort: 'desc' }]}
        />
        <Typography sx={{
          display: 'flex', paddingY: '2rem', width: '100%', justifyContent: 'center', 
        }}
        >
          <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="/sales/list" title="Go to Genealogy">  
            {t('Show More')}
          </a>
        </Typography>
      </Container>
    );
  }

  return (
    <Layout
      maxWidth="2xl"
      sx={{ mt: 0 }}
      style={{ height: '100%', opacity: 0.7 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Icon
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '45px',
            height: '45px',
          }}
          name="ShoppingBag"
        />
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mt: 1,
          }}
        >
          {t('No other sales yet')}
        </Typography>
      </div>
    </Layout>
  );
}
