import {
  Typography, Grid, CircularProgress, Pagination,
} from '@mui/material';
import { useContext } from 'react';
import dayjs from 'dayjs';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import { Context } from '../context';
import useScreenSize from '../../../utils/hooks/useScreenSize';

export default function TransactionHistory() {
  const {
    loadingHistory, columns, historyData, totalPages, historyPage, fetchHistory, t,
  } = useContext(Context);

  const { isMobile } = useScreenSize();

  return (
    <Layout maxWidth="xl">
      <div className="flex">
        <Typography
          className="flex-1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: { xs: '18px', md: '26px' },
            fontWeight: 600,
            mb: 2,
          }}
        >
          {t('Transaction History')}
        </Typography>
      </div>
      <Grid item container xs>
        {loadingHistory ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <Grid item container xs rowSpacing={2}>
            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              {isMobile ? (
                historyData.map((data, idx) => (
                  <Grid
                    key={idx}
                    container
                    borderBottom="1px solid rgba(255, 255, 255, 0.1)"
                    paddingY="24px"
                  >
                    <Grid item xs={6} marginBottom="6px">
                      <Typography
                        fontSize="8px"
                        fontWeight="600"
                        color="rgba(255, 255, 255, 0.7)"
                      >
                        Received/Deducted
                      </Typography>
                      <Typography
                        fontSize="14px"
                      >
                        {`$${Number(data.amount).toLocaleString('en-us')}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} marginBottom="6px">
                      <Typography
                        fontSize="8px"
                        fontWeight="600"
                        color="rgba(255, 255, 255, 0.7)"
                      >
                        Date
                      </Typography>
                      <Typography
                        fontSize="14px"
                      >
                        {dayjs(data.createdAt).format('MMM DD, YYYY')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        fontSize="8px"
                        fontWeight="600"
                        color="rgba(255, 255, 255, 0.7)"
                      >
                        Description
                      </Typography>
                      <Typography
                        fontSize="14px"
                      >
                        {data.description}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Table
                  loading={loadingHistory}
                  columns={columns}
                  rows={historyData}
                  className="table mb-4"
                />
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" className="custom-pagination">
              <Pagination
                count={totalPages}
                page={historyPage}
                onChange={(val, page) => {
                  fetchHistory(page);
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
