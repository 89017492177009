import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Form from '../../../../../components/Form';
import { validateEmail } from '../../../../../utils/string';
import useAuthenticationApi from '../../../api';

const config = [
  {
    name: 'email',
    type: 'email',
    label: 'Email',
  },
];

export default function Step1({ registrationData = {}, signupParent = null }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAffiliate } = useAuthenticationApi();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const onNo = () => navigate('/');

  useEffect(() => {
    if (signupParent) {
      navigate('/dashboard/registration/newRegister');
    }
  }, [signupParent]);

  const sendEmail = async (val) => {
    setLoading(true);
    setErrors();
    if (val?.email?.length > 0) {
      if (validateEmail(val.email)) {
        try {
          const _data = {
            inputEmail: val,
            user: registrationData,
          };
          await getAffiliate(_data);
          navigate('/');
        } catch (err) {
          console.log(err);
        }

        onNo();
      } else {
        setErrors({
          email: 'Email not valid!',
        });
      }
    } else {
      setErrors({
        email: 'Please provide an email',
      });
    }
    setLoading(false);
  };

  if (!signupParent) {
    return (
      <div className="signup-form">
        <div className="admin-login">
          <div className="flex-row justify-center mb-12">
            <img alt="logo-black" src="/assets/logo_black.svg" />
          </div>

          <p className="mb-8">
            {t(
              "You haven't provided an ambassador link, please leave your email below and an ambassador will be in touch.",
            )}
          </p>

          <Form
            config={config}
            onSubmit={sendEmail}
            submitLabel="Send Email"
            defaultFormValue={{}}
            errors={errors}
            formLoading={loading}
            buttonType="button"
          />
        </div>
      </div>
    );
  }

  return <div />;
}
