const config = {
  viewBox: '0 0 12 12',
  children: (
    <g>
      <mask id="path-1-inside-1_186_3411" fill="currentColor">
        <path d="M0 0H4V2.65625H0V0ZM8 4.65625H12V7.34375H8V4.65625ZM8 9.34375H12V12H8V9.34375ZM6.65625 6.65625H2.65625V10H6.65625V11.3438H1.34375V4H2.65625V5.34375H6.65625V6.65625Z" />
      </mask>
      <path
        opacity={0.6}
        d="M0 0V-1H-1V0H0ZM4 0H5V-1H4V0ZM4 2.65625V3.65625H5V2.65625H4ZM0 2.65625H-1V3.65625H0V2.65625ZM8 4.65625V3.65625H7V4.65625H8ZM12 4.65625H13V3.65625H12V4.65625ZM12 7.34375V8.34375H13V7.34375H12ZM8 7.34375H7V8.34375H8V7.34375ZM8 9.34375V8.34375H7V9.34375H8ZM12 9.34375H13V8.34375H12V9.34375ZM12 12V13H13V12H12ZM8 12H7V13H8V12ZM6.65625 6.65625V7.65625H7.65625V6.65625H6.65625ZM2.65625 6.65625V5.65625H1.65625V6.65625H2.65625ZM2.65625 10H1.65625V11H2.65625V10ZM6.65625 10H7.65625V9H6.65625V10ZM6.65625 11.3438V12.3438H7.65625V11.3438H6.65625ZM1.34375 11.3438H0.34375V12.3438H1.34375V11.3438ZM1.34375 4V3H0.34375V4H1.34375ZM2.65625 4H3.65625V3H2.65625V4ZM2.65625 5.34375H1.65625V6.34375H2.65625V5.34375ZM6.65625 5.34375H7.65625V4.34375H6.65625V5.34375ZM0 1H4V-1H0V1ZM3 0V2.65625H5V0H3ZM4 1.65625H0V3.65625H4V1.65625ZM1 2.65625V0H-1V2.65625H1ZM8 5.65625H12V3.65625H8V5.65625ZM11 4.65625V7.34375H13V4.65625H11ZM12 6.34375H8V8.34375H12V6.34375ZM9 7.34375V4.65625H7V7.34375H9ZM8 10.3438H12V8.34375H8V10.3438ZM11 9.34375V12H13V9.34375H11ZM12 11H8V13H12V11ZM9 12V9.34375H7V12H9ZM6.65625 5.65625H2.65625V7.65625H6.65625V5.65625ZM1.65625 6.65625V10H3.65625V6.65625H1.65625ZM2.65625 11H6.65625V9H2.65625V11ZM5.65625 10V11.3438H7.65625V10H5.65625ZM6.65625 10.3438H1.34375V12.3438H6.65625V10.3438ZM2.34375 11.3438V4H0.34375V11.3438H2.34375ZM1.34375 5H2.65625V3H1.34375V5ZM1.65625 4V5.34375H3.65625V4H1.65625ZM2.65625 6.34375H6.65625V4.34375H2.65625V6.34375ZM5.65625 5.34375V6.65625H7.65625V5.34375H5.65625Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_186_3411)"
      />
    </g>
  ),
};

export default config;
