import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useMemeCoinsApi from './api';

export default function useMemeCoins() {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const {
    getMemeCoins, updateMemeCoin, createMemeCoin, deleteMemeCoin, 
  } = useMemeCoinsApi();
  const [deleteId, setDeleteId] = useState();

  const onEditClick = (coin) => setSelectedCoin(coin);
  const onAddClick = () => setSelectedCoin({});
  const onClose = () => setSelectedCoin(null);
  const onDelete = (data) => {
    setDeleteId(data);
  };
    
  const _fetch = async () => {
    setLoading(true);

    try {
      const data = await getMemeCoins();
      setCoins(data);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    _fetch();
  }, []);

  const onSubmit = async (data) => {
    setLoadingForm(true);

    try {
      if (data.id) {
        const tmp = { ...data };
        delete tmp.id;
        delete tmp._id;
        await updateMemeCoin(tmp, data.id);
        _fetch();
      } else {
        await createMemeCoin(data);
        _fetch();
      }
    } catch (err) {
      console.log(err);
    }

    setLoadingForm(false);
    onClose();
  };
  
  const onDeleteSubmit = async () => {
    try {
      await deleteMemeCoin(deleteId.id);
      toast.success('Meme coin deleted successfully!');
      setDeleteId();
      _fetch();
    } catch (err) {
      console.log(err);
    }
  };
  
  const cancelDelete = () => {
    setDeleteId();
  };

  return {
    coins,
    loading,
    onClose,
    onSubmit,
    onAddClick,
    loadingForm,
    onEditClick,
    selectedCoin,
    onDeleteSubmit,
    onDelete,
    deleteId,
    cancelDelete,
  };
}
