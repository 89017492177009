import useLeaderboardApi from './api';

const useLeaderboard = () => {
  const {
    t,
    user,

    // Ranking
    topTotal,
    setTopTotal,
    topRankYesterdayData,
    loadingTopRankYesterday,
    setLoadingTopRankYesterday,
    topRankData,
    loadingTopRank,
    setLoadingTopRank,
    allRankData,
    loadingAllRank,
    setLoadingAllRank,
    getPaginatedRankData,

    paginationData,

    // Columns configuration
    leaderboardTopColumns,
    leaderboardColumns,
    leaderboardTopYesterdayColumns,
  } = useLeaderboardApi();

  return {
    t,
    user,

    // Ranking
    topTotal,
    setTopTotal,
    topRankYesterdayData,
    loadingTopRankYesterday,
    setLoadingTopRankYesterday,
    topRankData,
    loadingTopRank,
    setLoadingTopRank,
    allRankData,
    loadingAllRank,
    setLoadingAllRank,
    getPaginatedRankData,

    paginationData,

    // Columns configuration
    leaderboardTopColumns,
    leaderboardColumns,
    leaderboardTopYesterdayColumns,
  };
};

export default useLeaderboard;
