import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';

export default function MultiplierModal({ onClose, multiplierModalType }) {
  const { title, content, backgroundImage } = multiplierModalType === 'percentage' ? {
    content: [
      'Lorem ipsum dolor sit amet, consequatctetur adipisicing elit, sed do eiusmod tempor incidaidunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    ],
    title: 'Percentage Multiplier',
    backgroundImage: 'url("/images/percentage-img.png")',
  } : {
    content: [
      'Lorem ipsum dolor sit amet, consequatctetur adipisicing elit, sed do eiusmod tempor incidaidunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    ],
    title: 'Points', // changed to points
    backgroundImage: 'url("/images/points-img.png")',
  };

  return (
    <Modal
      open={!!multiplierModalType}
      handleClose={onClose}
      width="700px"
    >
      <div className="multiplier-modal">
        <div className="left-content">
          <div className="left-content-img" style={{ backgroundImage }} />
        </div>

        <div className="right-content">
          <h2 className="mb-4">{title}</h2>

          {content.map((c, i) => (
            <p className="mb-2" key={i}>{c}</p>
          ))}

          <div className="flex mt-6">
            <Button bordered onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
