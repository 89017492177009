/* eslint-disable react/style-prop-object */
const config = {
  viewBox: '0 0 14 12',
  children: (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1259_1360" fill="white">
        <path d="M7 0L0 3.84314L7 7.68627L12.7273 4.54131V8.96732H14V3.84314M2.54545 6.52052V9.08261L7 11.5294L11.4545 9.08261V6.52052L7 8.96732L2.54545 6.52052Z" />
      </mask>
      <path d="M0 3.84314L-0.481259 2.96656L-2.07788 3.84314L-0.481259 4.71972L0 3.84314ZM7 7.68627L6.51874 8.56285L7.00004 8.8271L7.48133 8.56282L7 7.68627ZM12.7273 4.54131H13.7273V2.85134L12.2459 3.66477L12.7273 4.54131ZM12.7273 8.96732H11.7273V9.96732H12.7273V8.96732ZM14 8.96732V9.96732H15V8.96732H14ZM2.54545 6.52052L3.02689 5.64404L1.54545 4.83032V6.52052H2.54545ZM2.54545 9.08261H1.54545V9.67426L2.06402 9.9591L2.54545 9.08261ZM7 11.5294L6.51857 12.4059L7 12.6703L7.48143 12.4059L7 11.5294ZM11.4545 9.08261L11.936 9.9591L12.4545 9.67426V9.08261H11.4545ZM11.4545 6.52052H12.4545V4.83032L10.9731 5.64404L11.4545 6.52052ZM7 8.96732L6.51857 9.8438L7 10.1082L7.48143 9.8438L7 8.96732ZM6.51874 -0.876579L-0.481259 2.96656L0.481259 4.71972L7.48126 0.876579L6.51874 -0.876579ZM-0.481259 4.71972L6.51874 8.56285L7.48126 6.8097L0.481259 2.96656L-0.481259 4.71972ZM7.48133 8.56282L13.2086 5.41785L12.2459 3.66477L6.51867 6.80973L7.48133 8.56282ZM11.7273 4.54131V8.96732H13.7273V4.54131H11.7273ZM12.7273 9.96732H14V7.96732H12.7273V9.96732ZM15 8.96732V3.84314H13V8.96732H15ZM1.54545 6.52052V9.08261H3.54545V6.52052H1.54545ZM2.06402 9.9591L6.51857 12.4059L7.48143 10.6529L3.02689 8.20613L2.06402 9.9591ZM7.48143 12.4059L11.936 9.9591L10.9731 8.20613L6.51857 10.6529L7.48143 12.4059ZM12.4545 9.08261V6.52052H10.4545V9.08261H12.4545ZM10.9731 5.64404L6.51857 8.09084L7.48143 9.8438L11.936 7.39701L10.9731 5.64404ZM7.48143 8.09084L3.02689 5.64404L2.06402 7.39701L6.51857 9.8438L7.48143 8.09084Z" fill="#CE965F" mask="url(#path-1-inside-1_1259_1360)" />
      <path d="M0 3.84314L-0.481259 2.96656L-2.07788 3.84314L-0.481259 4.71972L0 3.84314ZM7 7.68627L6.51874 8.56285L7.00004 8.8271L7.48133 8.56282L7 7.68627ZM12.7273 4.54131H13.7273V2.85134L12.2459 3.66477L12.7273 4.54131ZM12.7273 8.96732H11.7273V9.96732H12.7273V8.96732ZM14 8.96732V9.96732H15V8.96732H14ZM2.54545 6.52052L3.02689 5.64404L1.54545 4.83032V6.52052H2.54545ZM2.54545 9.08261H1.54545V9.67426L2.06402 9.9591L2.54545 9.08261ZM7 11.5294L6.51857 12.4059L7 12.6703L7.48143 12.4059L7 11.5294ZM11.4545 9.08261L11.936 9.9591L12.4545 9.67426V9.08261H11.4545ZM11.4545 6.52052H12.4545V4.83032L10.9731 5.64404L11.4545 6.52052ZM7 8.96732L6.51857 9.8438L7 10.1082L7.48143 9.8438L7 8.96732ZM6.51874 -0.876579L-0.481259 2.96656L0.481259 4.71972L7.48126 0.876579L6.51874 -0.876579ZM-0.481259 4.71972L6.51874 8.56285L7.48126 6.8097L0.481259 2.96656L-0.481259 4.71972ZM7.48133 8.56282L13.2086 5.41785L12.2459 3.66477L6.51867 6.80973L7.48133 8.56282ZM11.7273 4.54131V8.96732H13.7273V4.54131H11.7273ZM12.7273 9.96732H14V7.96732H12.7273V9.96732ZM15 8.96732V3.84314H13V8.96732H15ZM1.54545 6.52052V9.08261H3.54545V6.52052H1.54545ZM2.06402 9.9591L6.51857 12.4059L7.48143 10.6529L3.02689 8.20613L2.06402 9.9591ZM7.48143 12.4059L11.936 9.9591L10.9731 8.20613L6.51857 10.6529L7.48143 12.4059ZM12.4545 9.08261V6.52052H10.4545V9.08261H12.4545ZM10.9731 5.64404L6.51857 8.09084L7.48143 9.8438L11.936 7.39701L10.9731 5.64404ZM7.48143 8.09084L3.02689 5.64404L2.06402 7.39701L6.51857 9.8438L7.48143 8.09084Z" fill="url(#paint0_linear_1259_1360)" fillOpacity="0.3" mask="url(#path-1-inside-1_1259_1360)" />
      <defs>
        <linearGradient id="paint0_linear_1259_1360" x1="0" y1="11.5294" x2="11.3155" y2="-2.21079" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

export default config;
