import clsx from 'clsx';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateInput({
  value = '',
  size = 'md',
  focused = false,
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  disabled = false,
}) {
  return (
    <div className="form-input date-picker-input flex-row" onFocus={onFocus} onBlur={onBlur}>
      <DatePicker
        className={clsx('flex-1 date-picker', focused && 'focused', size)}
        value={dayjs(value)}
        timezone="UTC"
        onOpen={onFocus}
        onClose={onBlur}
        onChange={(val) => onChange(val)}
        format="MMM D, YYYY"
        disabled={disabled}
      />
    </div>
  );
}

DateInput.propTypes = {
  value: PropTypes.any,
  focused: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};
