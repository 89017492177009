import { useState } from 'react';
import Modal from '../Modal';
import Icon from '../Icon';

const InfoBubbleModal = ({ children, iconColor = '#FFFFFF', title = 'Important Information' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => handleOpenModal()}
        type="button"
        aria-label="Help"
        className="help-button"
      >
        <Icon name="SquaredQuestionmark" width={16} height={16} color={iconColor} />
      </button>

      <Modal
        title={title}
        width="550px"
        open={isOpen}
        handleClose={handleCloseModal}
      >
        <p className="text-center mb-8">{children}</p>
      </Modal>
    </>
  );
};

export default InfoBubbleModal;
