/* eslint-disable max-len */
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Layout from '../../../components/CardLayout';
import Icon from '../../../components/Icon';

export default function ComunityHome() {
  const { t } = useTranslation();
  return (
    <Layout style={{ height: '100%' }} maxWidth="100%" maxHeight="100%">
      <Grid
        container
        columnSpacing={3}
        rowSpacing={3}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

          <Typography sx={{ fontSize: '17px', fontWeight: 600, mb: 2 }}>
            {t('Technology')}
          </Typography>
          <div className="divider" />

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,
            }}
          >
            <Icon name="GraduationHat" width={24} height={24} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/tech-academy/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Academy')}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <SearchIcon width={20} height={20} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/tech-opportunities/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Opportunities')}
              {' '}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <ChatBubbleOutlineIcon fontSize="medium" />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/tech-chat/" target="_blank" rel="noreferrer"> 
              {' '}
              {t('Chat')}
              {' '}
            </a>
          </Typography>

        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

          <Typography sx={{ fontSize: '17px', fontWeight: 600, mb: 2 }}>
            {t('Real Estate')}
          </Typography>
          <div className="divider" />

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <Icon name="GraduationHat" width={24} height={24} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/real-estate-academy/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Academy')}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <SearchIcon width={20} height={20} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/real-estate-opportunities/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Opportunities')}
              {' '}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <ChatBubbleOutlineIcon width={20} height={20} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/real-estate-chat/" target="_blank" rel="noreferrer"> 
              {' '}
              {t('Chat')}
              {' '}
            </a>
          </Typography>

        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

          <Typography sx={{ fontSize: '17px', fontWeight: 600, mb: 2 }}>
            {t('Crypto')}
          </Typography>
          <div className="divider" />

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <Icon name="GraduationHat" width={24} height={24} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/crypto-academy/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Academy')}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <SearchIcon width={20} height={20} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/crypto-opportunities/" target="_blank" rel="noreferrer">  
              {' '}
              {t('Opportunities')}
              {' '}
            </a>
          </Typography>

          <Typography
            color="primary"
            sx={{
              textDecoration: 'underline',
              color: 'var(--primary-color) !important',
              cursor: 'pointer',
              fontSize: '14px',
              mt: 1,
              display: 'flex',
              textAlign: 'center',
              fontWeight: 600,
              mb: 2,

            }}
          >
            <ChatBubbleOutlineIcon width={20} height={20} />
            <a style={{ color: 'var(--primary-color) !important', marginLeft: '7px' }} href="https://portal.trec.global/c/crypto-chat/" target="_blank" rel="noreferrer"> 
              {' '}
              {t('Chat')}
              {' '}
            </a>
          </Typography>

        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        
          <Typography
            onClick={() => {
              console.log(process.env.REACT_APP_CIRCLE_OAUTH);
              window.open(process.env.REACT_APP_CIRCLE_OAUTH, '_blank', 'noreferrer');
            }}
            sx={{
              display: 'flex',
              boxShadow: `box-shadow: 0px 0px 3px 0px rgba(97,97,97,1);
            -webkit-box-shadow: 0px 0px 3px 0px rgba(97,97,97,1);
            -moz-box-shadow: 0px 0px 3px 0px rgba(97,97,97,1);`, 
              borderRadius: '8px',
              alignContent: 'center',
              alignItems: 'center',
              paddingTop: '13px',
              paddingBottom: '13px',
              justifyContent: 'space-between',
              paddingLeft: '10px',
              paddingRight: '10px',
              cursor: 'pointer',

            }}
          >

            <Typography
              color="primary"
              sx={{
                textDecoration: 'underline',
                color: 'var(--primary-color) !important',
                cursor: 'pointer',
                fontSize: '17px',
                display: 'flex',
                textAlign: 'center',
                fontWeight: 600,
                marginLeft: '20px',

              }}
            >
              <Icon name="TrecLogo" height={34} width={34} style={{ alignItems: 'center', textAlign: 'center' }} />

              <a style={{ color: 'var(--primary-color) !important', marginLeft: '25px', marginTop: 3 }} href={process.env.REACT_APP_CIRCLE_OAUTH} target="_blank" rel="noreferrer"> 
                {' '}
                {t('TREC Community')}
                {' '}
              </a>
            </Typography>

            <KeyboardDoubleArrowRightIcon color="#464749" sx={{ marginRight: '20px', color: '#464749', fontSize: '27px' }} />

          </Typography>
        
        </Grid>

        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt="logo" src="/assets/layout/logo_white.svg" height="53px" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{
            fontSize: '22px', fontWeight: 600, display: 'flex', justifyContent: 'center', 
          }}
          >
            {t('Community Home')}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <a href={process.env.REACT_APP_CIRCLE_OAUTH} target="_blank" rel="noreferrer">
            <Button onClick={onTrecGlobalClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                TREC Global
              </span>
            </Button>
          </a>

        </Grid> */}
      </Grid>
    </Layout>
   
  );
}
