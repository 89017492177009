import Table from '../../../../../components/Table';

export default {};

export const colConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'name') {
      return 'first-column ';
    }
    return '';
  },
};

export const dummyData = {
  name: 'Admin User',
  memberId: 'Admin',
  username: 'Admin',
  enrolledParentId: 10001,
  rank: 'Admin',
  status: 'Active',
  email: 'ruan@danzlive.com',
  mobile: '+355XXXXXXX',
  dateOfBirth: '1 January, 1970',
  country: 'South Africa',
  joinDate: '13 October, 2023',
  expirationDate: '13 October. 2030',
  lastLoginDate: '1 Novemver, 2023',
};

export const changePasswordForm = [
  {
    name: 'password',
    type: 'password',
    label: 'PASSWORD',
    placeholder: 'Password',
  },

  {
    name: 'retypePassword',
    type: 'password',
    label: 'NEW PASSWORD CONFIRMATION',
    placeholder: 'Password',
  },
];

export const changeSponsorForm = [
  {
    name: 'sponsorId',
    type: 'text',
    label: 'NEW SPONSOR',
    placeholder: 'New Sponsor Id',
  },
];

export const historyTable = ({ rows, columns }) => (
  <div className="flex ">
    <Table columns={columns} rows={rows} className="table" />
  </div>
);
