import { memeModalConfig } from './config';
import Form from '../../../components/Form';

export default function MemeModal({ value = {}, onSubmit, loadingForm }) {
  return (
    <Form
      config={memeModalConfig}
      size="sm"
      defaultFormValue={value}
      onSubmit={onSubmit}
      formLoading={loadingForm}
      submitLabel="Save Meme Game"
    />
  );
}
