/* eslint-disable max-len */
import CircularProgress from '@mui/material/CircularProgress';
import Form from '../../../../components/Form';
import useProfile from './hook';
import ConfirmAffiliateModal from './components/confirmAffiliateModal';

export default function AccountInfo() {
  const {
    userData, submitPersonalInformation, 
    isLoading, errors, 
    openAffiliateConfirmModal, 
    handleConfirmModalClose, t, 
    onConfirmationModalSubmit,
    setFormData,
    formConfig,
  } = useProfile();

  return (
    <div>
      {isLoading ? (
        <div style={{ position: 'absolute', left: '50%', top: '-50%' }}>
          <CircularProgress />
        </div>
      ) : (
        <Form
          onSubmit={submitPersonalInformation}
          defaultFormValue={userData}
          config={formConfig}
          errors={errors}
          size="sm"
          className="horizontal-alignment"
          submitLabel="Save Personal Information"
          setCurrentData={setFormData}
        />
      )}
      {
        handleConfirmModalClose && (
        <ConfirmAffiliateModal
          t={t} 
          openAffiliateConfirmModal={openAffiliateConfirmModal}
          handleConfirmModalClose={handleConfirmModalClose}
          onConfirmationModalSubmit={onConfirmationModalSubmit}
        />
        )

      }
    </div>
  );
}
