import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../Icon';
import { APP_BAR_HEIGHT } from '../../../../constants';
import { useAuthentication, useGeneral } from '../../../../utils/store';
import UserPopper from '../UserPopper';
import Button from '../../../Button';
import useApi from '../../../../utils/api';

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  minHeight: `${APP_BAR_HEIGHT}px`,
  height: `${APP_BAR_HEIGHT}px`,
  background: theme.palette.background.black,
  backgroundColor: theme.palette.background.black,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    zIndex: theme.zIndex.drawer - 1,
  }),
}));

const AppBar = ({ open, toggleDrawer }) => {
  const { theme } = useGeneral();
  const { user, authenticateUser } = useAuthentication();
  const navigate = useNavigate();
  const { authGet } = useApi();
  const [simulate, setSimulate] = useState();

  const exitImpersonation = async () => {
    try {
      const res = await authGet('auth/impersonate?_exit=1');
      authenticateUser(res.user, res.token);
      sessionStorage.removeItem('_imp');
      setSimulate(false);
      navigate('/admin/members');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const imp = sessionStorage.getItem('_imp');
    if (imp) {
      setSimulate(true);
    }
  }, []);

  return (
    <CustomAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
          px: 'auto',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            // eslint-disable-next-line react/jsx-props-no-spreading
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon color="primary" />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {simulate && (
          <Button
            style={{ paddingLeft: '44px' }}
            type="button"
            size="sm"
            bordered
            onClick={exitImpersonation}
            icon={(
              <Icon name="Spy" width={24} height={24} color={theme.PRIMARY_COLOR} />
            )}
          >
            Exit Simulation
          </Button>
        )}

        <Typography sx={{ mx: 3, textTransform: 'uppercase', fontWeight: 500 }}>
          {user?.Username}
        </Typography>

        <UserPopper theme={theme} user={user} />

        <IconButton color="inherit">
          <Icon name="Bell" width={25} height={25} color={theme.PRIMARY_COLOR} />
        </IconButton>

        {/* <IconButton color="inherit">
          <Icon name="ListItems" width={25} height={25} color={theme.PRIMARY_COLOR} />
        </IconButton> */}
      </Toolbar>
    </CustomAppBar>
  );
};

export default AppBar;
