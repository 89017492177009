import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import { Typography, Container } from '@mui/material';
import Modal from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import AppLoader from '../../../../../../components/AppLoader';

export default function AddBonusModal({
  user, open, handleClose, onAddBonus, loading,
}) {
  const [validation, setValidation] = useState(false);
  const [bonus, setBonus] = useState('');

  return (
    <Modal width="550px" title="Add Bonus Volume" open={open} handleClose={handleClose}>
      <Container sx={{ pt: 0, mt: 0 }}>
        {loading && <AppLoader overpage />}
        {!Array.isArray(user) && (
          <>
            <Typography
              sx={{
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                mb: user?.rankName ? 0 : 3,
              }}
            >
              {`${user?.Name} ${user?.Surname} (${user?.Username})`}
            </Typography>
            {user?.rankName && (
              <Typography
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  color: 'grey',
                  justifyContent: 'center',
                  mb: 3,
                }}
              >
                {`${user.rankName}`}
              </Typography>
            )}
          </>

        )}
        <div className="form-input-group">
          <div className={clsx('form-input text-input flex-row justify-between')}>
            <NumericFormat
              value={bonus}
              displayType="input"
              thousandSeparator
              placeholder="Enter Bonus Volume..."
              prefix="$"
              suffix=""
              renderText={(value) => (
                <Typography
                  sx={{
                    fontSize: '14px',
                    display: 'flex',
                    color: 'grey',
                    justifyContent: 'center',
                    mb: 3,
                  }}
                >
                  {value}
                </Typography>
              )}
              onValueChange={(valObj) => {
                const val = valObj.value || '';
                setBonus(val);
                setValidation(false);
              }}
            />
          </div>
        </div>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'brown',
            display: 'flex',
            justifyContent: 'center',
            mb: 3,
            mt: 2,
          }}
        >
          {validation}
        </Typography>
        {' '}
        <Button
          type="button"
          style={{ width: '100%' }}
          onClick={() => {
            if (bonus && bonus >= 0) {
              onAddBonus(user, bonus);
            } else {
              setValidation('Please enter a valid bonus volume');
            }
          }}
          disabled={loading}
        >
          Add Bonus
        </Button>
      </Container>
    </Modal>
  );
}
