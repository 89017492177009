/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import configs from './config';

const Icon = ({
  width = 14, height = 6, name = '', fill = 'none', color = '', ...props 
}) => {
  const conf = configs[name];

  if (conf) {
    return (
      <svg
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        color={color}
        viewBox={conf.viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      >
        {conf.children}
      </svg>
    );
  }
  return null;
};

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.any,
  color: PropTypes.any,
  name: PropTypes.string,
};

export default Icon;
