import imageCompression from 'browser-image-compression';

const options = {
  initialQuality: 0.9,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  maxSizeMB: 1,
  alwaysKeepResolution: true,
};

export const compressImage = async (imageFile) => {
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }

  return imageFile;
};

export default compressImage;

export const browserDownloadBlobData = (data, fileName) => {
  // Create a blob with the downloadable data
  const blob = new Blob([data], { type: 'text/csv' });

  const link = document.createElement('a');

  // Create a URL for the blob and set it as the href attribute
  link.href = window.URL.createObjectURL(blob);

  link.download = fileName;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download by click
  link.click();

  document.body.removeChild(link);
};
