const config = {
  viewBox: '0 0 20 19',
  children: (
    <>
      <mask id="path-1-inside-1_186_3409" fill="white">
        <path d="M0.625 8.59961V0.25H8.97461V8.59961H0.625ZM0.625 19V10.6504H8.97461V19H0.625ZM11.0254 19V10.6504H19.375V19H11.0254ZM11.0254 0.25H19.375V8.59961H11.0254V0.25Z" />
      </mask>
      <path d="M0.625 8.59961H-0.875V10.0996H0.625V8.59961ZM0.625 0.25V-1.25H-0.875V0.25H0.625ZM8.97461 0.25H10.4746V-1.25H8.97461V0.25ZM8.97461 8.59961V10.0996H10.4746V8.59961H8.97461ZM0.625 19H-0.875V20.5H0.625V19ZM0.625 10.6504V9.15039H-0.875V10.6504H0.625ZM8.97461 10.6504H10.4746V9.15039H8.97461V10.6504ZM8.97461 19V20.5H10.4746V19H8.97461ZM11.0254 19H9.52539V20.5H11.0254V19ZM11.0254 10.6504V9.15039H9.52539V10.6504H11.0254ZM19.375 10.6504H20.875V9.15039H19.375V10.6504ZM19.375 19V20.5H20.875V19H19.375ZM11.0254 0.25V-1.25H9.52539V0.25H11.0254ZM19.375 0.25H20.875V-1.25H19.375V0.25ZM19.375 8.59961V10.0996H20.875V8.59961H19.375ZM11.0254 8.59961H9.52539V10.0996H11.0254V8.59961ZM2.125 8.59961V0.25H-0.875V8.59961H2.125ZM0.625 1.75H8.97461V-1.25H0.625V1.75ZM7.47461 0.25V8.59961H10.4746V0.25H7.47461ZM8.97461 7.09961H0.625V10.0996H8.97461V7.09961ZM2.125 19V10.6504H-0.875V19H2.125ZM0.625 12.1504H8.97461V9.15039H0.625V12.1504ZM7.47461 10.6504V19H10.4746V10.6504H7.47461ZM8.97461 17.5H0.625V20.5H8.97461V17.5ZM12.5254 19V10.6504H9.52539V19H12.5254ZM11.0254 12.1504H19.375V9.15039H11.0254V12.1504ZM17.875 10.6504V19H20.875V10.6504H17.875ZM19.375 17.5H11.0254V20.5H19.375V17.5ZM11.0254 1.75H19.375V-1.25H11.0254V1.75ZM17.875 0.25V8.59961H20.875V0.25H17.875ZM19.375 7.09961H11.0254V10.0996H19.375V7.09961ZM12.5254 8.59961V0.25H9.52539V8.59961H12.5254Z" fill="currentColor" mask="url(#path-1-inside-1_186_3409)" />
    </>
  ),
};

export default config;
