import { createContext } from 'react';
import useLeaderboard from './hook';

export const LeaderboardContext = createContext({
  t: () => {},
  user: {},
  topTotal: 5,
  setTopTotal: () => {},
  topRankYesterdayData: [],
  loadingTopRankYesterday: true,
  setLoadingTopRankYesterday: () => {},
  topRankData: [],
  loadingTopRank: true,
  setLoadingTopRank: () => {},
  allRankData: [],
  loadingAllRank: true,
  setLoadingAllRank: () => {},
  getPaginatedRankData: () => {},
  paginationData: {},
  leaderboardTopColumns: [],
  leaderboardColumns: [],
  leaderboardTopYesterdayColumns: [],
});

export const LeaderboardProvider = ({ children }) => {
  const value = useLeaderboard();
  return <LeaderboardContext.Provider value={value}>{ children }</LeaderboardContext.Provider>;
};
