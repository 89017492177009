import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuthenticationApi from '../api';

export default function GetParent() {
  const navigate = useNavigate();
  const { username } = useParams();
  const { getParent } = useAuthenticationApi();

  useEffect(() => {
    let _username = null;
    const host = window.location.href;
    const uri = new URL(host);

    if (uri.hostname.split('.').length > 2) {
      const [user] = uri.hostname.split('.');
      _username = user;
    }

    if (username || _username) {
      const _fetch = async () => {
        try {
          const res = await getParent(username || _username);
          sessionStorage.setItem('signupParent', JSON.stringify(res));
        } catch (err) {
          console.log(err);
          toast.error(err);
        }

        navigate('/');
      };

      _fetch();
    }
  }, []);

  return (
    <div className="flex flex-column h-full">
      <div className="flex flex-row justify-center mb-8">
        <CircularProgress />
      </div>
    </div>
  );
}
