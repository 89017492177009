import { createContext } from 'react';
import useGameHistory from './hook';

export const GameHistoryContext = createContext({
  t: () => {},
  user: {},
  loadedData: false,
  gamesData: [],
  getPaginatedData: () => {},
  paginationData: {},
  tokens: 0,
});

export const GameHistoryProvider = ({ children }) => {
  const value = useGameHistory();
  return <GameHistoryContext.Provider value={value}>{ children }</GameHistoryContext.Provider>;
};
