/* eslint-disable react/jsx-props-no-spreading */
import {
  Container, Slider, SliderThumb, Tooltip, Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Context } from '../../../context';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import ComponentLoader from '../../../../../components/ComponentLoader';
import { renderNumber } from '../../../../../utils/string';
import Icon from '../../../../../components/Icon';
import { usePickPoints } from '../../../context/helper';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

function CustomSliderThumb(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <div className="slider-thumb">
        <div className="layer-a" />
      </div>
    </SliderThumb>
  );
}

export default function VoteModal({ renderCont = () => null, gameId }) {
  const {
    chosenMemeCoin: chosenMemeCoins, onVoteMemeCoin, loadingCoins, openMemeConfirmModal,
    gamingFunds, setOpenMemeConfirmModal, todayGame: todayGames,
  } = useContext(Context);
  const coin = useMemo(() => openMemeConfirmModal, [openMemeConfirmModal]);
  const { getPercentageMultiplier } = usePickPoints();
  const todayGame = todayGames.find((g) => g.id === gameId);
  const [wage, setWage] = useState(todayGame?.price || 0);
  const cannotVote = !gamingFunds || todayGame?.price > gamingFunds;

  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === gameId);

  const percentageMultiplier = getPercentageMultiplier({
    coinData: openMemeConfirmModal,
    maxMultiplier: todayGame.maxMultiplier,
  });

  const numbersData = useMemo(() => {
    let currentPrice = Number(coin?.data?.market_data?.current_price?.usd);

    if (currentPrice < 1) {
      currentPrice = Number(coin?.data?.market_data?.current_price?.usd).toFixed(8);
    } else {
      currentPrice = renderNumber(coin?.data?.market_data?.current_price?.usd);
    }

    let _startData = Number(coin?.start?.price);

    if (!_startData) {
      _startData = Number(
        (coin?.data?.market_data?.current_price?.usd || 0)
        - (coin?.data?.market_data?.price_change_24h_in_currency?.usd || 0),
      );
    }

    const _currentPrice = Number(currentPrice || 0);
    const _diff = _currentPrice - _startData;

    return {
      currentPrice,
      startPrice: renderNumber(_startData),
      priceChangePercentage24h: Number((_diff / _startData) * 100).toFixed(2),
    };
  }, [coin]);

  const isGrowing = useMemo(() => numbersData.priceChangePercentage24h > 0, [numbersData]);

  const onCloseModal = () => {
    setOpenMemeConfirmModal();
  };

  const onChangeWage = (val) => {
    try {
      let _val = Number(val);
      if (Number.isNaN(_val)) _val = 0;
      setWage(_val > gamingFunds ? gamingFunds : _val);
    } catch (err) {
      setWage(0);
    }
  };

  return (
    <Modal
      title={chosenMemeCoin ? 'Picks' : `Pick ${openMemeConfirmModal?.name}`}
      open={openMemeConfirmModal}
      handleClose={onCloseModal}
      width="700px"
    >
      <Container className="meme-coins-page-modal">
        <div className="max-width-cont">
          <div className="flex gap-1 justify-center flex-1 mb-2 meme-coins">
            {loadingCoins ? (
              <ComponentLoader />
            ) : (
              <div className="coin-info meme-coin-data flex gap-1 justify-between flex-1">
                <div className="flex gap-1">
                  <div className="flex flex-column">
                    <img alt={`${coin.name}`} src={coin.data?.image?.large || coin.image} />
                  </div>

                  <div className="flex flex-column">
                    <div className="data-cont mb-2 flex-column">
                      <span className="value">
                        <span className="dollar">$</span>
                        {numbersData.startPrice}
                      </span>
                      <span className="label">Start Price:</span>
                    </div>
                    <div className="data-cont mb-2 flex-column">
                      <span className="value">
                        <span className="dollar">$</span>
                        {numbersData.currentPrice}
                      </span>
                      <span className="label">Current Price:</span>
                    </div>

                    <div className="flex">
                      <div className="card-chip">
                        <div className="flex flex-column mr-1">
                          <span>
                            {numbersData.priceChangePercentage24h}
                            %
                          </span>
                        </div>
                        <div className="flex flex-column">
                          {isGrowing ? (
                            <div className="chip-bubble">
                              <div className="flex justify-center">
                                <ArrowUpwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                              </div>
                            </div>
                          ) : (
                            <div className="chip-bubble not-growing-bubble">
                              <div className="flex justify-center">
                                <ArrowDownwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="meme-game-multipliers-cont flex">
                    {/* {renderCont({
                      type: 'Percentage',
                      label: 'MG Coins',
                      value: wage,
                      color: '#DAAB74',
                    })} */}

                    {/* <div className="flex flex-column multiplication-cont">
                      <Icon name="GameMultiplication" color="#DAAB74" width={10} height={10} />
                    </div> */}

                    {/* {renderCont({
                      type: 'percentage',
                      label: 'Percentage',
                      color: '#DAAB74',
                      value: percentageMultiplier,
                    })} */}
                  </div>

                  <div className="meme-game-multipliers-cont flex bordered mt-4">
                    {renderCont({
                      type: 'Percentage',
                      label: 'MG Coins',
                      value: renderNumber(wage),
                      color: '#DAAB74',
                    })}
                    <div className="flex flex-column multiplication-cont">
                      <Icon name="GameEqualSign" color="#DAAB74" width={10} height={10} />
                    </div>

                    {renderCont({
                      type: 'lastPoints',
                      label: 'Potential Tokens', // changed to points
                      value: renderNumber(wage * todayGame.maxMultiplier || 1),
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          {!chosenMemeCoin && !loadingCoins ? (
            <div className="flex flex-column">
              {cannotVote ? (
                <Typography component="p" sx={{ flexGrow: 1 }}>
                  Not enough MG Coins Available!
                </Typography>
              ) : (

                <>
                  {/* <Typography component="p" className="label" color="inherit" noWrap 
                  sx={{ flexGrow: 1 }}>
                    Your MG Coins:
                  </Typography> */}

                  {/* <Slider
                    min={0}
                    slots={{
                      thumb: CustomSliderThumb,
                      valueLabel: ValueLabelComponent,
                    }}
                    value={wage}
                    max={gamingFunds}
                    valueLabelDisplay="auto"
                    getAriaValueText={() => wage}
                    onChange={(e, v) => {
                      if (!todayGame.price) {
                        onChangeWage(v);
                      }
                    }}
                  /> */}

                  {/* <div className="flex justify-between slider-range">
                    <strong>0</strong>
                    <strong>{gamingFunds}</strong>
                  </div> */}
                </>
              )}
            </div>
          ) : null}

          <div className="modal-footer">
            {chosenMemeCoin || cannotVote ? null : (
              <Button
                onClick={() => {
                  if (loadingCoins) {
                    return;
                  }
                  onVoteMemeCoin(wage);
                }}
                disabled={!wage}
              >
                Pick Winner
              </Button>
            )}

            <Button onClick={onCloseModal} bordered className="btn-link">
              Cancel
            </Button>
          </div>
        </div>
      </Container>

    </Modal>
  );
}
