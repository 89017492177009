/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup as MUIRadioGroup } from '@mui/material';

export default function RadioGroup({
  name = '',
  value = '',
  options = [],
  focused = false,
  placeholder = '',
  onChange = () => { },
  size = 'md',
  disabled = false,
  error,
  setErrors,
  defaultValue,
  sx = {},
  template,
}) {
  if (template === 'cards') {
    return (
      <MUIRadioGroup
        sx={sx}
        id={name}
        value={value || defaultValue}
        placeholder={placeholder}
        className={clsx('flex-1 select', focused && 'focused', size, 'radio-group-cards')}
        onChange={(e) => {
          onChange(e.target.value);
          setErrors(null);
        }}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {error && <div className="form-input-error">{error}</div>}
        {options.map((o) => (
          <FormControlLabel
            sx={{ my: 1 }}
            label={(
              <div className="radio-label flex flex-column">
                {o.label.name ? (
                  <h5>
                    {o.label.name}
                  </h5>
                ) : null}

                {o.label.isBundle ? (
                  <>
                    <span>
                      {o.label.description}
                    </span>
                    <div className="flex mt-2">
                      <span>
                        $
                        {o?.label?.price?.toLocaleString('en-us') || o?.label?.Price?.toLocaleString('en-us') || 0}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="flex">
                    <span>
                      {o.label.points}
                      <br />
                      <span className="small">
                        coins
                      </span>
                    </span>
                    <span>
                      $
                      {o?.label?.price?.toLocaleString('en-us') || o?.label?.Price?.toLocaleString('en-us') || 0}
                    </span>
                  </div>
                )}
              </div>
            )}
            className={(value || defaultValue) === o.value ? 'checked' : null}
            control={<Radio />}
            value={o.value}
            key={o.value}
          />
        ))}
      </MUIRadioGroup>
    );
  }

  return (
    <MUIRadioGroup
      sx={sx}
      id={name}
      value={value || defaultValue}
      placeholder={placeholder}
      className={clsx('flex-1 select', focused && 'focused', size)}
      onChange={(e) => {
        onChange(e.target.value);
        setErrors(null);
      }}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {error && <div className="form-input-error">{error}</div>}
      {options.map((o) => (
        <FormControlLabel sx={{ my: 1 }} label={o.label} control={<Radio />} value={o.value} key={o.value} />
      ))}
    </MUIRadioGroup>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
